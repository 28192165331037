.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}

.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}

html{
  -webkit-box-sizing:border-box;
          box-sizing:border-box;
  overflow-y:scroll;
  -webkit-text-size-adjust:100%
}

*,:before,:after{
  -webkit-box-sizing:inherit;
          box-sizing:inherit
}

:before,:after{
  vertical-align:inherit;
  text-decoration:inherit
}

*{
  margin:0;
  padding:0;
  background-repeat:no-repeat
}

hr{
  overflow:visible
}

article,footer,header,main,menu,nav,section{
  display:block
}

small{
  font-size:80%
}

[hidden],template{
  display:none
}

a{
  background-color:transparent;
  -webkit-text-decoration-skip:objects
}

a:active,a:hover{
  outline-width:0
}

code{
  font-family:monospace, monospace
}

b,strong{
  font-weight:bolder
}

input{
  border-radius:0
}

[type="number"]{
  width:auto
}

[type="search"]{
  -webkit-appearance:textfield;
  outline-offset:-2px
}

[type="search"]::-webkit-search-cancel-button,[type="search"]::-webkit-search-decoration{
  -webkit-appearance:none
}

textarea{
  overflow:auto;
  resize:vertical
}

button,input,select,textarea{
  font:inherit
}

button{
  overflow:visible
}

button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner{
  padding:0;
  border-style:0
}

button:-moz-focusring,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner{
  outline:1px dotted ButtonText
}

button,html [type="button"],[type="reset"],[type="submit"]{
  -webkit-appearance:button
}

button,select{
  text-transform:none
}

button,input,select,textarea{
  color:inherit;
  background-color:transparent;
  border-style:none
}

select{
  -moz-appearance:none;
  -webkit-appearance:none
}

select::-ms-expand{
  display:none
}

select::-ms-value{
  color:currentColor
}

::-webkit-file-upload-button{
  font:inherit;
  -webkit-appearance:button
}

::-webkit-input-placeholder{
  color:inherit;
  opacity:0.54
}

img{
  border-style:none
}

progress{
  vertical-align:baseline
}

svg:not(:root){
  overflow:hidden
}

progress{
  display:inline-block
}

@media screen{
  [hidden~="screen"]{
    display:inherit
  }

  [hidden~="screen"]:not(:active):not(:focus):not(:target){
    position:absolute !important;
    clip:rect(0 0 0 0) !important
  }
}

[aria-busy="true"]{
  cursor:progress
}

[aria-controls]{
  cursor:pointer
}

[aria-disabled]{
  cursor:default
}

::-moz-selection{
  text-shadow:none;
  color:#fff;
  background-color:#b3d4fc
}

::selection{
  text-shadow:none;
  color:#fff;
  background-color:#b3d4fc
}

button:focus{
  outline:none
}

.u-img{
  display:block;
  width:100%;
  height:auto
}

@media (min-width: 0em){

  .u-dtc--xxs{
    display:table-cell !important
  }
}

@media not all and (min-width: 0em){

  .u-dtc--xxs-max{
    display:table-cell !important
  }
}

@media (min-width: 30em){

  .u-dtc--xs{
    display:table-cell !important
  }
}

@media not all and (min-width: 30em){

  .u-dtc--xs-max{
    display:table-cell !important
  }
}

@media (min-width: 48em){

  .u-dtc--sm{
    display:table-cell !important
  }
}

@media not all and (min-width: 48em){

  .u-dtc--sm-max{
    display:table-cell !important
  }
}

@media (min-width: 62em){

  .u-dtc--md{
    display:table-cell !important
  }
}

@media not all and (min-width: 62em){

  .u-dtc--md-max{
    display:table-cell !important
  }
}

@media (min-width: 80em){

  .u-dtc--lg{
    display:table-cell !important
  }
}

@media not all and (min-width: 80em){

  .u-dtc--lg-max{
    display:table-cell !important
  }
}

@media (min-width: 90em){

  .u-dtc--xl{
    display:table-cell !important
  }
}

@media not all and (min-width: 90em){

  .u-dtc--xl-max{
    display:table-cell !important
  }
}

@media (min-width: 120em){

  .u-dtc--xxl{
    display:table-cell !important
  }
}

@media not all and (min-width: 120em){

  .u-dtc--xxl-max{
    display:table-cell !important
  }
}

@media (min-width: 0em){
  .u-tac--xxs{
    text-align:center !important
  }
}

@media (min-width: 0em), max{
  .u-tac--xxs-max{
    text-align:center !important
  }
}

@media (min-width: 30em){
  .u-tac--xs{
    text-align:center !important
  }
}

@media (min-width: 30em), max{
  .u-tac--xs-max{
    text-align:center !important
  }
}

@media (min-width: 48em){
  .u-tac--sm{
    text-align:center !important
  }
}

@media (min-width: 48em), max{
  .u-tac--sm-max{
    text-align:center !important
  }
}

@media (min-width: 62em){
  .u-tac--md{
    text-align:center !important
  }
}

@media (min-width: 62em), max{
  .u-tac--md-max{
    text-align:center !important
  }
}

@media (min-width: 80em){
  .u-tac--lg{
    text-align:center !important
  }
}

@media (min-width: 80em), max{
  .u-tac--lg-max{
    text-align:center !important
  }
}

@media (min-width: 90em){
  .u-tac--xl{
    text-align:center !important
  }
}

@media (min-width: 90em), max{
  .u-tac--xl-max{
    text-align:center !important
  }
}

@media (min-width: 120em){
  .u-tac--xxl{
    text-align:center !important
  }
}

@media (min-width: 120em), max{
  .u-tac--xxl-max{
    text-align:center !important
  }
}

.o-icon{
  --color-bg: #a0dcef;
  display:inline-block;
  vertical-align:middle
}

.o-icon svg{
  display:block;
  fill:currentColor
}

.o-icon--twitter{
  --color-bg: #95e4db
}

.o-icon--award{
  --color-bg: #ffde55
}

.o-icon--xs{
  font-size:.4rem
}

.o-icon--md{
  font-size:1.3rem
}

.o-icon--lg{
  font-size:1.7rem
}

.o-icon.active .svg-heart{
  --color-bg: #ff9173
}

.svg-logo{
  width:11.875em;
  height:3.75em
}

.svg-arrow-left,.svg-arrow-right{
  width:.625em;
  height:.64em
}

.svg-user,.svg-arrow-circle{
  width:1.5em;
  height:1.5em
}

.svg-warn{
  width:2em;
  height:1.875em
}

.svg-arrow-down{
  width:.625em;
  height:.5625em
}

.svg-plus{
  width:.5em;
  height:.5em
}

.svg-cross{
  width:.5em;
  height:.5em
}

.svg-picker{
  width:2.875em;
  height:3.51em
}

.svg-checkbox{
  width:1.25em;
  height:1.33em
}

.svg-download{
  width:1.06em;
  height:1em
}

.svg-twitter,.svg-facebook{
  width:2em;
  height:2em
}

.svg-vev{
  width:5.25em;
  height:2.58em
}

.svg-level-1{
  width:4.2em;
  height:4.2em
}

.svg-level-2{
  width:5.84em;
  height:4.2em
}

.svg-level-3{
  width:7.71em;
  height:4.2em
}

.svg-award{
  width:1.875em;
  height:2.625em
}

.svg-heart{
  width:1em;
  height:.9em;
  --color-line: #000000;
  --color-bg: transparent
}

.svg-circle,.svg-square,.svg-checkbox-half{
  width:1em;
  height:1em
}

.svg-settings{
  width:.75em;
  height:.92em
}

.svg-logout{
  width:.875em;
  height:.875em
}

.svg-info{
  width:.875em;
  height:.875em
}

.svg-checkbox-rounded{
  width:1em;
  height:1.14em
}

.svg-tree{
  width:1.56em;
  height:2em
}

.svg-edit{
  width:1.4em;
  height:1.4em
}

.svg-check{
  width:.89em;
  height:1em
}

.svg-lock{
  width:0.4em;
  height:0.61em;
  --color-bg: #000000
}

.svg-unlock{
  width:0.55em;
  height:0.77em;
  --color-bg: #000000
}

.o-burger{
  display:block;
  width:2.75em;
  height:2.75em;
  cursor:pointer
}

.has-menu-open .o-burger .o-burger__line:nth-child(1),.has-menu-open .o-burger .o-burger__line:nth-child(3){
  opacity:0;
  -webkit-transform:scale(0, 1);
          transform:scale(0, 1);
  -webkit-transition:all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition:all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045)
}

.has-menu-open .o-burger .o-burger__line:nth-child(2){
  opacity:0;
  -webkit-transform:scale(0.5, 1);
          transform:scale(0.5, 1);
  -webkit-transition:all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition:all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045)
}

.has-menu-open .o-burger .o-burger__cross{
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-transition:all 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
  transition:all 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.2s
}

.o-burger__line,.o-burger__cross{
  position:absolute;
  left:calc(2.75em/2 - 1.25em/2);
  display:block;
  width:1.25em;
  height:.1em
}

.o-burger__line{
  background-color:#000000;
  -webkit-transition:all 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.2s;
  transition:all 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.2s
}

.o-burger__line:nth-child(1){
  top:.9em
}

.o-burger__line:nth-child(2){
  top:calc(50% - .1em/2)
}

.o-burger__line:nth-child(3){
  bottom:.9em
}

.o-burger__cross{
  top:calc(50% - .1em/2);
  -webkit-transform:scale(0);
          transform:scale(0);
  -webkit-transition:all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
  transition:all 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s
}

.o-burger__cross:after,.o-burger__cross:before{
  content:"";
  display:block;
  width:100%;
  height:100%;
  background:#000000;
  position:absolute;
  top:0;
  left:0
}

.o-burger__cross:before{
  -webkit-transform:rotate(45deg);
          transform:rotate(45deg)
}

.o-burger__cross:after{
  -webkit-transform:rotate(-45deg);
          transform:rotate(-45deg)
}

.o-hover-link{
  z-index:0;
  margin-right:-.1em;
  margin-left:-.1em;
  padding-right:.1em;
  padding-left:.1em;
  overflow:hidden
}

.o-hover-link span{
  z-index:-1;
  position:absolute;
  bottom:0;
  left:0;
  display:block;
  width:100%;
  height:.4em;
  -webkit-clip-path:inset(0 round .425em .425em .425em .425em);
          clip-path:inset(0 round .425em .425em .425em .425em)
}

.o-hover-link span:before{
  content:"";
  display:block;
  width:100%;
  height:100%;
  background:none;
  z-index:-1;
  position:absolute;
  bottom:-1px;
  left:0;
  background:rgba(255,255,255,0);
  background:-webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)),color-stop(25%, var(--color-main)),color-stop(75%, var(--color-main)),to(rgba(255,255,255,0)));
  background:linear-gradient(to right, rgba(255,255,255,0) 0%,var(--color-main) 25%,var(--color-main) 75%,rgba(255,255,255,0) 100%);
  -webkit-clip-path:inherit;
          clip-path:inherit;
  opacity:0;
  -webkit-transform:scale(0, 1) translateZ(0);
          transform:scale(0, 1) translateZ(0);
  -webkit-transition:opacity 0.1s 0.3s,-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715),-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715)
}

.o-hover-link:hover span:before{
  opacity:1;
  -webkit-transform:scale(1) translateZ(0);
          transform:scale(1) translateZ(0);
  -webkit-transition:opacity 0.1s 0s,-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.o-hover-link.is-active span:before{
  opacity:1;
  -webkit-transform:scale(2, 1) translateZ(0);
          transform:scale(2, 1) translateZ(0);
  -webkit-transition:opacity 0.1s 0s,-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}

.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}

.o-spacer{
  display:block;
  width:100%;
  border:0
}

.c-btn{
  --color-bg: #ffffff;
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  min-height:3em;
  padding:.5em 1em;
  background-color:var(--color-bg, #ffffff);
  border-radius:.425em;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
  cursor:pointer;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  overflow:hidden
}

.c-btn--simple{
  min-height:2.5em;
  padding:.2em .5em;
  -webkit-box-shadow:none;
          box-shadow:none;
  background-color:transparent
}

.c-btn--grey{
  color:#b2b2b2
}

.c-btn--primary{
  --color-bg: #ffde55
}

.c-btn:before{
  content:"";
  display:block;
  width:100%;
  height:100%;
  background:none;
  position:absolute;
  top:0;
  left:0;
  background-color:rgba(0,0,0,0.1);
  background:rgba(255,255,255,0);
  background:-webkit-gradient(linear, right top, left top, from(rgba(255,255,255,0)),color-stop(25%, rgba(255,255,255,0.5)),color-stop(75%, rgba(255,255,255,0.5)),to(rgba(255,255,255,0)));
  background:linear-gradient(to left, rgba(255,255,255,0),rgba(255,255,255,0.5) 25%,rgba(255,255,255,0.5) 75%,rgba(255,255,255,0));
  -webkit-transform:scale(0, 1);
          transform:scale(0, 1);
  -webkit-transition:-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715), -webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715)
}

.c-btn:not([style]):before,.c-btn[style*="--color-bg:#ffffff"]:before,.c-btn[style*="--color-bg: #ffffff"]:before{
  background:rgba(255,255,255,0);
  background:-webkit-gradient(linear, right top, left top, from(rgba(255,255,255,0)),color-stop(25%, rgba(178,178,178,0.2)),color-stop(75%, rgba(178,178,178,0.2)),to(rgba(255,255,255,0)));
  background:linear-gradient(to left, rgba(255,255,255,0),rgba(178,178,178,0.2) 25%,rgba(178,178,178,0.2) 75%,rgba(255,255,255,0))
}

.c-btn:hover:before{
  -webkit-transform:scale(2);
          transform:scale(2);
  -webkit-transition:-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1), -webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.c-btn:active .c-btn__label{
  -webkit-transform:scale(0.98);
          transform:scale(0.98)
}

.c-btn--sm{
  font-size:.85em
}

.c-btn.is-disabled{
  opacity:0.7;
  pointer-events:none
}

@media (min-width: 480px){
  .c-btn{
    padding-right:1.5em;
    padding-left:1.5em
  }

  .c-btn--simple{
    padding-right:.75em;
    padding-left:.75em
  }
}

.c-btn__label{
  font-size:.875em;
  display:block;
  font-weight:700;
  -webkit-transition:-webkit-transform .1s;
  transition:-webkit-transform .1s;
  transition:transform .1s;
  transition:transform .1s, -webkit-transform .1s
}

.c-btn__label+.c-btn__icon{
  margin-left:.75em
}

.c-btn__icon+.c-btn__label{
  margin-left:.75em
}

.c-usage:before{
  content:"";
  display:block;
  width:100vw;
  height:auto;
  background:#ffffff;
  position:absolute;
  top:0;
  bottom:50%;
  left:calc(50% - 50vw)
}

@media (min-width: 992px){
  .c-usage{
    margin-top:5.75em;
    padding-bottom:5vh
  }
}

.c-usage__box{
  z-index:1;
  margin-bottom:1em;
  background-color:#ffffff;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
  opacity:0;
  -webkit-transform:translate(0, 2em) scale(1, 0.8);
          transform:translate(0, 2em) scale(1, 0.8);
  -webkit-transform-origin:50% 100%;
          transform-origin:50% 100%
}

.c-usage__box.view-in{
  border-radius:.425em;
  opacity:1;
  -webkit-transform:translate(0) scale(1);
          transform:translate(0) scale(1);
  -webkit-transition:border-radius 0.6s cubic-bezier(0.47, 0, 0.745, 0.715),opacity 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95),-webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition:border-radius 0.6s cubic-bezier(0.47, 0, 0.745, 0.715),opacity 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95),-webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition:border-radius 0.6s cubic-bezier(0.47, 0, 0.745, 0.715),opacity 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95),transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition:border-radius 0.6s cubic-bezier(0.47, 0, 0.745, 0.715),opacity 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95),transform 0.6s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}

@media (min-width: 480px){
  .c-usage__box{
    width:calc(100% - 4em);
    margin-bottom:-2em;
    margin-left:2em
  }
}

@media (min-width: 768px){
  .c-usage__box{
    max-width:32em;
    margin-right:auto;
    margin-left:auto
  }
}

@media (min-width: 992px){
  .c-usage__box{
    position:absolute;
    top:-5.75em;
    left:2em;
    max-width:28em
  }
}

@media (min-width: 1280px){
  .c-usage__box{
    max-width:32em
  }
}

.c-usage__header{
  padding:1em 2.5em
}

@media (min-width: 992px){
  .c-usage__header{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    height:5.75em
  }
}

@media (min-width: 1280px){
  .c-usage__header{
    padding-right:3.25em;
    padding-left:3.25em
  }
}

.c-usage__content{
  padding:2em 2.5em;
  border-top:1px solid #f6f6f6
}

@media (min-width: 992px){
  .c-usage__content{
    padding-top:2em;
    padding-bottom:2em
  }
}

@media (min-width: 1280px){
  .c-usage__content{
    padding-right:3.25em;
    padding-left:3.25em
  }
}

.c-usage__asset{
  border-radius:.425em;
  overflow:hidden
}

.c-usage__asset.view-in img{
  opacity:1;
  -webkit-transition:opacity 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s;
  transition:opacity 0.3s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s
}

.c-usage__asset img{
  opacity:0
}

.o-usage-list{
  margin:0;
  padding-left:0;
  list-style:none;
  margin-top:2em;
  counter-reset:step
}

.o-usage-list__item{
  z-index:0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  margin-top:.75em;
  margin-bottom:.75em;
  counter-increment:step
}

.o-usage-list__item:before{
  font-size:.875em;
  content:counter(step) ".";
  -ms-flex-negative:0;
      flex-shrink:0;
  width:2.15rem;
  height:2rem;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  background-color:#ffffff;
  border-radius:.425em;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1)
}

.o-usage-list__item.is-current{
  padding-top:.75em;
  padding-bottom:.75em
}

.o-usage-list__item.is-current:after{
  content:"";
  display:block;
  width:calc(100% - 1.5em);
  height:100%;
  background:#eefbfb;
  z-index:-1;
  position:absolute;
  top:0;
  right:0;
  border-radius:.425em
}

.o-usage-list__item.is-disabled{
  color:#b2b2b2
}

.o-usage-list__icon{
  width:3em
}

.o-usage-list__icon svg{
  margin-right:auto;
  margin-left:auto
}

.o-usage-list__icon+.o-usage-list__label{
  margin-left:0
}

.o-usage-list__label{
  font-size:.875em;
  margin-left:3rem
}

.o-usage-pickers{
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  width:auto;
  height:auto;
  margin:0;
  padding-left:0;
  list-style:none
}

@media not all and (min-width: 62em){
  .o-usage-pickers{
    top:2em
  }
}

@media (min-width: 992px){
  .o-usage-pickers{
    left:calc(28em + 2em)
  }
}

@media (min-width: 1280px){
  .o-usage-pickers{
    left:calc(32em + 2em)
  }
}

.o-usage-pickers__item{
  position:absolute;
  display:block;
  opacity:0;
  -webkit-transform:translate(0, -0.5em) scale(0);
          transform:translate(0, -0.5em) scale(0)
}

.o-usage-pickers__item .o-rounded{
  position:absolute;
  top:-.5em;
  left:50%
}

.o-usage-pickers__item:nth-child(1){
  top:15%;
  left:20%
}

.o-usage-pickers__item:nth-child(1) .o-icon{
  --color-bg: #bce772
}

.o-usage-pickers__item:nth-child(2){
  top:5%;
  left:70%
}

.o-usage-pickers__item:nth-child(2) .o-icon{
  --color-bg: #b2b2b2
}

.o-usage-pickers__item:nth-child(3){
  top:50%;
  left:40%
}

.o-usage-pickers__item:nth-child(3) .o-icon{
  --color-bg: #bce772
}

.o-usage-pickers__item:nth-child(4){
  bottom:40%;
  left:85%
}

.o-usage-pickers__item:nth-child(4) .o-icon{
  --color-bg: #a0dcef
}

.o-usage-pickers__item:nth-child(5){
  bottom:10%;
  left:5%
}

.o-usage-pickers__item:nth-child(5) .o-icon{
  --color-bg: #a0dcef
}

.o-usage-pickers__item:nth-child(6){
  bottom:20%;
  left:60%
}

.o-usage-pickers__item:nth-child(6) .o-icon{
  --color-bg: #a0dcef
}

.view-in .o-usage-pickers__item{
  opacity:1;
  -webkit-transform:translate(0) scale(1);
          transform:translate(0) scale(1);
  -webkit-transform-origin:50% 100%;
          transform-origin:50% 100%
}

.view-in .o-usage-pickers__item:nth-child(1){
  -webkit-transition:opacity 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .6s;
  transition:opacity 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .6s;
  transition:opacity 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .6s;
  transition:opacity 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 0s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .6s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .6s
}

.view-in .o-usage-pickers__item:nth-child(2){
  -webkit-transition:opacity 0.55s cubic-bezier(0.39, 0.575, 0.565, 1) .16667s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .76667s;
  transition:opacity 0.55s cubic-bezier(0.39, 0.575, 0.565, 1) .16667s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .76667s;
  transition:opacity 0.55s cubic-bezier(0.39, 0.575, 0.565, 1) .16667s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .76667s;
  transition:opacity 0.55s cubic-bezier(0.39, 0.575, 0.565, 1) .16667s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .76667s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .76667s
}

.view-in .o-usage-pickers__item:nth-child(3){
  -webkit-transition:opacity 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) .33333s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .93333s;
  transition:opacity 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) .33333s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .93333s;
  transition:opacity 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) .33333s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .93333s;
  transition:opacity 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) .33333s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .93333s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) .93333s
}

.view-in .o-usage-pickers__item:nth-child(4){
  -webkit-transition:opacity 0.45s cubic-bezier(0.39, 0.575, 0.565, 1) .5s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.1s;
  transition:opacity 0.45s cubic-bezier(0.39, 0.575, 0.565, 1) .5s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.1s;
  transition:opacity 0.45s cubic-bezier(0.39, 0.575, 0.565, 1) .5s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.1s;
  transition:opacity 0.45s cubic-bezier(0.39, 0.575, 0.565, 1) .5s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.1s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.1s
}

.view-in .o-usage-pickers__item:nth-child(5){
  -webkit-transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) .66667s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.26667s;
  transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) .66667s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.26667s;
  transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) .66667s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.26667s;
  transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) .66667s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.26667s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.26667s
}

.view-in .o-usage-pickers__item:nth-child(6){
  -webkit-transition:opacity 0.35s cubic-bezier(0.39, 0.575, 0.565, 1) .83333s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.43333s;
  transition:opacity 0.35s cubic-bezier(0.39, 0.575, 0.565, 1) .83333s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.43333s;
  transition:opacity 0.35s cubic-bezier(0.39, 0.575, 0.565, 1) .83333s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.43333s;
  transition:opacity 0.35s cubic-bezier(0.39, 0.575, 0.565, 1) .83333s,transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.43333s,-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1) 1.43333s
}

@media not all and (min-width: 48em){
  .o-usage-pickers__item{
    font-size:.8rem
  }
}

.c-form{
  margin-top:1em
}

.c-form__el{
  padding-top:1em;
  padding-bottom:1em
}

.c-form__el--simplify .c-btn__label,.c-form__el--simplify .o-input-text__edit-text{
  display:none
}

.c-form__el--simplify .o-input-text__set--edit .o-icon{
  margin-right:0
}

.c-form__el--simplify .o-input-text--editable textarea,.c-form__el--simplify .o-input-text--editable input,.c-form__el--simplify .o-input-text--editable label{
  padding-right:50px
}

.c-form__el--simplify .o-input-text label{
  border-bottom:0
}

.error{
  --text-opacity:1;
  color:#ff9173;
  color:rgba(255, 145, 115, var(--text-opacity));
  font-size:.875em;
  display:block;
  margin-top:0.5rem;
  margin-bottom:1rem
}

.success{
  --text-opacity:1;
  color:#bce772;
  color:rgba(188, 231, 114, var(--text-opacity));
  font-size:.875em;
  display:block;
  margin-top:0.5rem;
  margin-bottom:1rem
}

.c-form__btns{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:end;
      -ms-flex-align:end;
          align-items:flex-end;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  margin-top:1em
}

.c-form__btns>*:nth-child(n+2){
  margin-left:1em
}

.c-form__submit{
  -ms-flex-negative:0;
      flex-shrink:0;
  margin-left:auto
}

.o-input-text input,.o-input-text textarea,.o-input-text label{
  padding-top:.25em;
  padding-bottom:.25em
}

.o-input-text textarea{
  height:20px;
  resize:none;
  overflow:hidden
}

.o-input-text input,.o-input-text textarea{
  width:100%
}

.o-input-text input .o-input-text__placeholder,.o-input-text textarea .o-input-text__placeholder{
  pointer-events:none
}

.o-input-text input:focus+label .o-input-text__placeholder,.o-input-text input:active+label .o-input-text__placeholder,.o-input-text textarea:focus+label .o-input-text__placeholder,.o-input-text textarea:active+label .o-input-text__placeholder{
  display:none
}

.o-input-text input:focus+label .o-input-text__set--edit,.o-input-text input:active+label .o-input-text__set--edit,.o-input-text textarea:focus+label .o-input-text__set--edit,.o-input-text textarea:active+label .o-input-text__set--edit{
  z-index:1;
  opacity:0
}

.o-input-text input:focus ~ .o-input-text__set--save,.o-input-text input:active ~ .o-input-text__set--save,.o-input-text textarea:focus ~ .o-input-text__set--save,.o-input-text textarea:active ~ .o-input-text__set--save{
  opacity:1;
  pointer-events:auto
}

.o-input-text label{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border-bottom:1px solid #000000;
  pointer-events:none
}

.o-input-text--editable input,.o-input-text--editable textarea,.o-input-text--editable label{
  padding-right:6em
}

.o-input-text--show-save-btn input+label .o-input-text__set--edit,.o-input-text--show-save-btn textarea+label .o-input-text__set--edit{
  z-index:1;
  opacity:0
}

.o-input-text--show-save-btn .o-input-text__set--save{
  opacity:1;
  pointer-events:auto
}

.o-input-text--show-edit-btn input+label .o-input-text__set--edit,.o-input-text--show-edit-btn textarea+label .o-input-text__set--edit{
  z-index:1;
  opacity:1
}

.o-input-text--show-edit-btn .o-input-text__set--save{
  opacity:0;
  pointer-events:none
}

.o-input-text--show-edit-btn label{
  pointer-events:auto
}

.o-input-text--show-edit-btn input:focus+label .o-input-text__set--edit,.o-input-text--show-edit-btn input:active+label .o-input-text__set--edit,.o-input-text--show-edit-btn textarea:focus+label .o-input-text__set--edit,.o-input-text--show-edit-btn textarea:active+label .o-input-text__set--edit{
  z-index:1;
  opacity:1
}

.o-input-text--show-edit-btn input:focus .o-input-text__set--save,.o-input-text--show-edit-btn input:active .o-input-text__set--save,.o-input-text--show-edit-btn textarea:focus .o-input-text__set--save,.o-input-text--show-edit-btn textarea:active .o-input-text__set--save{
  opacity:0;
  pointer-events:none
}

.o-input-text__set{
  position:absolute;
  font-size:.7em;
  text-transform:uppercase;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  cursor:pointer
}

.o-input-text__set--edit{
  z-index:1;
  right:1em;
  top:50%;
  -webkit-transform:translateY(-55%);
          transform:translateY(-55%);
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  font-weight:700;
  color:#b2b2b2;
  cursor:pointer
}

.o-input-text__set--edit .o-icon{
  margin-right:1em
}

.o-input-text__set--save{
  --color-bg: #ffde55;
  right:0;
  top:50%;
  -webkit-transform:translateY(-55%);
          transform:translateY(-55%);
  min-height:2em;
  padding:0 1em;
  opacity:0;
  pointer-events:none
}

.o-input-text__set--save .c-btn__label{
  font-size:inherit
}

.o-input-check input{
  display:none
}

.o-input-check label{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  cursor:pointer;
  color:#b2b2b2;
  -webkit-transition:color 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:color 0.2s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.o-input-check label:hover{
  color:#000000
}

.o-input-check .o-input-check__icon:before{
  content:"";
  display:block;
  width:calc(100% + .5em);
  height:0;
  background:var(--color-main);
  position:absolute;
  bottom:calc(-.5em / 2);
  left:calc(-.5em / 2);
  padding-top:calc(100% + .5em);
  border-radius:.425em;
  opacity:0;
  -webkit-transform:scale(0.5);
          transform:scale(0.5);
  -webkit-transition:all 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:all 0.2s cubic-bezier(0.47, 0, 0.745, 0.715)
}

.o-input-check .o-input-check__icon .checkbox{
  fill:transparent;
  -webkit-transition:fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715)
}

.o-input-check .o-input-check__icon .square{
  fill:#b2b2b2;
  -webkit-transition:fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:fill 0.2s cubic-bezier(0.47, 0, 0.745, 0.715)
}

.o-input-check .o-input-check__text{
  display:block;
  margin-left:1em;
  font-size:.75em;
  font-weight:700;
  text-transform:uppercase;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none
}

.o-input-check input:checked+label{
  color:#000000
}

.o-input-check input:checked+label .o-input-check__icon:before{
  opacity:1;
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.o-input-check input:checked+label .o-input-check__icon .checkbox{
  fill:#000000;
  -webkit-transition:fill 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:fill 0.2s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.o-input-check input:checked+label .o-input-check__icon .square{
  fill:transparent;
  -webkit-transition:fill 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:fill 0.2s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.c-dash-listing__header{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
      -ms-flex-direction:row;
          flex-direction:row;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  -webkit-box-align:end;
      -ms-flex-align:end;
          align-items:flex-end;
  margin-bottom:2.5rem
}

.c-dash-listing__label{
  text-align:center;
  text-transform:uppercase;
  display:none;
  letter-spacing:0.08em
}

@media (min-width: 992px){
  .c-dash-listing__label{
    display:block
  }
}

.c-dash-listing__list{
  margin:0;
  padding-left:0;
  list-style:none
}

.c-dash-listing__item{
  cursor:pointer;
  padding:3rem 0 2rem 0;
  min-height:100px;
  background-color:#ffffff;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
  border-radius:.425em;
  position:relative;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  width:100%;
  margin-bottom:0.75rem;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column
}

@media (min-width: 992px){
  .c-dash-listing__item{
    padding:0.5rem;
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row
  }
}

.c-dash-listing__item .c-form__el{
  padding:0
}

.c-dash-listing__item--add{
  padding:0;
  width:100%;
  color:#b2b2b2;
  background-color:#f6f6f6
}

.c-dash-listing__item--add .c-dash-listing__btn{
  display:block;
  width:100%;
  padding:0.5rem;
  cursor:pointer
}

.c-dash-listing__item--add .c-dash-listing__inner{
  display:grid;
  grid-gap:1em
}

.c-dash-listing__item--add .c-dash-listing__title{
  margin-top:0;
  padding-right:0;
  text-align:center
}

.c-dash-listing__item--add .c-dash-listing__icon{
  position:static;
  top:0;
  left:0
}

.c-dash-listing__item--add .c-dash-listing__icon .o-icon{
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  justify-items:center
}

.c-dash-listing__item--add .c-dash-listing__start{
  text-align:right
}

@media not all and (min-width: 48em){
  .c-dash-listing__item--add .c-dash-listing__icon{
    display:none
  }
}

@media (min-width: 480px){
  .c-dash-listing__item--add .c-dash-listing__btn{
    padding-top:2em;
    padding-bottom:2em
  }

  .c-dash-listing__item--add .c-dash-listing__inner{
    grid-template-columns:1fr 1fr;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }

  .c-dash-listing__item--add .c-dash-listing__title{
    text-align:left
  }
}

@media (min-width: 768px){
  .c-dash-listing__item--add .c-dash-listing__inner{
    grid-template-columns:3em 1fr 1fr
  }
}

@media (min-width: 1280px){
  .c-dash-listing__item:not(.c-dash-listing__item--add){
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }

  .c-dash-listing__item:not(.c-dash-listing__item--add) .c-dash-listing__show{
    display:none
  }

  .c-dash-listing__item:not(.c-dash-listing__item--add).c-dash-listing__item--incomplete .c-dash-listing__show{
    display:block;
    text-align:right
  }

  .c-dash-listing__item--add .c-dash-listing__inner{
    display:grid;
    grid-template-columns:3em 2fr 1fr;
    grid-gap:1em;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }
}

.c-dash-listing__icon{
  z-index:2;
  cursor:pointer;
  position:absolute;
  top:0.25rem;
  left:0.75rem
}

.c-dash-listing__icon .o-icon{
  font-size:16px
}

.c-dash-listing__icon-favorite{
  z-index:1;
  position:absolute;
  top:2.75rem;
  left:0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  margin:auto
}

@media (min-width: 992px){
  .c-dash-listing__icon-favorite{
    top:0;
    bottom:0
  }
}

.c-dash-listing__icon-favorite>button{
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
  background:#ffffff;
  background-color:#ffffff;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
  border-radius:.425em;
  width:40px;
  height:40px;
  cursor:pointer
}

.c-dash-listing__icon-favorite .o-icon{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  width:100%;
  height:2.5em
}

.c-dash-listing__address{
  font-family:Calibre, Helvetica Neue, Arial, sans-serif;
  font-style:normal;
  border-top:1px solid #f6f6f6
}

@media (min-width: 992px){
  .c-dash-listing__address{
    border-top:none
  }
}

@media (min-width: 1280px){
  .c-dash-listing__address{
    top:.5em
  }
}

.c-dash-listing__state{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  height:100%;
  border-top:1px solid #f6f6f6;
  padding:1.5rem 3rem
}

@media (min-width: 992px){
  .c-dash-listing__state{
    padding:0 10px;
    border-top:none
  }
}

.c-dash-listing__state .c-dash-listing__label{
  display:block;
  margin-bottom:.5rem
}

@media (min-width: 992px){
  .c-dash-listing__state .c-dash-listing__label{
    display:none
  }
}

.c-dash-listing__state .c-box,.c-dash-listing__state .c-box__inner{
  height:100%
}

.c-dash-listing__state .c-box__inner{
  height:100%
}

.c-dash-listing__state .c-box__content{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  height:100%;
  padding-right:1em;
  padding-left:1em
}

.c-dash-listing__state .svg-level-1,.c-dash-listing__state .svg-level-2,.c-dash-listing__state .svg-level-3{
  height:50px
}

@media (min-width: 1280px){
  .c-dash-listing__state .svg-level-1,.c-dash-listing__state .svg-level-2,.c-dash-listing__state .svg-level-3{
    height:4.2em
  }

  .c-dash-listing__state .c-box{
    font-size:.8rem
  }
}

@media (min-width: 1920px){
  .c-dash-listing__state .c-box{
    font-size:1rem
  }
}

.c-result-listing{
  margin-bottom:5vh
}

.c-result-listing__header{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  margin-bottom:2em
}

.c-result-listing__forces .c-result-listing__title:first-child{
  margin-top:0
}

.c-result-listing__forces .c-result-listing__title:last-child{
  margin-bottom:.5em
}

.c-result-listing__title{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:baseline;
      -ms-flex-align:baseline;
          align-items:baseline;
  font-size:1em;
  margin-top:1.5em;
  margin-bottom:1.5em
}

.c-result-listing__title strong{
  display:inline-block;
  margin-left:1em;
  -webkit-box-flex:1;
      -ms-flex:1;
          flex:1;
  text-transform:uppercase
}

.c-result-listing__item{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:baseline;
      -ms-flex-align:baseline;
          align-items:baseline;
  -webkit-box-flex:1;
      -ms-flex:1;
          flex:1;
  margin-top:.75em;
  margin-bottom:.75em
}

.c-result-listing__number{
  width:2em;
  -ms-flex-negative:0;
      flex-shrink:0;
  font-weight:700;
  text-align:center
}

.c-result-listing__text{
  font-family:Calibre, Helvetica Neue, Arial, sans-serif
}

.c-result-listing__icons{
  display:block;
  -ms-flex-negative:0;
      flex-shrink:0;
  margin-left:auto
}

.c-result-listing__icons .svg-circle{
  fill:#b2b2b2
}

.c-result-listing__icons .svg-checkbox-rounded{
  fill:#000000
}

.c-result-listing__icon{
  opacity:1;
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.1s;
  transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.1s
}

.c-result-listing__icon--checked{
  position:absolute;
  bottom:0;
  right:0;
  opacity:0;
  -webkit-transform:scale(0.85);
          transform:scale(0.85);
  -webkit-transition:all 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  transition:all 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s
}

.c-result-listing__icon--checked.is-visible{
  opacity:1;
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-transition:all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0.1s;
  transition:all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0.1s
}

.c-result-listing__icon--checked.is-visible+.c-result-listing__icon{
  opacity:0;
  -webkit-transition:all 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  transition:all 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s
}

::-moz-selection{
  text-shadow:none;
  color:#ffffff;
  background:var(--color-main)
}

::selection{
  text-shadow:none;
  color:#ffffff;
  background:var(--color-main)
}

:root{
  --color-main: #f6f6f6;
  --color-light: #ffffff
}

*,*:after,*:before{
  position:relative;
  outline:none
}

html{
  font-size:16px
}

html.is-mac-os{
  -webkit-font-smoothing:antialiased;
  -moz-font-smoothing:antialiased;
  font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  -moz-osx-font-smoothing:antialiased
}

@font-face{
  font-display:"auto";

  font-family:"Gilroy";

  src:url("/static/fonts/gilroy/gilroy-medium.woff2") format("woff2"),url("/static/fonts/gilroy/gilroy-medium.woff") format("woff");

  font-weight:500;

  font-style:normal;

  font-display:swap
}

@font-face{
  font-display:"auto";

  font-family:"Gilroy";

  src:url("/static/fonts/gilroy/gilroy-bold.woff2") format("woff2"),url("/static/fonts/gilroy/gilroy-bold.woff") format("woff");

  font-weight:700;

  font-style:normal;

  font-display:swap
}

@font-face{
  font-display:"auto";

  font-family:"Calibre";

  src:url("/static/fonts/calibre/calibre-regular.woff2") format("woff2"),url("/static/fonts/calibre/calibre-regular.woff") format("woff");

  font-weight:400;

  font-style:normal;

  font-display:swap
}

@font-face{
  font-display:"auto";

  font-family:"Calibre";

  src:url("/static/fonts/calibre/calibre-regular-italic.woff2") format("woff2"),url("/static/fonts/calibre/calibre-regular-italic.woff") format("woff");

  font-weight:400;

  font-style:italic;

  font-display:swap
}

@font-face{
  font-display:"auto";

  font-family:"Calibre";

  src:url("/static/fonts/calibre/calibre-semibold.woff2") format("woff2"),url("/static/fonts/calibre/calibre-semibold.woff") format("woff");

  font-weight:700;

  font-style:normal;

  font-display:swap
}

@font-face{
  font-display:"auto";

  font-family:"Calibre";

  src:url("/static/fonts/calibre/calibre-semibold-italic.woff2") format("woff2"),url("/static/fonts/calibre/calibre-semibold-italic.woff") format("woff");

  font-weight:700;

  font-style:italic;

  font-display:swap
}

body{
  font-family:Gilroy, Helvetica Neue, Arial, sans-serif;
  font-size:1em;
  line-height:1.45;
  color:#000000;
  width:100%;
  min-height:100vh
}

#app{
  position:static
}

[class^='t-t'],[class*=' t-t']{
  display:block;
  line-height:1.2;
  font-weight:700
}

.t-t1,.t-cms h1{
  font-size:2.25em
}

@media (min-width: 992px){
  .t-t1,.t-cms h1{
    font-size:3em
  }
}

.t-t2,.t-cms h2{
  font-size:1.5em
}

@media (min-width: 992px){
  .t-t2,.t-cms h2{
    font-size:2.25em
  }
}

.t-t3,.t-cms h3{
  font-size:1.2em
}

@media (min-width: 992px){
  .t-t3,.t-cms h3{
    font-size:1.5em
  }
}

@media (min-width: 992px){
  .t-t4,.t-cms h4{
    font-size:1.2em
  }
}

.t-uppercase{
  font-size:.875em;
  font-weight:700;
  text-transform:uppercase
}

.t-rounded{
  display:inline-block;
  margin-left:.75em;
  padding:.4em .75em;
  line-height:1;
  border:1px solid currentColor;
  border-radius:1.45em
}

.t-cms{
  font-family:Calibre, Helvetica Neue, Arial, sans-serif
}

.t-cms p,.t-cms ul,.t-cms ol,.t-cms blockquote{
  margin-bottom:1.45em
}

.t-cms h1,.t-cms h2,.t-cms h3,.t-cms h4{
  font-family:Gilroy, Helvetica Neue, Arial, sans-serif;
  margin:1.414em 0 0.5em;
  line-height:1.2;
  font-weight:700
}

.t-cms ul{
  margin:0;
  padding-left:0;
  list-style:none;
  padding-left:1em
}

.t-cms ul li{
  padding-left:2em
}

.t-cms ul li:before{
  content:"";
  display:block;
  width:.625em;
  height:.64em;
  background:none;
  position:absolute;
  top:.2em;
  left:0;
  background-image:url(../img/arrow-right.ffa8847a.svg);
  background-size:contain
}

.t-cms ul li:nth-child(n+2){
  margin-top:1em
}

.t-cms ol{
  margin:0;
  padding-left:0;
  list-style:none;
  counter-reset:list-item
}

.t-cms ol li{
  counter-increment:list-item
}

.t-cms ol li:before{
  content:counter(list-item) "- "
}

.t-cms>*:first-child{
  margin-top:0
}

.t-cms>*:last-child{
  margin-bottom:0
}

.t-cms a{
  text-decoration:underline;
  -webkit-transition:opacity .3s ease-out;
  transition:opacity .3s ease-out
}

.t-cms a:hover{
  opacity:.5
}

small,.t-s,.t-s-grey{
  font-size:.875em
}

.t-s-grey{
  color:#b2b2b2
}

.error,.c-form__error{
  --text-opacity:1;
  color:#ff9173;
  color:rgba(255, 145, 115, var(--text-opacity));
  font-size:.875em;
  display:block;
  margin-top:0.5rem;
  margin-bottom:1rem
}

.t-xs{
  font-size:.625em
}

sup{
  vertical-align:super;
  font-size:0.75em
}

a{
  color:inherit;
  text-decoration:none
}

.l-grid{
  width:100%;
  max-width:80em;
  margin-right:auto;
  margin-left:auto
}

@media (min-width: 0){
  .l-grid{
    padding-right:1rem
  }
}

@media (min-width: 480px){
  .l-grid{
    padding-right:1rem
  }
}

@media (min-width: 768px){
  .l-grid{
    padding-right:1.25rem
  }
}

@media (min-width: 992px){
  .l-grid{
    padding-right:1.25rem
  }
}

@media (min-width: 1280px){
  .l-grid{
    padding-right:1.5rem
  }
}

@media (min-width: 1440px){
  .l-grid{
    padding-right:1.5rem
  }
}

@media (min-width: 1920px){
  .l-grid{
    padding-right:1.75rem
  }
}

@media (min-width: 0){
  .l-grid{
    padding-left:1rem
  }
}

@media (min-width: 480px){
  .l-grid{
    padding-left:1rem
  }
}

@media (min-width: 768px){
  .l-grid{
    padding-left:1.25rem
  }
}

@media (min-width: 992px){
  .l-grid{
    padding-left:1.25rem
  }
}

@media (min-width: 1280px){
  .l-grid{
    padding-left:1.5rem
  }
}

@media (min-width: 1440px){
  .l-grid{
    padding-left:1.5rem
  }
}

@media (min-width: 1920px){
  .l-grid{
    padding-left:1.75rem
  }
}

.l-grid.full-width{
  width:95%;
  max-width:none
}

.l-grid__row{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
      -ms-flex-direction:row;
          flex-direction:row;
  -ms-flex-wrap:wrap;
      flex-wrap:wrap
}

@media (min-width: 0){
  .l-grid__row{
    margin-right:-1rem
  }
}

@media (min-width: 480px){
  .l-grid__row{
    margin-right:-1rem
  }
}

@media (min-width: 768px){
  .l-grid__row{
    margin-right:-1.25rem
  }
}

@media (min-width: 992px){
  .l-grid__row{
    margin-right:-1.25rem
  }
}

@media (min-width: 1280px){
  .l-grid__row{
    margin-right:-1.5rem
  }
}

@media (min-width: 1440px){
  .l-grid__row{
    margin-right:-1.5rem
  }
}

@media (min-width: 1920px){
  .l-grid__row{
    margin-right:-1.75rem
  }
}

@media (min-width: 0){
  .l-grid__row{
    margin-left:-1rem
  }
}

@media (min-width: 480px){
  .l-grid__row{
    margin-left:-1rem
  }
}

@media (min-width: 768px){
  .l-grid__row{
    margin-left:-1.25rem
  }
}

@media (min-width: 992px){
  .l-grid__row{
    margin-left:-1.25rem
  }
}

@media (min-width: 1280px){
  .l-grid__row{
    margin-left:-1.5rem
  }
}

@media (min-width: 1440px){
  .l-grid__row{
    margin-left:-1.5rem
  }
}

@media (min-width: 1920px){
  .l-grid__row{
    margin-left:-1.75rem
  }
}

@media (min-width: 0){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-right:1rem
  }
}

@media (min-width: 480px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-right:1rem
  }
}

@media (min-width: 768px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-right:1.25rem
  }
}

@media (min-width: 992px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-right:1.25rem
  }
}

@media (min-width: 1280px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-right:1.5rem
  }
}

@media (min-width: 1440px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-right:1.5rem
  }
}

@media (min-width: 1920px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-right:1.75rem
  }
}

@media (min-width: 0){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-left:1rem
  }
}

@media (min-width: 480px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-left:1rem
  }
}

@media (min-width: 768px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-left:1.25rem
  }
}

@media (min-width: 992px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-left:1.25rem
  }
}

@media (min-width: 1280px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-left:1.5rem
  }
}

@media (min-width: 1440px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-left:1.5rem
  }
}

@media (min-width: 1920px){
  .l-grid__row>[class^="w-"]:not(.no-gutter),.l-grid__row>[class*=" w-"]:not(.no-gutter),.l-grid__row>[class*=":w-"]:not(.no-gutter){
    padding-left:1.75rem
  }
}

.l-wrapper{
  max-height:100%
}

.has-modal-open .l-wrapper{
  max-height:100vh;
  overflow:hidden;
  -webkit-transition:max-height 0s .8s;
  transition:max-height 0s .8s
}

body.hide-body-behind .l-wrapper{
  -webkit-filter:blur(10px);
          filter:blur(10px);
  background-color:rgba(0,0,0,0.3)
}

@media not all and (min-width: 62em){
  .has-menu-open .l-wrapper{
    max-height:100vh;
    overflow:hidden;
    -webkit-transition:max-height 0s .8s;
    transition:max-height 0s .8s
  }
}

@media not all and (min-width: 62em){
  .l-main{
    margin-top:3.75em
  }
}

.l-section{
  --color-bg: #ffffff;
  --color-underline: #f6f6f6;
  padding-top:10vh;
  padding-bottom:10vh
}

.l-section .t-t1 strong,.l-section .t-t2 strong,.l-section .t-t3 strong{
  background:var(--color-underline);
  background:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), color-stop(.4em, var(--color-underline)));
  background:linear-gradient(rgba(0,0,0,0) calc(100% - .4em), var(--color-underline) .4em)
}

.l-section--hero .l-section__bg{
  background-color:var(--color-light)
}

.l-section--hero .t-t1 strong,.l-section--hero .t-t2 strong,.l-section--hero .t-t3 strong{
  background:var(--color-main);
  background:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), color-stop(.4em, var(--color-main)));
  background:linear-gradient(rgba(0,0,0,0) calc(100% - .4em), var(--color-main) .4em)
}

.l-section--hero .l-section__header{
  margin-bottom:0
}

.l-section ~ .l-section:last-child{
  padding-bottom:15vh
}

.l-section .o-wave{
  bottom:calc(-10vh - 2px)
}

.l-section .c-partners{
  padding-top:10vh
}

.l-section.js-reveal .l-section__bg-img img{
  opacity:0
}

.l-section.js-reveal .l-section__bg-img.view-in img{
  opacity:1;
  -webkit-transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s;
  transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) 0.4s
}

.l-section__bg{
  position:absolute;
  bottom:0;
  left:0;
  display:block;
  width:100%;
  height:100%;
  background-color:var(--color-bg)
}

.l-section__bg-img{
  position:absolute;
  bottom:0;
  left:0;
  display:block;
  width:100%;
  height:100%;
  overflow:hidden
}

.l-section__bg-img img{
  position:absolute;
  bottom:0;
  left:0;
  display:block
}

@media not all and (min-width: 48em){
  .l-section__bg-img img{
    width:100%;
    height:auto;
    -webkit-transform:scale(1.2);
            transform:scale(1.2);
    -webkit-transform-origin:50% 100%;
            transform-origin:50% 100%
  }

  .l-section__bg-img ~ .o-wave{
    position:absolute;
    left:0;
    bottom:0
  }
}

@media (min-width: 768px){
  .l-section__bg-img img{
    left:50%;
    width:auto;
    max-width:100%;
    height:auto;
    max-height:70%;
    -webkit-transform:translate(-50%, 0);
            transform:translate(-50%, 0)
  }

  .l-section__bg-img+.l-section__inner{
    padding-bottom:25vh
  }
}

.l-section__inner .c-usage{
  padding-bottom:10vh
}

.l-section__inner>div:first-child .c-usage:before{
  top:-10vh
}

@media (min-width: 992px){
  .l-section__inner .c-usage{
    padding-bottom:15vh
  }

  .l-section__inner>div:first-child .c-usage:before{
    top:calc(-10vh - 5.75em)
  }
}

.l-section__header{
  margin-bottom:2em
}

.l-section__header>*:nth-child(n+2),.l-section__content>*:nth-child(n+2){
  margin-top:1rem
}

.l-section__header+*,.l-section__content+*{
  margin-top:2em
}

.l-section__logo{
  display:inline-block;
  margin-bottom:2em
}

.l-section__img{
  display:block;
  width:100%;
  height:auto
}

.l-section__img--top{
  top:-10vh
}

.l-section__img--bottom{
  margin-top:-5vh;
  bottom:calc(-10vh - calc(45/1440 * 100vw))
}

@media (min-width: 768px){
  .l-section__img--bottom{
    bottom:-10vh
  }
}

.l-section__small{
  font-weight:700;
  color:#b2b2b2
}

.l-header{
  position:fixed;
  z-index:500;
  top:0;
  left:0;
  width:100%;
  height:3.75em;
  background-color:#ffffff;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1)
}

.l-header .c-btn{
  min-height:2.5em
}

@media not all and (min-width: 62em){
  .l-header--admin .l-header__content{
    overflow-y:auto
  }

  .l-header:after{
    content:"";
    display:block;
    width:100%;
    height:100%;
    background:none;
    position:absolute;
    top:0;
    left:0;
    -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
            box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
    pointer-events:none
  }
}

@media (min-width: 992px){
  .l-header{
    -webkit-transition:height 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition:height 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
  }

  .l-header:not(.l-header--admin){
    font-size:.9rem
  }

  .l-header:not(.l-header--admin).is-static{
    position:absolute;
    height:5.75em;
    background-color:transparent;
    -webkit-box-shadow:none;
            box-shadow:none;
    -webkit-transform:translate(0, 2.875em);
            transform:translate(0, 2.875em)
  }

  .l-header:not(.l-header--admin).is-static .l-header__logo{
    margin-right:0;
    font-size:1em
  }

  .l-header:not(.l-header--admin).is-static .l-header__logo:after{
    display:none
  }

  .l-header--admin{
    height:100%;
    width:21em;
    overflow-y:auto
  }

  .l-header--admin .l-header__inner{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }

  .l-header--admin .l-header__logo{
    z-index:1;
    position:absolute;
    top:4em;
    margin-right:0;
    margin-bottom:2em;
    font-size:1rem
  }

  .l-header--admin .l-header__logo:after{
    display:none
  }

  .l-header--admin .l-header__content{
    width:100%;
    padding:0
  }

  .p-result .l-header--admin,.p-settings .l-header--admin,.p-dashboard .l-header--admin{
    position:absolute;
    height:auto;
    background-color:transparent;
    -webkit-box-shadow:none;
            box-shadow:none;
    overflow:visible
  }

  .l-header .c-btn{
    padding-right:1em;
    padding-left:1em
  }
}

@media (min-width: 1280px){
  .l-header:not(.l-header--admin){
    font-size:1rem
  }

  .l-header:not(.l-header--admin).is-static .l-header__logo{
    font-size:1.2rem
  }

  .l-header .c-btn{
    padding-right:1.5em;
    padding-left:1.5em
  }
}

.l-header__inner{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  height:100%
}

@media (min-width: 992px){
  .l-header__inner{
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between
  }
}

.l-header__logo{
  font-size:.7rem;
  margin-right:calc(-11.5em + 2.2em)
}

.l-header__logo:after{
  content:"";
  display:block;
  width:calc(100% - 2.2em);
  height:100%;
  background:#ffffff;
  position:absolute;
  top:0;
  right:0
}

@media (min-width: 992px){
  .l-header__logo{
    -webkit-transition:margin-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition:margin-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
  }
}

.l-header__content{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between
}

@media not all and (min-width: 62em){
  .l-header__content{
    position:fixed;
    top:3.75em;
    left:0;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    width:100%;
    height:calc(100% - 3.75em);
    background-color:#ffffff;
    max-height:0;
    overflow:hidden;
    -webkit-transition:max-height 0.8s cubic-bezier(1, 0, 0, 1);
    transition:max-height 0.8s cubic-bezier(1, 0, 0, 1)
  }

  .has-menu-open .l-header__content{
    max-height:calc(100% - 3.75em);
    -webkit-transition:max-height 0.8s cubic-bezier(1, 0, 0, 1);
    transition:max-height 0.8s cubic-bezier(1, 0, 0, 1)
  }
}

@media (min-width: 992px){
  .l-header__content{
    -webkit-box-flex:1;
        -ms-flex:1;
            flex:1;
    padding-right:.5em;
    padding-left:.5em
  }
}

@media (min-width: 1280px){
  .l-header__content{
    padding-right:2em;
    padding-left:2em
  }
}

@media not all and (min-width: 62em){
  .l-header__connect{
    margin-bottom:10vh;
    opacity:0;
    -webkit-transform:scale(0.8);
            transform:scale(0.8);
    -webkit-transition:all 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
    transition:all 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s
  }

  .has-menu-open .l-header__connect{
    opacity:1;
    -webkit-transform:scale(1);
            transform:scale(1);
    -webkit-transition:all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s;
    transition:all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s
  }
}

.l-header__cta{
  --color-bg: #ffde55
}

@media not all and (min-width: 62em){
  .l-header__cta{
    margin-left:auto
  }
}

@media (min-width: 992px){
  .l-header__burger{
    display:none
  }
}

.l-header__question{
  position:absolute;
  top:50%;
  left:50%;
  padding:0.5rem 0.75rem;
  font-weight:700;
  border-radius:.425em;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%)
}

@media (min-width: 992px){
  .l-header__question{
    display:none !important
  }
}

.l-header__principe{
  margin-left:auto;
  font-size:.8em;
  text-transform:uppercase
}

.l-header__principe.o-rounded{
  --color-bg: var(--color-main)
}

.l-header__principe .o-tooltip{
  position:absolute;
  top:-.7em !important;
  right:-1.5em;
  text-transform:none;
  font-weight:initial
}

@media (min-width: 992px){
  .l-header__principe{
    display:none !important
  }
}

.l-nav--header{
  -webkit-box-flex:1;
      -ms-flex:1;
          flex:1
}

@media not all and (min-width: 62em){
  .l-nav--header{
    padding-top:2em;
    padding-bottom:2em
  }

  .l-nav--header .l-nav__list{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    -ms-flex-pack:distribute;
        justify-content:space-around;
    height:100%
  }

  .l-nav--header .l-nav__item{
    opacity:0;
    -webkit-transform:scale(0.8);
            transform:scale(0.8)
  }

  .l-nav--header .l-nav__item:nth-last-child(1){
    -webkit-transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) .1s;
    transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) .1s
  }

  .l-nav--header .l-nav__item:nth-last-child(2){
    -webkit-transition:all 0.16667s cubic-bezier(0.39, 0.575, 0.565, 1) .16667s;
    transition:all 0.16667s cubic-bezier(0.39, 0.575, 0.565, 1) .16667s
  }

  .l-nav--header .l-nav__item:nth-last-child(3){
    -webkit-transition:all 0.13333s cubic-bezier(0.39, 0.575, 0.565, 1) .23333s;
    transition:all 0.13333s cubic-bezier(0.39, 0.575, 0.565, 1) .23333s
  }

  .l-nav--header .l-nav__item:nth-last-child(4){
    -webkit-transition:all 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) .3s;
    transition:all 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) .3s
  }

  .has-menu-open .l-nav--header .l-nav__item{
    opacity:1;
    -webkit-transform:scale(1);
            transform:scale(1)
  }

  .has-menu-open .l-nav--header .l-nav__item:nth-child(1){
    -webkit-transition:all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) .5s;
    transition:all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) .5s
  }

  .has-menu-open .l-nav--header .l-nav__item:nth-child(2){
    -webkit-transition:all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) .6s;
    transition:all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) .6s
  }

  .has-menu-open .l-nav--header .l-nav__item:nth-child(3){
    -webkit-transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) .7s;
    transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) .7s
  }

  .has-menu-open .l-nav--header .l-nav__item:nth-child(4){
    -webkit-transition:all 0.15s cubic-bezier(0.39, 0.575, 0.565, 1) .8s;
    transition:all 0.15s cubic-bezier(0.39, 0.575, 0.565, 1) .8s
  }
}

@media (min-width: 992px){
  .l-nav--header{
    padding-right:.5em
  }
}

@media (min-width: 1280px){
  .l-nav--header{
    padding-right:2em
  }
}

.l-nav__list{
  margin:0;
  padding-left:0;
  list-style:none;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center
}

@media (min-width: 992px){
  .l-nav__list{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -ms-flex-pack:distribute;
        justify-content:space-around;
    width:100%
  }
}

.l-nav__link{
  display:block;
  font-weight:700;
  z-index:0;
  margin-right:-.1em;
  margin-left:-.1em;
  padding-right:.1em;
  padding-left:.1em;
  overflow:hidden
}

.l-nav__link span{
  z-index:-1;
  position:absolute;
  bottom:0;
  left:0;
  display:block;
  width:100%;
  height:.4em;
  -webkit-clip-path:inset(0 round .425em .425em .425em .425em);
          clip-path:inset(0 round .425em .425em .425em .425em)
}

.l-nav__link span:before{
  content:"";
  display:block;
  width:100%;
  height:100%;
  background:none;
  z-index:-1;
  position:absolute;
  bottom:-1px;
  left:0;
  background:rgba(255,255,255,0);
  background:-webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)),color-stop(25%, var(--color-main)),color-stop(75%, var(--color-main)),to(rgba(255,255,255,0)));
  background:linear-gradient(to right, rgba(255,255,255,0) 0%,var(--color-main) 25%,var(--color-main) 75%,rgba(255,255,255,0) 100%);
  -webkit-clip-path:inherit;
          clip-path:inherit;
  opacity:0;
  -webkit-transform:scale(0, 1) translateZ(0);
          transform:scale(0, 1) translateZ(0);
  -webkit-transition:opacity 0.1s 0.3s,-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715),-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715)
}

.l-nav__link:hover span:before{
  opacity:1;
  -webkit-transform:scale(1) translateZ(0);
          transform:scale(1) translateZ(0);
  -webkit-transition:opacity 0.1s 0s,-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.l-nav__link.is-active span:before{
  opacity:1;
  -webkit-transform:scale(2, 1) translateZ(0);
          transform:scale(2, 1) translateZ(0);
  -webkit-transition:opacity 0.1s 0s,-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1)
}

@media not all and (min-width: 62em){
  .l-nav__link{
    padding:.5em
  }

  .l-nav__link span{
    bottom:.5em
  }
}

.l-footer{
  padding-top:10vh;
  padding-bottom:1em;
  background-color:#ffffff
}

.l-footer .o-wave{
  position:absolute;
  left:0;
  bottom:calc(100% - 2px)
}

.l-footer .c-partners__list{
  -webkit-box-pack:start;
      -ms-flex-pack:start;
          justify-content:flex-start
}

.l-footer .c-partners__item{
  max-width:8em
}

@media not all and (min-width: 30em){
  .l-footer .c-partners__title{
    margin-top:1.5em
  }
}

@media (min-width: 992px){
  .l-footer{
    padding-bottom:2em
  }

  .l-footer .c-partners__item{
    max-width:9em
  }
}

.l-footer__upper{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  margin-bottom:1em
}

@media (min-width: 992px){
  .l-footer__upper{
    margin-bottom:7vh;
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between
  }
}

.l-footer__logo{
  display:block
}

.l-footer__title{
  color:#b2b2b2
}

@media (min-width: 0){
  .l-footer__title{
    padding-left:.5rem
  }
}

@media (min-width: 480px){
  .l-footer__title{
    padding-left:.5rem
  }
}

@media (min-width: 768px){
  .l-footer__title{
    padding-left:.625rem
  }
}

@media (min-width: 992px){
  .l-footer__title{
    padding-left:.625rem
  }
}

@media (min-width: 1280px){
  .l-footer__title{
    padding-left:.75rem
  }
}

@media (min-width: 1440px){
  .l-footer__title{
    padding-left:.75rem
  }
}

@media (min-width: 1920px){
  .l-footer__title{
    padding-left:.875rem
  }
}

@media not all and (min-width: 62em){
  .l-footer__title{
    display:none
  }
}

@media (min-width: 992px){
  .l-footer__title{
    width:calc(3/4 * 100%)
  }
}

.l-footer__credits{
  margin-top:2em;
  color:#b2b2b2
}

.l-footer__credits small{
  display:block
}

@media not all and (min-width: 30em){
  .l-footer__credits small{
    margin-bottom:.5em
  }
}

@media (min-width: 480px){
  .l-footer__credits{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:end;
        -ms-flex-align:end;
            align-items:flex-end;
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between
  }
}

@media (min-width: 768px){
  .l-footer__credits br{
    display:none
  }
}

@media (min-width: 992px){
  .l-footer__credits{
    margin-top:10vh
  }
}

.l-footer__sub{
  display:block;
  margin-bottom:.75em;
  color:#b2b2b2
}

.l-footer__link{
  display:inline-block
}

.l-footer__link img{
  display:block;
  width:100%;
  height:auto
}

.l-nav-footer{
  display:grid;
  grid-template-columns:repeat(2, 1fr)
}

@media (min-width: 0){
  .l-nav-footer{
    grid-column-gap:1rem
  }
}

@media (min-width: 480px){
  .l-nav-footer{
    grid-column-gap:1rem
  }
}

@media (min-width: 768px){
  .l-nav-footer{
    grid-column-gap:1.25rem
  }
}

@media (min-width: 992px){
  .l-nav-footer{
    grid-column-gap:1.25rem
  }
}

@media (min-width: 1280px){
  .l-nav-footer{
    grid-column-gap:1.5rem
  }
}

@media (min-width: 1440px){
  .l-nav-footer{
    grid-column-gap:1.5rem
  }
}

@media (min-width: 1920px){
  .l-nav-footer{
    grid-column-gap:1.75rem
  }
}

.l-nav-footer__list{
  margin:0;
  padding-left:0;
  list-style:none
}

@media (min-width: 768px){
  .l-nav-footer__list{
    margin-top:-1em
  }
}

.l-nav-footer__item{
  margin-top:.5em;
  margin-bottom:.5em
}

.l-nav-footer__item--social{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center
}

.l-nav-footer__item--social .l-nav-footer__link{
  padding-top:.3em;
  padding-bottom:.3em;
  font-size:1em
}

.l-nav-footer__item--social .l-nav-footer__link:nth-child(n+2){
  margin-left:1em
}

.l-nav-footer__item--social .l-nav-footer__link .o-icon{
  -webkit-transition:0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:0.2s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.l-nav-footer__item--social .l-nav-footer__link:hover .o-icon{
  -webkit-transform:scale(1.1);
          transform:scale(1.1)
}

.l-nav-footer__link{
  font-size:.875em;
  display:inline-block;
  padding-top:.75em;
  padding-bottom:.75em;
  z-index:0;
  margin-right:-.1em;
  margin-left:-.1em;
  padding-right:.1em;
  padding-left:.1em;
  overflow:hidden
}

.l-nav-footer__link span{
  z-index:-1;
  position:absolute;
  bottom:0;
  left:0;
  display:block;
  width:100%;
  height:.4em;
  -webkit-clip-path:inset(0 round .425em .425em .425em .425em);
          clip-path:inset(0 round .425em .425em .425em .425em)
}

.l-nav-footer__link span:before{
  content:"";
  display:block;
  width:100%;
  height:100%;
  background:none;
  z-index:-1;
  position:absolute;
  bottom:-1px;
  left:0;
  background:rgba(255,255,255,0);
  background:-webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)),color-stop(25%, var(--color-main)),color-stop(75%, var(--color-main)),to(rgba(255,255,255,0)));
  background:linear-gradient(to right, rgba(255,255,255,0) 0%,var(--color-main) 25%,var(--color-main) 75%,rgba(255,255,255,0) 100%);
  -webkit-clip-path:inherit;
          clip-path:inherit;
  opacity:0;
  -webkit-transform:scale(0, 1) translateZ(0);
          transform:scale(0, 1) translateZ(0);
  -webkit-transition:opacity 0.1s 0.3s,-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715),-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715)
}

.l-nav-footer__link:hover span:before{
  opacity:1;
  -webkit-transform:scale(1) translateZ(0);
          transform:scale(1) translateZ(0);
  -webkit-transition:opacity 0.1s 0s,-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.l-nav-footer__link.is-active span:before{
  opacity:1;
  -webkit-transform:scale(2, 1) translateZ(0);
          transform:scale(2, 1) translateZ(0);
  -webkit-transition:opacity 0.1s 0s,-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.l-nav-footer__link span{
  bottom:.75em
}

.l-sidebar{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  width:100%;
  padding-top:1em;
  padding-bottom:1em
}

@media not all and (min-width: 62em){
  .l-sidebar{
    max-width:21em;
    min-height:100%;
    margin-right:auto;
    margin-left:auto
  }

  .l-sidebar .o-user{
    width:calc(100% - 1.5em)
  }
}

@media (min-width: 992px){
  .l-sidebar{
    min-height:100%
  }
}

@media not all and (min-width: 62em){
  .l-sidebar__return{
    margin-bottom:.5em
  }
}

@media (min-width: 992px){
  .l-sidebar__return{
    margin-bottom:6rem
  }
}

@media (min-width: 1280px){
  .l-sidebar__return{
    margin-bottom:6.5rem
  }
}

.l-sidebar__nav{
  margin-top:auto;
  margin-bottom:auto;
  width:100%;
  padding-bottom:6.5em
}

.l-sidebar__bottom{
  z-index:10;
  position:fixed;
  bottom:0;
  left:0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  width:100%;
  padding:.75em;
  background-color:#ffffff
}

.l-sidebar__bottom:before{
  content:"";
  display:block;
  width:100%;
  height:2em;
  background:none;
  background:rgba(255,255,255,0);
  background:-webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)),to(#ffffff));
  background:linear-gradient(to bottom, rgba(255,255,255,0),#ffffff);
  position:absolute;
  bottom:100%;
  left:0
}

@media not all and (min-width: 62em){
  .l-sidebar__bottom{
    -webkit-transform:translate(0, 100%);
            transform:translate(0, 100%);
    -webkit-transition:-webkit-transform 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition:-webkit-transform 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition:transform 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition:transform 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035), -webkit-transform 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035)
  }

  .has-menu-open .l-sidebar__bottom{
    -webkit-transform:translate(0);
            transform:translate(0);
    -webkit-transition:-webkit-transform 1s cubic-bezier(1, 0, 0, 1);
    transition:-webkit-transform 1s cubic-bezier(1, 0, 0, 1);
    transition:transform 1s cubic-bezier(1, 0, 0, 1);
    transition:transform 1s cubic-bezier(1, 0, 0, 1), -webkit-transform 1s cubic-bezier(1, 0, 0, 1)
  }
}

@media (min-width: 992px){
  .l-sidebar__bottom{
    width:21em;
    padding-right:1em;
    padding-left:1em
  }
}

.o-user{
  width:100%;
  background-color:#ffffff;
  border-radius:.425em;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1)
}

.o-user__header{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  padding:1em 1.25em;
  border-bottom:1px solid #f6f6f6
}

.o-user__name{
  -webkit-box-flex:1;
      -ms-flex:1;
          flex:1;
  margin-left:.75em
}

.o-user__buttons{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  overflow:hidden
}

.o-user__buttons .c-btn{
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  -webkit-box-flex:1;
      -ms-flex:1;
          flex:1;
  min-height:3em;
  border-radius:0;
  -webkit-box-shadow:none;
          box-shadow:none
}

.o-user__buttons .c-btn:nth-child(n+2){
  border-left:1px solid #f6f6f6
}

.l-nav-principe__item:first-child{
  margin-top:2em
}

.l-nav-principe__item--yellow .c-box{
  --color-bg: #fffced;
  --color-rounded: #ffde55
}

.l-nav-principe__item--orange .c-box{
  --color-bg: #fff5ed;
  --color-rounded: #ff974a
}

.l-nav-principe__item--green .c-box{
  --color-bg: #f6ffe8;
  --color-rounded: #bce772
}

.l-nav-principe__item--blue-aqua .c-box{
  --color-bg: #eefbfb;
  --color-rounded: #95e4db
}

.l-nav-principe__item .c-accordion__header{
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  padding:1em;
  text-align:center
}

.l-nav-principe__item .c-accordion__title{
  padding-right:0
}

.l-nav-principe__item .c-accordion__btn{
  display:none
}

.l-nav-principe__item .c-accordion__container{
  border-top:0
}

.l-nav-principe__item .c-accordion__content{
  max-height:none;
  padding:1em
}

.l-nav-principe__item .c-box:first-child .c-box__inner{
  margin-top:0
}

.l-nav-principe__item .c-box__inner{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  padding-top:1em !important
}

.l-nav-principe__item .o-rounded{
  font-size:.8rem
}

.l-nav-principe__btn{
  display:block;
  color:#b2b2b2;
  font-weight:700;
  cursor:none;
  pointer-events:none;
  padding:0.5rem 0.75rem;
  text-align:center;
  margin:0 auto
}

.l-nav-principe__btn:nth-child(n+2){
  margin-top:.5em
}

.l-nav-principe__btn.active{
  pointer-events:auto;
  border-radius:.425em;
  background:#ffffff;
  color:#000000;
  cursor:pointer
}

.l-nav-principe__btn.is-answer{
  pointer-events:auto;
  color:#000000;
  cursor:pointer
}

.o-progress{
  width:100%;
  max-width:21em
}

.o-progress__state{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  margin-bottom:1em;
  font-size:.75em;
  font-weight:700;
  color:#b2b2b2;
  text-transform:uppercase
}

.o-progress__progress,.o-progress__timeline{
  display:block;
  width:100%;
  height:1.125em;
  border-radius:1em
}

.o-progress__timeline{
  background-color:#f6f6f6;
  overflow:hidden
}

.o-progress__progress{
  position:absolute;
  top:0;
  left:-100%;
  background-color:#95e4db;
  -webkit-transform-origin:0 50%;
          transform-origin:0 50%;
  -webkit-transition:-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1), -webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
}

@media (min-width: 992px){
  .p-page .l-section:first-child{
    padding-top:11.5em
  }
}

.p-homepage .l-section--hero .c-box{
  top:calc(10vh + calc(45/1440 * 100vw));
  max-width:24em;
  margin-right:auto;
  margin-left:auto
}

.p-homepage .l-section--hero .c-box .o-rounded{
  padding:.3em .7em;
  border-radius:.425em
}

.p-homepage .l-section--hero .c-box .c-box__inner{
  padding-bottom:calc(calc(45/1440 * 100vw) + 2em)
}

.p-homepage .l-section--hero .c-box .l-section__content{
  text-align:center
}

.p-homepage .l-section--hero .c-box .l-section__content .t-cms{
  text-align:left
}

.p-homepage .l-section:not(.l-section--hero) .c-box .l-section__content{
  padding-left:2em
}

.p-homepage .l-section:not(.l-section--hero) .c-box .l-section__content:before{
  content:"";
  display:block;
  width:.625em;
  height:.64em;
  background:none;
  position:absolute;
  top:.2em;
  left:0;
  background-image:url(../img/arrow-right.ffa8847a.svg);
  background-size:contain
}

@media not all and (min-width: 48em){
  .p-homepage .l-section--hero .l-section__bg-img img{
    top:60%;
    bottom:auto;
    -webkit-transform-origin:100% 100%;
            transform-origin:100% 100%
  }
}

@media (min-width: 768px){
  .p-homepage .l-section--hero .l-section__bg-img img{
    bottom:-10vh;
    min-width:100vmax
  }

  .p-homepage .l-section--hero .l-section__inner{
    padding-bottom:2em
  }

  .p-homepage .l-section--hero .c-box{
    top:calc(10vh + calc(45/1440 * 100vw) + 2em)
  }
}

.p-settings .l-header__content,.p-dashboard .l-header__content{
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start
}

.p-settings .l-sidebar__nav,.p-settings .l-sidebar__save,.p-dashboard .l-sidebar__nav,.p-dashboard .l-sidebar__save{
  display:none
}

.p-settings .l-section--listing,.p-dashboard .l-section--listing{
  padding-top:5vh;
  --color-bg: #fcfcfc
}

.p-settings .l-section--header,.p-dashboard .l-section--header{
  padding-bottom:5vh
}

.p-settings .l-section--header .l-section__inner,.p-dashboard .l-section--header .l-section__inner{
  width:100%
}

@media (min-width: 992px){
  .p-settings .l-section--header,.p-dashboard .l-section--header{
    padding-top:12em
  }
}

.p-dashboard__levels{
  display:grid;
  width:100%;
  grid-column-gap:1em;
  grid-template-columns:repeat(2, 1fr)
}

.p-dashboard__levels .c-box{
  margin-top:4em
}

@media (min-width: 1280px){
  .p-dashboard__levels .c-box{
    margin-top:0
  }
}

.p-dashboard__levels .c-box__inner{
  margin-top:0 !important
}

.p-dashboard__levels .c-box__content{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  text-transform:uppercase
}

.p-dashboard__levels .c-box__content .o-icon{
  margin-bottom:1em
}

@media (min-width: 480px){
  .p-dashboard__levels{
    grid-template-columns:repeat(3, 1fr)
  }
}

.p-settings .o-input-check{
  --color-main: #ff974a
}

.p-settings__btn{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  margin-top:2em;
  margin-left:auto
}

.l-header--admin{
  position:fixed;
  left:0;
  right:0;
  top:0
}

@media (min-width: 992px){
  .p-question .l-main,.p-principe .l-main{
    width:calc(100% - 21em);
    margin-left:auto
  }
}

.p-principe .l-section{
  min-height:calc(100vh - 3.75em)
}

.p-principe .p-cover{
  display:block;
  width:100vw;
  height:auto;
  bottom:-10vh;
  left:calc(50% - 50vw)
}

@media (min-width: 992px){
  .p-principe{
    min-height:100vh
  }

  .p-principe .p-cover{
    left:calc(50% - 50vw + 10.5em);
    width:calc(100vw - 21em)
  }
}

.p-question .p-question__hero{
  padding-top:0;
  padding-bottom:2em
}

.p-question .p-question__title{
  margin-bottom:1.5em
}

.p-question .p-question__nav{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between;
  width:100%
}

.p-question .p-question__nav .c-btn:only-child{
  margin-left:auto
}

.p-question .l-section__bg-img{
  background-size:150% auto;
  background-position-y:50%;
  -webkit-transition:background-position 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:background-position 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
}

@media not all and (min-width: 80em){
  .p-question .p-question__nav{
    z-index:500;
    position:fixed;
    right:0;
    bottom:0;
    width:calc(100% - 21em);
    padding:.5em 1.25em;
    background-color:#ffffff;
    -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
            box-shadow:0 0 40px 0 rgba(0,0,0,0.1)
  }
}

@media not all and (min-width: 62em){
  .p-question .p-question__hero{
    display:none
  }

  .p-question .p-question__nav{
    width:100%
  }
}

@media not all and (min-width: 48em){
  .p-question .l-section__bg-img{
    position:relative;
    height:0;
    margin-bottom:2em;
    padding-top:50%
  }

  .p-question .p-question__nav{
    padding-right:1em;
    padding-left:1em
  }
}

@media (min-width: 768px){
  .p-question .l-section__bg-img{
    left:50%;
    width:50%;
    height:100%
  }
}

@media (min-height: 768px) and (min-width: 992px){
  .p-question .p-question__hero{
    z-index:15;
    padding-top:2em;
    position:sticky;
    top:0;
    left:0;
    right:0
  }
}

@media (min-height: 768px) and (min-width: 1280px){
  .p-question .l-section__content{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column;
    height:100%
  }

  .p-question .p-question__nav{
    margin-top:auto
  }
}

.o-question{
  font-family:Gilroy, Helvetica Neue, Arial, sans-serif;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  width:100%;
  max-width:32em;
  margin:1em auto 3em auto !important;
  border-radius:.425em;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
  overflow:hidden;
  -webkit-transition:background-color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:background-color 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.o-question.has-futur{
  background-color:var(--color-light)
}

.o-question.has-futur .o-question__col{
  max-width:50%
}

.o-question__col{
  -webkit-box-flex:1;
      -ms-flex:1 1 0px;
          flex:1 1 0;
  padding:1.5em 2em
}

.o-question__col:nth-child(1){
  z-index:1;
  max-width:100%;
  background-color:#ffffff;
  -webkit-transition:max-width 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transition:max-width 0.4s cubic-bezier(0.19, 1, 0.22, 1)
}

.o-question__col:nth-child(2){
  position:absolute;
  top:0;
  right:0;
  width:50%;
  height:100%
}

.o-question__title{
  display:block;
  margin-bottom:1.5rem;
  text-align:center;
  text-transform:uppercase
}

.o-question__form{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center
}

.o-question__form .o-input-check:nth-child(2){
  margin-left:3em
}

.slide-enter-active,.slide-leave-active{
  background-color:var(--color-light);
  -webkit-transition:background-color .5s;
  transition:background-color .5s
}

.slide-enter-active .o-question__col:nth-child(1),.slide-leave-active .o-question__col:nth-child(1){
  width:50%;
  -webkit-transition:width .5s ease-out;
  transition:width .5s ease-out
}

.slide-enter .o-question__col:nth-child(1),.slide-leave-to .o-question__col:nth-child(1){
  width:100%;
  -webkit-transition:width .5s ease-out;
  transition:width .5s ease-out
}

.p-result__container{
  display:grid
}

@media (min-width: 992px){
  .p-result__container{
    grid-template-columns:repeat(2, 1fr)
  }
}

.p-result__col--index .c-box{
  margin-top:1.5em;
  margin-bottom:2em
}

.p-result__col--index .c-box .c-box__title+.c-box__inner{
  margin-top:0;
  padding-top:0;
  padding-bottom:0;
  padding-right:1em
}

.p-result__col--index .c-box .o-toggle-switch input:checked+label:after{
  border:0;
  background-color:var(--color-underline)
}

@media (min-width: 992px){
  .p-result__col--index .l-section{
    height:100%
  }

  .p-result__col--index .l-section__inner{
    margin-left:auto
  }

  .p-result__col .l-section{
    padding-top:11em
  }

  .p-result__col .l-section>.l-grid{
    position:sticky;
    top:2em
  }

  .p-result__col .l-section__inner{
    max-width:40em
  }

  @media (min-width: 0){
    .p-result__col .l-section__inner{
      padding-right:1rem
    }
  }

  @media (min-width: 480px){
    .p-result__col .l-section__inner{
      padding-right:1rem
    }
  }

  @media (min-width: 768px){
    .p-result__col .l-section__inner{
      padding-right:1.25rem
    }
  }

  @media (min-width: 992px){
    .p-result__col .l-section__inner{
      padding-right:1.25rem
    }
  }

  @media (min-width: 1280px){
    .p-result__col .l-section__inner{
      padding-right:1.5rem
    }
  }

  @media (min-width: 1440px){
    .p-result__col .l-section__inner{
      padding-right:1.5rem
    }
  }

  @media (min-width: 1920px){
    .p-result__col .l-section__inner{
      padding-right:1.75rem
    }
  }

  @media (min-width: 0){
    .p-result__col .l-section__inner{
      padding-left:1rem
    }
  }

  @media (min-width: 480px){
    .p-result__col .l-section__inner{
      padding-left:1rem
    }
  }

  @media (min-width: 768px){
    .p-result__col .l-section__inner{
      padding-left:1.25rem
    }
  }

  @media (min-width: 992px){
    .p-result__col .l-section__inner{
      padding-left:1.25rem
    }
  }

  @media (min-width: 1280px){
    .p-result__col .l-section__inner{
      padding-left:1.5rem
    }
  }

  @media (min-width: 1440px){
    .p-result__col .l-section__inner{
      padding-left:1.5rem
    }
  }

  @media (min-width: 1920px){
    .p-result__col .l-section__inner{
      padding-left:1.75rem
    }
  }
}

@media (min-width: 1280px){
  .p-result__col .l-section{
    padding-top:13em
  }

  .p-result__col--summary .l-section{
    padding-top:10vh
  }
}

.p-result__icon .o-icon+.o-rounded{
  position:absolute;
  top:-.5em;
  right:-1em
}

.p-result--switch--fiveyears{
  border-radius:5px;
  background:url(../img/result-5years.8e1e6caf.png) no-repeat center center/cover
}

.p-result--switch--present{
  border-radius:5px;
  background:url(../img/result-present.b74876cf.png) no-repeat center center/cover
}

.anim-fade-enter-active{
  -webkit-transition:opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.25s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.anim-fade-leave-active{
  -webkit-transition:opacity 0.15s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.15s cubic-bezier(0.47, 0, 0.745, 0.715)
}

.anim-fade-enter,.anim-fade-leave-to{
  opacity:0
}

.anim-text-enter,.anim-text-leave-to{
  opacity:0;
  -webkit-transform:translateY(0.5em) scale(1, 0);
          transform:translateY(0.5em) scale(1, 0)
}

.anim-text-leave,.anim-text-enter-to{
  opacity:1;
  -webkit-transform:translateY(0) scale(1);
          transform:translateY(0) scale(1)
}

.anim-text-enter-active,.anim-text-leave-active{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-transform-origin:50% 100%;
          transform-origin:50% 100%
}

.anim-text-enter,.anim-text-leave-to{
  opacity:0
}

.anim-text-leave,.anim-text-enter-to{
  opacity:1
}

.anim-text-enter-active,.anim-text-leave-active{
  -webkit-transition:opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 1s cubic-bezier(0.39, 0.575, 0.565, 1)
}

.o-loading{
  min-height:2em
}

.o-loading .o-loader{
  position:absolute
}

.o-loading.is-loading .o-loader{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex
}

.o-loader{
  z-index:10;
  position:fixed;
  top:0;
  left:0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  width:100%;
  height:100%
}

.o-loader:before{
  content:"";
  display:block;
  width:100%;
  height:100%;
  background:#ffffff;
  position:absolute;
  top:0;
  left:0;
  opacity:.7
}

.o-loader.o-loader-size-small .o-loader__inner{
  -webkit-transform:scale(0.6);
          transform:scale(0.6)
}

.o-loader__inner{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  padding:1em;
  border-radius:.425em;
  background-color:#ffffff;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1)
}

@media (min-width: 768px){
  .o-loader__inner{
    font-size:1.25rem
  }
}

.o-loader__icon{
  margin-right:.25em;
  margin-left:.25em;
  opacity:0;
  -webkit-transform:translate(0, 0.3em) scale(0.5, 0) rotateX(45deg);
          transform:translate(0, 0.3em) scale(0.5, 0) rotateX(45deg);
  -webkit-transform-origin:50% 100%;
          transform-origin:50% 100%
}

.o-loader__icon .svg-tree__bg{
  fill:#a0dcef
}

.o-loader__icon:nth-child(1){
  -webkit-animation:anim-loader-tree 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite;
          animation:anim-loader-tree 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite
}

.o-loader__icon:nth-child(1) .svg-tree__bg{
  -webkit-animation:anim-loader-color 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite;
          animation:anim-loader-color 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite
}

.o-loader__icon:nth-child(2){
  -webkit-animation:anim-loader-tree 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) .1s infinite;
          animation:anim-loader-tree 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) .1s infinite
}

.o-loader__icon:nth-child(2) .svg-tree__bg{
  -webkit-animation:anim-loader-color 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) .2s infinite;
          animation:anim-loader-color 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) .2s infinite
}

.o-loader__icon:nth-child(3){
  -webkit-animation:anim-loader-tree 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) .2s infinite;
          animation:anim-loader-tree 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) .2s infinite
}

.o-loader__icon:nth-child(3) .svg-tree__bg{
  -webkit-animation:anim-loader-color 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) .4s infinite;
          animation:anim-loader-color 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) .4s infinite
}

@-webkit-keyframes anim-loader-color{
  0%{
    fill:#a0dcef
  }

  50%{
    fill:#bce772
  }

  100%{
    fill:#a0dcef
  }
}

@keyframes anim-loader-color{
  0%{
    fill:#a0dcef
  }

  50%{
    fill:#bce772
  }

  100%{
    fill:#a0dcef
  }
}

@-webkit-keyframes anim-loader-tree{
  0%{
    opacity:0;
    -webkit-transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg);
            transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg)
  }

  5%{
    opacity:0;
    -webkit-transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg);
            transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg)
  }

  20%{
    opacity:1;
    -webkit-transform:translate(0) scale(1) rotateX(0);
            transform:translate(0) scale(1) rotateX(0)
  }

  70%{
    opacity:1;
    -webkit-transform:translate(0) scale(1) rotateX(0);
            transform:translate(0) scale(1) rotateX(0)
  }

  85%{
    opacity:0;
    -webkit-transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg);
            transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg)
  }

  100%{
    opacity:0;
    -webkit-transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg);
            transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg)
  }
}

@keyframes anim-loader-tree{
  0%{
    opacity:0;
    -webkit-transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg);
            transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg)
  }

  5%{
    opacity:0;
    -webkit-transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg);
            transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg)
  }

  20%{
    opacity:1;
    -webkit-transform:translate(0) scale(1) rotateX(0);
            transform:translate(0) scale(1) rotateX(0)
  }

  70%{
    opacity:1;
    -webkit-transform:translate(0) scale(1) rotateX(0);
            transform:translate(0) scale(1) rotateX(0)
  }

  85%{
    opacity:0;
    -webkit-transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg);
            transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg)
  }

  100%{
    opacity:0;
    -webkit-transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg);
            transform:translate(0, 0.3em) scale(0.7, 0) rotateX(45deg)
  }
}

.bg-fixed{
  background-attachment:fixed
}

.bg-local{
  background-attachment:local
}

.bg-scroll{
  background-attachment:scroll
}

.bg-clip-border{
  background-clip:border-box
}

.bg-clip-padding{
  background-clip:padding-box
}

.bg-clip-content{
  background-clip:content-box
}

.bg-clip-text{
  -webkit-background-clip:text;
          background-clip:text
}

.bg-dark{
  --bg-opacity:1;
  background-color:#000000;
  background-color:rgba(0, 0, 0, var(--bg-opacity))
}

.bg-grey{
  --bg-opacity:1;
  background-color:#b2b2b2;
  background-color:rgba(178, 178, 178, var(--bg-opacity))
}

.bg-greyLight{
  --bg-opacity:1;
  background-color:#f6f6f6;
  background-color:rgba(246, 246, 246, var(--bg-opacity))
}

.bg-light{
  --bg-opacity:1;
  background-color:#ffffff;
  background-color:rgba(255, 255, 255, var(--bg-opacity))
}

.bg-red{
  --bg-opacity:1;
  background-color:#ff9173;
  background-color:rgba(255, 145, 115, var(--bg-opacity))
}

.bg-redLight{
  --bg-opacity:1;
  background-color:#fff4f1;
  background-color:rgba(255, 244, 241, var(--bg-opacity))
}

.bg-orange{
  --bg-opacity:1;
  background-color:#ff974a;
  background-color:rgba(255, 151, 74, var(--bg-opacity))
}

.bg-orangeLight{
  --bg-opacity:1;
  background-color:#fff5ed;
  background-color:rgba(255, 245, 237, var(--bg-opacity))
}

.bg-yellowOrange{
  --bg-opacity:1;
  background-color:#ffb850;
  background-color:rgba(255, 184, 80, var(--bg-opacity))
}

.bg-yellow{
  --bg-opacity:1;
  background-color:#ffde55;
  background-color:rgba(255, 222, 85, var(--bg-opacity))
}

.bg-yellowLight{
  --bg-opacity:1;
  background-color:#fffced;
  background-color:rgba(255, 252, 237, var(--bg-opacity))
}

.bg-yellowGreen{
  --bg-opacity:1;
  background-color:#f4f44a;
  background-color:rgba(244, 244, 74, var(--bg-opacity))
}

.bg-yellowGreenLight{
  --bg-opacity:1;
  background-color:#fcfce5;
  background-color:rgba(252, 252, 229, var(--bg-opacity))
}

.bg-green{
  --bg-opacity:1;
  background-color:#bce772;
  background-color:rgba(188, 231, 114, var(--bg-opacity))
}

.bg-greenLight{
  --bg-opacity:1;
  background-color:#f6ffe8;
  background-color:rgba(246, 255, 232, var(--bg-opacity))
}

.bg-blue{
  --bg-opacity:1;
  background-color:#a0dcef;
  background-color:rgba(160, 220, 239, var(--bg-opacity))
}

.bg-blueLight{
  --bg-opacity:1;
  background-color:#e9f6ff;
  background-color:rgba(233, 246, 255, var(--bg-opacity))
}

.bg-blueAqua{
  --bg-opacity:1;
  background-color:#95e4db;
  background-color:rgba(149, 228, 219, var(--bg-opacity))
}

.bg-blueAquaLight{
  --bg-opacity:1;
  background-color:#eefbfb;
  background-color:rgba(238, 251, 251, var(--bg-opacity))
}

.hover\:bg-dark:hover{
  --bg-opacity:1;
  background-color:#000000;
  background-color:rgba(0, 0, 0, var(--bg-opacity))
}

.hover\:bg-grey:hover{
  --bg-opacity:1;
  background-color:#b2b2b2;
  background-color:rgba(178, 178, 178, var(--bg-opacity))
}

.hover\:bg-greyLight:hover{
  --bg-opacity:1;
  background-color:#f6f6f6;
  background-color:rgba(246, 246, 246, var(--bg-opacity))
}

.hover\:bg-light:hover{
  --bg-opacity:1;
  background-color:#ffffff;
  background-color:rgba(255, 255, 255, var(--bg-opacity))
}

.hover\:bg-red:hover{
  --bg-opacity:1;
  background-color:#ff9173;
  background-color:rgba(255, 145, 115, var(--bg-opacity))
}

.hover\:bg-redLight:hover{
  --bg-opacity:1;
  background-color:#fff4f1;
  background-color:rgba(255, 244, 241, var(--bg-opacity))
}

.hover\:bg-orange:hover{
  --bg-opacity:1;
  background-color:#ff974a;
  background-color:rgba(255, 151, 74, var(--bg-opacity))
}

.hover\:bg-orangeLight:hover{
  --bg-opacity:1;
  background-color:#fff5ed;
  background-color:rgba(255, 245, 237, var(--bg-opacity))
}

.hover\:bg-yellowOrange:hover{
  --bg-opacity:1;
  background-color:#ffb850;
  background-color:rgba(255, 184, 80, var(--bg-opacity))
}

.hover\:bg-yellow:hover{
  --bg-opacity:1;
  background-color:#ffde55;
  background-color:rgba(255, 222, 85, var(--bg-opacity))
}

.hover\:bg-yellowLight:hover{
  --bg-opacity:1;
  background-color:#fffced;
  background-color:rgba(255, 252, 237, var(--bg-opacity))
}

.hover\:bg-yellowGreen:hover{
  --bg-opacity:1;
  background-color:#f4f44a;
  background-color:rgba(244, 244, 74, var(--bg-opacity))
}

.hover\:bg-yellowGreenLight:hover{
  --bg-opacity:1;
  background-color:#fcfce5;
  background-color:rgba(252, 252, 229, var(--bg-opacity))
}

.hover\:bg-green:hover{
  --bg-opacity:1;
  background-color:#bce772;
  background-color:rgba(188, 231, 114, var(--bg-opacity))
}

.hover\:bg-greenLight:hover{
  --bg-opacity:1;
  background-color:#f6ffe8;
  background-color:rgba(246, 255, 232, var(--bg-opacity))
}

.hover\:bg-blue:hover{
  --bg-opacity:1;
  background-color:#a0dcef;
  background-color:rgba(160, 220, 239, var(--bg-opacity))
}

.hover\:bg-blueLight:hover{
  --bg-opacity:1;
  background-color:#e9f6ff;
  background-color:rgba(233, 246, 255, var(--bg-opacity))
}

.hover\:bg-blueAqua:hover{
  --bg-opacity:1;
  background-color:#95e4db;
  background-color:rgba(149, 228, 219, var(--bg-opacity))
}

.hover\:bg-blueAquaLight:hover{
  --bg-opacity:1;
  background-color:#eefbfb;
  background-color:rgba(238, 251, 251, var(--bg-opacity))
}

.focus\:bg-dark:focus{
  --bg-opacity:1;
  background-color:#000000;
  background-color:rgba(0, 0, 0, var(--bg-opacity))
}

.focus\:bg-grey:focus{
  --bg-opacity:1;
  background-color:#b2b2b2;
  background-color:rgba(178, 178, 178, var(--bg-opacity))
}

.focus\:bg-greyLight:focus{
  --bg-opacity:1;
  background-color:#f6f6f6;
  background-color:rgba(246, 246, 246, var(--bg-opacity))
}

.focus\:bg-light:focus{
  --bg-opacity:1;
  background-color:#ffffff;
  background-color:rgba(255, 255, 255, var(--bg-opacity))
}

.focus\:bg-red:focus{
  --bg-opacity:1;
  background-color:#ff9173;
  background-color:rgba(255, 145, 115, var(--bg-opacity))
}

.focus\:bg-redLight:focus{
  --bg-opacity:1;
  background-color:#fff4f1;
  background-color:rgba(255, 244, 241, var(--bg-opacity))
}

.focus\:bg-orange:focus{
  --bg-opacity:1;
  background-color:#ff974a;
  background-color:rgba(255, 151, 74, var(--bg-opacity))
}

.focus\:bg-orangeLight:focus{
  --bg-opacity:1;
  background-color:#fff5ed;
  background-color:rgba(255, 245, 237, var(--bg-opacity))
}

.focus\:bg-yellowOrange:focus{
  --bg-opacity:1;
  background-color:#ffb850;
  background-color:rgba(255, 184, 80, var(--bg-opacity))
}

.focus\:bg-yellow:focus{
  --bg-opacity:1;
  background-color:#ffde55;
  background-color:rgba(255, 222, 85, var(--bg-opacity))
}

.focus\:bg-yellowLight:focus{
  --bg-opacity:1;
  background-color:#fffced;
  background-color:rgba(255, 252, 237, var(--bg-opacity))
}

.focus\:bg-yellowGreen:focus{
  --bg-opacity:1;
  background-color:#f4f44a;
  background-color:rgba(244, 244, 74, var(--bg-opacity))
}

.focus\:bg-yellowGreenLight:focus{
  --bg-opacity:1;
  background-color:#fcfce5;
  background-color:rgba(252, 252, 229, var(--bg-opacity))
}

.focus\:bg-green:focus{
  --bg-opacity:1;
  background-color:#bce772;
  background-color:rgba(188, 231, 114, var(--bg-opacity))
}

.focus\:bg-greenLight:focus{
  --bg-opacity:1;
  background-color:#f6ffe8;
  background-color:rgba(246, 255, 232, var(--bg-opacity))
}

.focus\:bg-blue:focus{
  --bg-opacity:1;
  background-color:#a0dcef;
  background-color:rgba(160, 220, 239, var(--bg-opacity))
}

.focus\:bg-blueLight:focus{
  --bg-opacity:1;
  background-color:#e9f6ff;
  background-color:rgba(233, 246, 255, var(--bg-opacity))
}

.focus\:bg-blueAqua:focus{
  --bg-opacity:1;
  background-color:#95e4db;
  background-color:rgba(149, 228, 219, var(--bg-opacity))
}

.focus\:bg-blueAquaLight:focus{
  --bg-opacity:1;
  background-color:#eefbfb;
  background-color:rgba(238, 251, 251, var(--bg-opacity))
}

.bg-none{
  background-image:none
}

.bg-gradient-to-t{
  background-image:-webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
  background-image:linear-gradient(to top, var(--gradient-color-stops))
}

.bg-gradient-to-tr{
  background-image:-webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
  background-image:linear-gradient(to top right, var(--gradient-color-stops))
}

.bg-gradient-to-r{
  background-image:-webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
  background-image:linear-gradient(to right, var(--gradient-color-stops))
}

.bg-gradient-to-br{
  background-image:-webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
  background-image:linear-gradient(to bottom right, var(--gradient-color-stops))
}

.bg-gradient-to-b{
  background-image:-webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
  background-image:linear-gradient(to bottom, var(--gradient-color-stops))
}

.bg-gradient-to-bl{
  background-image:-webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
  background-image:linear-gradient(to bottom left, var(--gradient-color-stops))
}

.bg-gradient-to-l{
  background-image:-webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
  background-image:linear-gradient(to left, var(--gradient-color-stops))
}

.bg-gradient-to-tl{
  background-image:-webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
  background-image:linear-gradient(to top left, var(--gradient-color-stops))
}

.bg-opacity-0{
  --bg-opacity:0
}

.bg-opacity-25{
  --bg-opacity:0.25
}

.bg-opacity-50{
  --bg-opacity:0.5
}

.bg-opacity-75{
  --bg-opacity:0.75
}

.bg-opacity-100{
  --bg-opacity:1
}

.hover\:bg-opacity-0:hover{
  --bg-opacity:0
}

.hover\:bg-opacity-25:hover{
  --bg-opacity:0.25
}

.hover\:bg-opacity-50:hover{
  --bg-opacity:0.5
}

.hover\:bg-opacity-75:hover{
  --bg-opacity:0.75
}

.hover\:bg-opacity-100:hover{
  --bg-opacity:1
}

.focus\:bg-opacity-0:focus{
  --bg-opacity:0
}

.focus\:bg-opacity-25:focus{
  --bg-opacity:0.25
}

.focus\:bg-opacity-50:focus{
  --bg-opacity:0.5
}

.focus\:bg-opacity-75:focus{
  --bg-opacity:0.75
}

.focus\:bg-opacity-100:focus{
  --bg-opacity:1
}

.bg-bottom{
  background-position:bottom
}

.bg-center{
  background-position:center
}

.bg-left{
  background-position:left
}

.bg-left-bottom{
  background-position:left bottom
}

.bg-left-top{
  background-position:left top
}

.bg-right{
  background-position:right
}

.bg-right-bottom{
  background-position:right bottom
}

.bg-right-top{
  background-position:right top
}

.bg-top{
  background-position:top
}

.bg-repeat{
  background-repeat:repeat
}

.bg-no-repeat{
  background-repeat:no-repeat
}

.bg-repeat-x{
  background-repeat:repeat-x
}

.bg-repeat-y{
  background-repeat:repeat-y
}

.bg-repeat-round{
  background-repeat:round
}

.bg-repeat-space{
  background-repeat:space
}

.bg-auto{
  background-size:auto
}

.bg-cover{
  background-size:cover
}

.bg-contain{
  background-size:contain
}

.border-light{
  --border-opacity:1;
  border-color:#ffffff;
  border-color:rgba(255, 255, 255, var(--border-opacity))
}

.rounded{
  border-radius:.425em
}

.cursor-text{
  cursor:text
}

.block{
  display:block
}

.inline-block{
  display:inline-block
}

.inline{
  display:inline
}

.flex{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex
}

.inline-flex{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex
}

.table{
  display:table
}

.table-cell{
  display:table-cell
}

.grid{
  display:grid
}

.inline-grid{
  display:inline-grid
}

.hidden{
  display:none
}

.flex-row{
  -webkit-box-orient:horizontal;
  -webkit-box-direction:normal;
      -ms-flex-direction:row;
          flex-direction:row
}

.flex-row-reverse{
  -webkit-box-orient:horizontal;
  -webkit-box-direction:reverse;
      -ms-flex-direction:row-reverse;
          flex-direction:row-reverse
}

.flex-col{
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column
}

.flex-col-reverse{
  -webkit-box-orient:vertical;
  -webkit-box-direction:reverse;
      -ms-flex-direction:column-reverse;
          flex-direction:column-reverse
}

.flex-wrap{
  -ms-flex-wrap:wrap;
      flex-wrap:wrap
}

.flex-wrap-reverse{
  -ms-flex-wrap:wrap-reverse;
      flex-wrap:wrap-reverse
}

.flex-no-wrap{
  -ms-flex-wrap:nowrap;
      flex-wrap:nowrap
}

.place-items-auto{
  place-items:auto
}

.place-items-start{
  place-items:start
}

.place-items-end{
  place-items:end
}

.place-items-center{
  place-items:center
}

.place-items-stretch{
  place-items:stretch
}

.items-start{
  -webkit-box-align:start;
      -ms-flex-align:start;
          align-items:flex-start
}

.items-end{
  -webkit-box-align:end;
      -ms-flex-align:end;
          align-items:flex-end
}

.items-center{
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center
}

.items-baseline{
  -webkit-box-align:baseline;
      -ms-flex-align:baseline;
          align-items:baseline
}

.items-stretch{
  -webkit-box-align:stretch;
      -ms-flex-align:stretch;
          align-items:stretch
}

.justify-items-auto{
  justify-items:auto
}

.justify-items-start{
  justify-items:start
}

.justify-items-end{
  justify-items:end
}

.justify-items-center{
  justify-items:center
}

.justify-items-stretch{
  justify-items:stretch
}

.justify-start{
  -webkit-box-pack:start;
      -ms-flex-pack:start;
          justify-content:flex-start
}

.justify-end{
  -webkit-box-pack:end;
      -ms-flex-pack:end;
          justify-content:flex-end
}

.justify-center{
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center
}

.justify-between{
  -webkit-box-pack:justify;
      -ms-flex-pack:justify;
          justify-content:space-between
}

.justify-around{
  -ms-flex-pack:distribute;
      justify-content:space-around
}

.justify-evenly{
  -webkit-box-pack:space-evenly;
      -ms-flex-pack:space-evenly;
          justify-content:space-evenly
}

.justify-self-auto{
  justify-self:auto
}

.justify-self-start{
  justify-self:start
}

.justify-self-end{
  justify-self:end
}

.justify-self-center{
  justify-self:center
}

.justify-self-stretch{
  justify-self:stretch
}

.flex-1{
  -webkit-box-flex:1;
      -ms-flex:1 1 0%;
          flex:1 1 0%
}

.flex-auto{
  -webkit-box-flex:1;
      -ms-flex:1 1 auto;
          flex:1 1 auto
}

.flex-initial{
  -webkit-box-flex:0;
      -ms-flex:0 1 auto;
          flex:0 1 auto
}

.flex-none{
  -webkit-box-flex:0;
      -ms-flex:none;
          flex:none
}

.flex-grow-0{
  -webkit-box-flex:0;
      -ms-flex-positive:0;
          flex-grow:0
}

.flex-grow{
  -webkit-box-flex:1;
      -ms-flex-positive:1;
          flex-grow:1
}

.flex-shrink-0{
  -ms-flex-negative:0;
      flex-shrink:0
}

.flex-shrink{
  -ms-flex-negative:1;
      flex-shrink:1
}

.font-heading{
  font-family:Gilroy, Helvetica Neue, Arial, sans-serif
}

.font-normal{
  font-weight:400
}

.font-bold{
  font-weight:700
}

.h-0{
  height:0
}

.h-full{
  height:100%
}

.h-screen{
  height:100vh
}

.text-t1{
  font-size:3em
}

.text-t2{
  font-size:2.25em
}

.text-t3{
  font-size:1.5em
}

.text-t4{
  font-size:1.2em
}

.text-body{
  font-size:1em
}

.text-s{
  font-size:.875em
}

.text-xs{
  font-size:.625em
}

.mx-2{
  margin-left:0.5rem;
  margin-right:0.5rem
}

.my-4{
  margin-top:1rem;
  margin-bottom:1rem
}

.mx-4{
  margin-left:1rem;
  margin-right:1rem
}

.mx-auto{
  margin-left:auto;
  margin-right:auto
}

.mt-1{
  margin-top:0.25rem
}

.mt-2{
  margin-top:0.5rem
}

.mb-2{
  margin-bottom:0.5rem
}

.mr-3{
  margin-right:0.75rem
}

.mt-4{
  margin-top:1rem
}

.mr-4{
  margin-right:1rem
}

.mb-4{
  margin-bottom:1rem
}

.ml-4{
  margin-left:1rem
}

.mt-6{
  margin-top:1.5rem
}

.ml-6{
  margin-left:1.5rem
}

.mt-8{
  margin-top:2rem
}

.mr-8{
  margin-right:2rem
}

.mt-12{
  margin-top:3rem
}

.overflow-hidden{
  overflow:hidden
}

.overflow-x-hidden{
  overflow-x:hidden
}

.overflow-y-hidden{
  overflow-y:hidden
}

.p-6{
  padding:1.5rem
}

.py-2{
  padding-top:0.5rem;
  padding-bottom:0.5rem
}

.py-4{
  padding-top:1rem;
  padding-bottom:1rem
}

.px-4{
  padding-left:1rem;
  padding-right:1rem
}

.py-6{
  padding-top:1.5rem;
  padding-bottom:1.5rem
}

.px-8{
  padding-left:2rem;
  padding-right:2rem
}

.pt-0{
  padding-top:0
}

.pl-0{
  padding-left:0
}

.pt-4{
  padding-top:1rem
}

.pr-4{
  padding-right:1rem
}

.pb-4{
  padding-bottom:1rem
}

.pl-4{
  padding-left:1rem
}

.pb-6{
  padding-bottom:1.5rem
}

.static{
  position:static
}

.fixed{
  position:fixed
}

.absolute{
  position:absolute
}

.right-0{
  right:0
}

.bottom-0{
  bottom:0
}

.left-0{
  left:0
}

.resize{
  resize:both
}

.shadow{
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1)
}

.text-left{
  text-align:left
}

.text-center{
  text-align:center
}

.text-right{
  text-align:right
}

.text-justify{
  text-align:justify
}

.text-dark{
  --text-opacity:1;
  color:#000000;
  color:rgba(0, 0, 0, var(--text-opacity))
}

.text-grey{
  --text-opacity:1;
  color:#b2b2b2;
  color:rgba(178, 178, 178, var(--text-opacity))
}

.text-greyLight{
  --text-opacity:1;
  color:#f6f6f6;
  color:rgba(246, 246, 246, var(--text-opacity))
}

.text-light{
  --text-opacity:1;
  color:#ffffff;
  color:rgba(255, 255, 255, var(--text-opacity))
}

.text-red{
  --text-opacity:1;
  color:#ff9173;
  color:rgba(255, 145, 115, var(--text-opacity))
}

.text-redLight{
  --text-opacity:1;
  color:#fff4f1;
  color:rgba(255, 244, 241, var(--text-opacity))
}

.text-orange{
  --text-opacity:1;
  color:#ff974a;
  color:rgba(255, 151, 74, var(--text-opacity))
}

.text-orangeLight{
  --text-opacity:1;
  color:#fff5ed;
  color:rgba(255, 245, 237, var(--text-opacity))
}

.text-yellowOrange{
  --text-opacity:1;
  color:#ffb850;
  color:rgba(255, 184, 80, var(--text-opacity))
}

.text-yellow{
  --text-opacity:1;
  color:#ffde55;
  color:rgba(255, 222, 85, var(--text-opacity))
}

.text-yellowLight{
  --text-opacity:1;
  color:#fffced;
  color:rgba(255, 252, 237, var(--text-opacity))
}

.text-yellowGreen{
  --text-opacity:1;
  color:#f4f44a;
  color:rgba(244, 244, 74, var(--text-opacity))
}

.text-yellowGreenLight{
  --text-opacity:1;
  color:#fcfce5;
  color:rgba(252, 252, 229, var(--text-opacity))
}

.text-green{
  --text-opacity:1;
  color:#bce772;
  color:rgba(188, 231, 114, var(--text-opacity))
}

.text-greenLight{
  --text-opacity:1;
  color:#f6ffe8;
  color:rgba(246, 255, 232, var(--text-opacity))
}

.text-blue{
  --text-opacity:1;
  color:#a0dcef;
  color:rgba(160, 220, 239, var(--text-opacity))
}

.text-blueLight{
  --text-opacity:1;
  color:#e9f6ff;
  color:rgba(233, 246, 255, var(--text-opacity))
}

.text-blueAqua{
  --text-opacity:1;
  color:#95e4db;
  color:rgba(149, 228, 219, var(--text-opacity))
}

.text-blueAquaLight{
  --text-opacity:1;
  color:#eefbfb;
  color:rgba(238, 251, 251, var(--text-opacity))
}

.hover\:text-dark:hover{
  --text-opacity:1;
  color:#000000;
  color:rgba(0, 0, 0, var(--text-opacity))
}

.hover\:text-grey:hover{
  --text-opacity:1;
  color:#b2b2b2;
  color:rgba(178, 178, 178, var(--text-opacity))
}

.hover\:text-greyLight:hover{
  --text-opacity:1;
  color:#f6f6f6;
  color:rgba(246, 246, 246, var(--text-opacity))
}

.hover\:text-light:hover{
  --text-opacity:1;
  color:#ffffff;
  color:rgba(255, 255, 255, var(--text-opacity))
}

.hover\:text-red:hover{
  --text-opacity:1;
  color:#ff9173;
  color:rgba(255, 145, 115, var(--text-opacity))
}

.hover\:text-redLight:hover{
  --text-opacity:1;
  color:#fff4f1;
  color:rgba(255, 244, 241, var(--text-opacity))
}

.hover\:text-orange:hover{
  --text-opacity:1;
  color:#ff974a;
  color:rgba(255, 151, 74, var(--text-opacity))
}

.hover\:text-orangeLight:hover{
  --text-opacity:1;
  color:#fff5ed;
  color:rgba(255, 245, 237, var(--text-opacity))
}

.hover\:text-yellowOrange:hover{
  --text-opacity:1;
  color:#ffb850;
  color:rgba(255, 184, 80, var(--text-opacity))
}

.hover\:text-yellow:hover{
  --text-opacity:1;
  color:#ffde55;
  color:rgba(255, 222, 85, var(--text-opacity))
}

.hover\:text-yellowLight:hover{
  --text-opacity:1;
  color:#fffced;
  color:rgba(255, 252, 237, var(--text-opacity))
}

.hover\:text-yellowGreen:hover{
  --text-opacity:1;
  color:#f4f44a;
  color:rgba(244, 244, 74, var(--text-opacity))
}

.hover\:text-yellowGreenLight:hover{
  --text-opacity:1;
  color:#fcfce5;
  color:rgba(252, 252, 229, var(--text-opacity))
}

.hover\:text-green:hover{
  --text-opacity:1;
  color:#bce772;
  color:rgba(188, 231, 114, var(--text-opacity))
}

.hover\:text-greenLight:hover{
  --text-opacity:1;
  color:#f6ffe8;
  color:rgba(246, 255, 232, var(--text-opacity))
}

.hover\:text-blue:hover{
  --text-opacity:1;
  color:#a0dcef;
  color:rgba(160, 220, 239, var(--text-opacity))
}

.hover\:text-blueLight:hover{
  --text-opacity:1;
  color:#e9f6ff;
  color:rgba(233, 246, 255, var(--text-opacity))
}

.hover\:text-blueAqua:hover{
  --text-opacity:1;
  color:#95e4db;
  color:rgba(149, 228, 219, var(--text-opacity))
}

.hover\:text-blueAquaLight:hover{
  --text-opacity:1;
  color:#eefbfb;
  color:rgba(238, 251, 251, var(--text-opacity))
}

.focus\:text-dark:focus{
  --text-opacity:1;
  color:#000000;
  color:rgba(0, 0, 0, var(--text-opacity))
}

.focus\:text-grey:focus{
  --text-opacity:1;
  color:#b2b2b2;
  color:rgba(178, 178, 178, var(--text-opacity))
}

.focus\:text-greyLight:focus{
  --text-opacity:1;
  color:#f6f6f6;
  color:rgba(246, 246, 246, var(--text-opacity))
}

.focus\:text-light:focus{
  --text-opacity:1;
  color:#ffffff;
  color:rgba(255, 255, 255, var(--text-opacity))
}

.focus\:text-red:focus{
  --text-opacity:1;
  color:#ff9173;
  color:rgba(255, 145, 115, var(--text-opacity))
}

.focus\:text-redLight:focus{
  --text-opacity:1;
  color:#fff4f1;
  color:rgba(255, 244, 241, var(--text-opacity))
}

.focus\:text-orange:focus{
  --text-opacity:1;
  color:#ff974a;
  color:rgba(255, 151, 74, var(--text-opacity))
}

.focus\:text-orangeLight:focus{
  --text-opacity:1;
  color:#fff5ed;
  color:rgba(255, 245, 237, var(--text-opacity))
}

.focus\:text-yellowOrange:focus{
  --text-opacity:1;
  color:#ffb850;
  color:rgba(255, 184, 80, var(--text-opacity))
}

.focus\:text-yellow:focus{
  --text-opacity:1;
  color:#ffde55;
  color:rgba(255, 222, 85, var(--text-opacity))
}

.focus\:text-yellowLight:focus{
  --text-opacity:1;
  color:#fffced;
  color:rgba(255, 252, 237, var(--text-opacity))
}

.focus\:text-yellowGreen:focus{
  --text-opacity:1;
  color:#f4f44a;
  color:rgba(244, 244, 74, var(--text-opacity))
}

.focus\:text-yellowGreenLight:focus{
  --text-opacity:1;
  color:#fcfce5;
  color:rgba(252, 252, 229, var(--text-opacity))
}

.focus\:text-green:focus{
  --text-opacity:1;
  color:#bce772;
  color:rgba(188, 231, 114, var(--text-opacity))
}

.focus\:text-greenLight:focus{
  --text-opacity:1;
  color:#f6ffe8;
  color:rgba(246, 255, 232, var(--text-opacity))
}

.focus\:text-blue:focus{
  --text-opacity:1;
  color:#a0dcef;
  color:rgba(160, 220, 239, var(--text-opacity))
}

.focus\:text-blueLight:focus{
  --text-opacity:1;
  color:#e9f6ff;
  color:rgba(233, 246, 255, var(--text-opacity))
}

.focus\:text-blueAqua:focus{
  --text-opacity:1;
  color:#95e4db;
  color:rgba(149, 228, 219, var(--text-opacity))
}

.focus\:text-blueAquaLight:focus{
  --text-opacity:1;
  color:#eefbfb;
  color:rgba(238, 251, 251, var(--text-opacity))
}

.text-opacity-0{
  --text-opacity:0
}

.text-opacity-25{
  --text-opacity:0.25
}

.text-opacity-50{
  --text-opacity:0.5
}

.text-opacity-75{
  --text-opacity:0.75
}

.text-opacity-100{
  --text-opacity:1
}

.hover\:text-opacity-0:hover{
  --text-opacity:0
}

.hover\:text-opacity-25:hover{
  --text-opacity:0.25
}

.hover\:text-opacity-50:hover{
  --text-opacity:0.5
}

.hover\:text-opacity-75:hover{
  --text-opacity:0.75
}

.hover\:text-opacity-100:hover{
  --text-opacity:1
}

.focus\:text-opacity-0:focus{
  --text-opacity:0
}

.focus\:text-opacity-25:focus{
  --text-opacity:0.25
}

.focus\:text-opacity-50:focus{
  --text-opacity:0.5
}

.focus\:text-opacity-75:focus{
  --text-opacity:0.75
}

.focus\:text-opacity-100:focus{
  --text-opacity:1
}

.uppercase{
  text-transform:uppercase
}

.normal-case{
  text-transform:none
}

.underline{
  text-decoration:underline
}

.select-text{
  -webkit-user-select:text;
     -moz-user-select:text;
      -ms-user-select:text;
          user-select:text
}

.align-text-top{
  vertical-align:text-top
}

.align-text-bottom{
  vertical-align:text-bottom
}

.visible{
  visibility:visible
}

.invisible{
  visibility:hidden
}

.w-1\/1{
  width:100%
}

.w-full{
  width:100%
}

.w-1\/2{
  width:50%
}

.w-10\/12{
  width:83.333333%
}

.w-screen{
  width:100vw
}

.transform{
  --transform-translate-x:0;
  --transform-translate-y:0;
  --transform-rotate:0;
  --transform-skew-x:0;
  --transform-skew-y:0;
  --transform-scale-x:1;
  --transform-scale-y:1;
  -webkit-transform:translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform:translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transition{
  -webkit-transition-property:background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property:background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-box-shadow, -webkit-transform
}

@-webkit-keyframes spin{
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}

@keyframes spin{
  to{
    -webkit-transform:rotate(360deg);
            transform:rotate(360deg)
  }
}

@keyframes ping{
  75%, 100%{
    -webkit-transform:scale(2);
            transform:scale(2);
    opacity:0
  }
}

@keyframes pulse{
  50%{
    opacity:.5
  }
}

@keyframes bounce{
  0%, 100%{
    -webkit-transform:translateY(-25%);
            transform:translateY(-25%);
    -webkit-animation-timing-function:cubic-bezier(0.8,0,1,1);
            animation-timing-function:cubic-bezier(0.8,0,1,1)
  }

  50%{
    -webkit-transform:none;
            transform:none;
    -webkit-animation-timing-function:cubic-bezier(0,0,0.2,1);
            animation-timing-function:cubic-bezier(0,0,0.2,1)
  }
}

@media (min-width: 0){

  .xxs\:bg-fixed{
    background-attachment:fixed
  }

  .xxs\:bg-local{
    background-attachment:local
  }

  .xxs\:bg-scroll{
    background-attachment:scroll
  }

  .xxs\:bg-clip-border{
    background-clip:border-box
  }

  .xxs\:bg-clip-padding{
    background-clip:padding-box
  }

  .xxs\:bg-clip-content{
    background-clip:content-box
  }

  .xxs\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .xxs\:bg-dark{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xxs\:bg-grey{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xxs\:bg-greyLight{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xxs\:bg-light{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xxs\:bg-red{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xxs\:bg-redLight{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xxs\:bg-orange{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xxs\:bg-orangeLight{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xxs\:bg-yellowOrange{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xxs\:bg-yellow{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xxs\:bg-yellowLight{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xxs\:bg-yellowGreen{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xxs\:bg-yellowGreenLight{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xxs\:bg-green{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xxs\:bg-greenLight{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xxs\:bg-blue{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xxs\:bg-blueLight{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xxs\:bg-blueAqua{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xxs\:bg-blueAquaLight{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xxs\:hover\:bg-dark:hover{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xxs\:hover\:bg-grey:hover{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xxs\:hover\:bg-greyLight:hover{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xxs\:hover\:bg-light:hover{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xxs\:hover\:bg-red:hover{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xxs\:hover\:bg-redLight:hover{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xxs\:hover\:bg-orange:hover{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xxs\:hover\:bg-orangeLight:hover{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xxs\:hover\:bg-yellowOrange:hover{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xxs\:hover\:bg-yellow:hover{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xxs\:hover\:bg-yellowLight:hover{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xxs\:hover\:bg-yellowGreen:hover{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xxs\:hover\:bg-yellowGreenLight:hover{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xxs\:hover\:bg-green:hover{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xxs\:hover\:bg-greenLight:hover{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xxs\:hover\:bg-blue:hover{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xxs\:hover\:bg-blueLight:hover{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xxs\:hover\:bg-blueAqua:hover{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xxs\:hover\:bg-blueAquaLight:hover{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xxs\:focus\:bg-dark:focus{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xxs\:focus\:bg-grey:focus{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xxs\:focus\:bg-greyLight:focus{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xxs\:focus\:bg-light:focus{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xxs\:focus\:bg-red:focus{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xxs\:focus\:bg-redLight:focus{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xxs\:focus\:bg-orange:focus{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xxs\:focus\:bg-orangeLight:focus{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xxs\:focus\:bg-yellowOrange:focus{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xxs\:focus\:bg-yellow:focus{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xxs\:focus\:bg-yellowLight:focus{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xxs\:focus\:bg-yellowGreen:focus{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xxs\:focus\:bg-yellowGreenLight:focus{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xxs\:focus\:bg-green:focus{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xxs\:focus\:bg-greenLight:focus{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xxs\:focus\:bg-blue:focus{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xxs\:focus\:bg-blueLight:focus{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xxs\:focus\:bg-blueAqua:focus{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xxs\:focus\:bg-blueAquaLight:focus{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xxs\:bg-none{
    background-image:none
  }

  .xxs\:bg-gradient-to-t{
    background-image:-webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top, var(--gradient-color-stops))
  }

  .xxs\:bg-gradient-to-tr{
    background-image:-webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top right, var(--gradient-color-stops))
  }

  .xxs\:bg-gradient-to-r{
    background-image:-webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to right, var(--gradient-color-stops))
  }

  .xxs\:bg-gradient-to-br{
    background-image:-webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .xxs\:bg-gradient-to-b{
    background-image:-webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .xxs\:bg-gradient-to-bl{
    background-image:-webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .xxs\:bg-gradient-to-l{
    background-image:-webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to left, var(--gradient-color-stops))
  }

  .xxs\:bg-gradient-to-tl{
    background-image:-webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top left, var(--gradient-color-stops))
  }

  .xxs\:bg-opacity-0{
    --bg-opacity:0
  }

  .xxs\:bg-opacity-25{
    --bg-opacity:0.25
  }

  .xxs\:bg-opacity-50{
    --bg-opacity:0.5
  }

  .xxs\:bg-opacity-75{
    --bg-opacity:0.75
  }

  .xxs\:bg-opacity-100{
    --bg-opacity:1
  }

  .xxs\:hover\:bg-opacity-0:hover{
    --bg-opacity:0
  }

  .xxs\:hover\:bg-opacity-25:hover{
    --bg-opacity:0.25
  }

  .xxs\:hover\:bg-opacity-50:hover{
    --bg-opacity:0.5
  }

  .xxs\:hover\:bg-opacity-75:hover{
    --bg-opacity:0.75
  }

  .xxs\:hover\:bg-opacity-100:hover{
    --bg-opacity:1
  }

  .xxs\:focus\:bg-opacity-0:focus{
    --bg-opacity:0
  }

  .xxs\:focus\:bg-opacity-25:focus{
    --bg-opacity:0.25
  }

  .xxs\:focus\:bg-opacity-50:focus{
    --bg-opacity:0.5
  }

  .xxs\:focus\:bg-opacity-75:focus{
    --bg-opacity:0.75
  }

  .xxs\:focus\:bg-opacity-100:focus{
    --bg-opacity:1
  }

  .xxs\:bg-bottom{
    background-position:bottom
  }

  .xxs\:bg-center{
    background-position:center
  }

  .xxs\:bg-left{
    background-position:left
  }

  .xxs\:bg-left-bottom{
    background-position:left bottom
  }

  .xxs\:bg-left-top{
    background-position:left top
  }

  .xxs\:bg-right{
    background-position:right
  }

  .xxs\:bg-right-bottom{
    background-position:right bottom
  }

  .xxs\:bg-right-top{
    background-position:right top
  }

  .xxs\:bg-top{
    background-position:top
  }

  .xxs\:bg-repeat{
    background-repeat:repeat
  }

  .xxs\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .xxs\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .xxs\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .xxs\:bg-repeat-round{
    background-repeat:round
  }

  .xxs\:bg-repeat-space{
    background-repeat:space
  }

  .xxs\:bg-auto{
    background-size:auto
  }

  .xxs\:bg-cover{
    background-size:cover
  }

  .xxs\:bg-contain{
    background-size:contain
  }

  .xxs\:block{
    display:block
  }

  .xxs\:inline-block{
    display:inline-block
  }

  .xxs\:inline{
    display:inline
  }

  .xxs\:flex{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
  }

  .xxs\:inline-flex{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex
  }

  .xxs\:inline-grid{
    display:inline-grid
  }

  .xxs\:hidden{
    display:none
  }

  .xxs\:flex-row{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row
  }

  .xxs\:flex-row-reverse{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:reverse;
        -ms-flex-direction:row-reverse;
            flex-direction:row-reverse
  }

  .xxs\:flex-col{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column
  }

  .xxs\:flex-col-reverse{
    -webkit-box-orient:vertical;
    -webkit-box-direction:reverse;
        -ms-flex-direction:column-reverse;
            flex-direction:column-reverse
  }

  .xxs\:flex-wrap{
    -ms-flex-wrap:wrap;
        flex-wrap:wrap
  }

  .xxs\:flex-wrap-reverse{
    -ms-flex-wrap:wrap-reverse;
        flex-wrap:wrap-reverse
  }

  .xxs\:flex-no-wrap{
    -ms-flex-wrap:nowrap;
        flex-wrap:nowrap
  }

  .xxs\:place-items-auto{
    place-items:auto
  }

  .xxs\:place-items-start{
    place-items:start
  }

  .xxs\:place-items-end{
    place-items:end
  }

  .xxs\:place-items-center{
    place-items:center
  }

  .xxs\:place-items-stretch{
    place-items:stretch
  }

  .xxs\:items-start{
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start
  }

  .xxs\:items-end{
    -webkit-box-align:end;
        -ms-flex-align:end;
            align-items:flex-end
  }

  .xxs\:items-center{
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }

  .xxs\:items-baseline{
    -webkit-box-align:baseline;
        -ms-flex-align:baseline;
            align-items:baseline
  }

  .xxs\:items-stretch{
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch
  }

  .xxs\:justify-items-auto{
    justify-items:auto
  }

  .xxs\:justify-items-start{
    justify-items:start
  }

  .xxs\:justify-items-end{
    justify-items:end
  }

  .xxs\:justify-items-center{
    justify-items:center
  }

  .xxs\:justify-items-stretch{
    justify-items:stretch
  }

  .xxs\:justify-start{
    -webkit-box-pack:start;
        -ms-flex-pack:start;
            justify-content:flex-start
  }

  .xxs\:justify-end{
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end
  }

  .xxs\:justify-center{
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center
  }

  .xxs\:justify-between{
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between
  }

  .xxs\:justify-around{
    -ms-flex-pack:distribute;
        justify-content:space-around
  }

  .xxs\:justify-evenly{
    -webkit-box-pack:space-evenly;
        -ms-flex-pack:space-evenly;
            justify-content:space-evenly
  }

  .xxs\:justify-self-auto{
    justify-self:auto
  }

  .xxs\:justify-self-start{
    justify-self:start
  }

  .xxs\:justify-self-end{
    justify-self:end
  }

  .xxs\:justify-self-center{
    justify-self:center
  }

  .xxs\:justify-self-stretch{
    justify-self:stretch
  }

  .xxs\:flex-1{
    -webkit-box-flex:1;
        -ms-flex:1 1 0%;
            flex:1 1 0%
  }

  .xxs\:flex-auto{
    -webkit-box-flex:1;
        -ms-flex:1 1 auto;
            flex:1 1 auto
  }

  .xxs\:flex-initial{
    -webkit-box-flex:0;
        -ms-flex:0 1 auto;
            flex:0 1 auto
  }

  .xxs\:flex-none{
    -webkit-box-flex:0;
        -ms-flex:none;
            flex:none
  }

  .xxs\:flex-grow-0{
    -webkit-box-flex:0;
        -ms-flex-positive:0;
            flex-grow:0
  }

  .xxs\:flex-grow{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1
  }

  .xxs\:flex-shrink-0{
    -ms-flex-negative:0;
        flex-shrink:0
  }

  .xxs\:flex-shrink{
    -ms-flex-negative:1;
        flex-shrink:1
  }

  .xxs\:h-0{
    height:0
  }

  .xxs\:h-auto{
    height:auto
  }

  .xxs\:h-1\/1{
    height:100%
  }

  .xxs\:h-full{
    height:100%
  }

  .xxs\:h-1\/2{
    height:50%
  }

  .xxs\:h-1\/3{
    height:33.333333%
  }

  .xxs\:h-2\/3{
    height:66.666667%
  }

  .xxs\:h-1\/4{
    height:25%
  }

  .xxs\:h-2\/4{
    height:50%
  }

  .xxs\:h-3\/4{
    height:75%
  }

  .xxs\:h-1\/5{
    height:20%
  }

  .xxs\:h-2\/5{
    height:40%
  }

  .xxs\:h-3\/5{
    height:60%
  }

  .xxs\:h-4\/5{
    height:80%
  }

  .xxs\:h-1\/6{
    height:16.666667%
  }

  .xxs\:h-2\/6{
    height:33.333333%
  }

  .xxs\:h-3\/6{
    height:50%
  }

  .xxs\:h-4\/6{
    height:66.666667%
  }

  .xxs\:h-5\/6{
    height:83.333333%
  }

  .xxs\:h-1\/12{
    height:8.333333%
  }

  .xxs\:h-2\/12{
    height:16.666667%
  }

  .xxs\:h-3\/12{
    height:25%
  }

  .xxs\:h-4\/12{
    height:33.333333%
  }

  .xxs\:h-5\/12{
    height:41.666667%
  }

  .xxs\:h-6\/12{
    height:50%
  }

  .xxs\:h-7\/12{
    height:58.333333%
  }

  .xxs\:h-8\/12{
    height:66.666667%
  }

  .xxs\:h-9\/12{
    height:75%
  }

  .xxs\:h-10\/12{
    height:83.333333%
  }

  .xxs\:h-11\/12{
    height:91.666667%
  }

  .xxs\:h-12\/12{
    height:100%
  }

  .xxs\:h-screen{
    height:100vh
  }

  .xxs\:text-t1{
    font-size:3em
  }

  .xxs\:text-t2{
    font-size:2.25em
  }

  .xxs\:text-t3{
    font-size:1.5em
  }

  .xxs\:text-t4{
    font-size:1.2em
  }

  .xxs\:text-body{
    font-size:1em
  }

  .xxs\:text-s{
    font-size:.875em
  }

  .xxs\:text-xs{
    font-size:.625em
  }

  .xxs\:overflow-hidden{
    overflow:hidden
  }

  .xxs\:overflow-x-hidden{
    overflow-x:hidden
  }

  .xxs\:overflow-y-hidden{
    overflow-y:hidden
  }

  .xxs\:text-left{
    text-align:left
  }

  .xxs\:text-center{
    text-align:center
  }

  .xxs\:text-right{
    text-align:right
  }

  .xxs\:text-justify{
    text-align:justify
  }

  .xxs\:text-dark{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xxs\:text-grey{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xxs\:text-greyLight{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xxs\:text-light{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xxs\:text-red{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xxs\:text-redLight{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xxs\:text-orange{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xxs\:text-orangeLight{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xxs\:text-yellowOrange{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xxs\:text-yellow{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xxs\:text-yellowLight{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xxs\:text-yellowGreen{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xxs\:text-yellowGreenLight{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xxs\:text-green{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xxs\:text-greenLight{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xxs\:text-blue{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xxs\:text-blueLight{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xxs\:text-blueAqua{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xxs\:text-blueAquaLight{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xxs\:hover\:text-dark:hover{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xxs\:hover\:text-grey:hover{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xxs\:hover\:text-greyLight:hover{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xxs\:hover\:text-light:hover{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xxs\:hover\:text-red:hover{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xxs\:hover\:text-redLight:hover{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xxs\:hover\:text-orange:hover{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xxs\:hover\:text-orangeLight:hover{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xxs\:hover\:text-yellowOrange:hover{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xxs\:hover\:text-yellow:hover{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xxs\:hover\:text-yellowLight:hover{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xxs\:hover\:text-yellowGreen:hover{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xxs\:hover\:text-yellowGreenLight:hover{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xxs\:hover\:text-green:hover{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xxs\:hover\:text-greenLight:hover{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xxs\:hover\:text-blue:hover{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xxs\:hover\:text-blueLight:hover{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xxs\:hover\:text-blueAqua:hover{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xxs\:hover\:text-blueAquaLight:hover{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xxs\:focus\:text-dark:focus{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xxs\:focus\:text-grey:focus{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xxs\:focus\:text-greyLight:focus{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xxs\:focus\:text-light:focus{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xxs\:focus\:text-red:focus{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xxs\:focus\:text-redLight:focus{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xxs\:focus\:text-orange:focus{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xxs\:focus\:text-orangeLight:focus{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xxs\:focus\:text-yellowOrange:focus{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xxs\:focus\:text-yellow:focus{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xxs\:focus\:text-yellowLight:focus{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xxs\:focus\:text-yellowGreen:focus{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xxs\:focus\:text-yellowGreenLight:focus{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xxs\:focus\:text-green:focus{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xxs\:focus\:text-greenLight:focus{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xxs\:focus\:text-blue:focus{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xxs\:focus\:text-blueLight:focus{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xxs\:focus\:text-blueAqua:focus{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xxs\:focus\:text-blueAquaLight:focus{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xxs\:text-opacity-0{
    --text-opacity:0
  }

  .xxs\:text-opacity-25{
    --text-opacity:0.25
  }

  .xxs\:text-opacity-50{
    --text-opacity:0.5
  }

  .xxs\:text-opacity-75{
    --text-opacity:0.75
  }

  .xxs\:text-opacity-100{
    --text-opacity:1
  }

  .xxs\:hover\:text-opacity-0:hover{
    --text-opacity:0
  }

  .xxs\:hover\:text-opacity-25:hover{
    --text-opacity:0.25
  }

  .xxs\:hover\:text-opacity-50:hover{
    --text-opacity:0.5
  }

  .xxs\:hover\:text-opacity-75:hover{
    --text-opacity:0.75
  }

  .xxs\:hover\:text-opacity-100:hover{
    --text-opacity:1
  }

  .xxs\:focus\:text-opacity-0:focus{
    --text-opacity:0
  }

  .xxs\:focus\:text-opacity-25:focus{
    --text-opacity:0.25
  }

  .xxs\:focus\:text-opacity-50:focus{
    --text-opacity:0.5
  }

  .xxs\:focus\:text-opacity-75:focus{
    --text-opacity:0.75
  }

  .xxs\:focus\:text-opacity-100:focus{
    --text-opacity:1
  }

  .xxs\:select-text{
    -webkit-user-select:text;
       -moz-user-select:text;
        -ms-user-select:text;
            user-select:text
  }

  .xxs\:align-text-top{
    vertical-align:text-top
  }

  .xxs\:align-text-bottom{
    vertical-align:text-bottom
  }

  .xxs\:w-0{
    width:0
  }

  .xxs\:w-auto{
    width:auto
  }

  .xxs\:w-1\/1{
    width:100%
  }

  .xxs\:w-full{
    width:100%
  }

  .xxs\:w-1\/2{
    width:50%
  }

  .xxs\:w-1\/3{
    width:33.333333%
  }

  .xxs\:w-2\/3{
    width:66.666667%
  }

  .xxs\:w-1\/4{
    width:25%
  }

  .xxs\:w-2\/4{
    width:50%
  }

  .xxs\:w-3\/4{
    width:75%
  }

  .xxs\:w-1\/5{
    width:20%
  }

  .xxs\:w-2\/5{
    width:40%
  }

  .xxs\:w-3\/5{
    width:60%
  }

  .xxs\:w-4\/5{
    width:80%
  }

  .xxs\:w-1\/6{
    width:16.666667%
  }

  .xxs\:w-2\/6{
    width:33.333333%
  }

  .xxs\:w-3\/6{
    width:50%
  }

  .xxs\:w-4\/6{
    width:66.666667%
  }

  .xxs\:w-5\/6{
    width:83.333333%
  }

  .xxs\:w-1\/12{
    width:8.333333%
  }

  .xxs\:w-2\/12{
    width:16.666667%
  }

  .xxs\:w-3\/12{
    width:25%
  }

  .xxs\:w-4\/12{
    width:33.333333%
  }

  .xxs\:w-5\/12{
    width:41.666667%
  }

  .xxs\:w-6\/12{
    width:50%
  }

  .xxs\:w-7\/12{
    width:58.333333%
  }

  .xxs\:w-8\/12{
    width:66.666667%
  }

  .xxs\:w-9\/12{
    width:75%
  }

  .xxs\:w-10\/12{
    width:83.333333%
  }

  .xxs\:w-11\/12{
    width:91.666667%
  }

  .xxs\:w-12\/12{
    width:100%
  }

  .xxs\:w-screen{
    width:100vw
  }
}

@media (min-width: 480px){

  .xs\:bg-fixed{
    background-attachment:fixed
  }

  .xs\:bg-local{
    background-attachment:local
  }

  .xs\:bg-scroll{
    background-attachment:scroll
  }

  .xs\:bg-clip-border{
    background-clip:border-box
  }

  .xs\:bg-clip-padding{
    background-clip:padding-box
  }

  .xs\:bg-clip-content{
    background-clip:content-box
  }

  .xs\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .xs\:bg-dark{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xs\:bg-grey{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xs\:bg-greyLight{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xs\:bg-light{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xs\:bg-red{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xs\:bg-redLight{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xs\:bg-orange{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xs\:bg-orangeLight{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xs\:bg-yellowOrange{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xs\:bg-yellow{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xs\:bg-yellowLight{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xs\:bg-yellowGreen{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xs\:bg-yellowGreenLight{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xs\:bg-green{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xs\:bg-greenLight{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xs\:bg-blue{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xs\:bg-blueLight{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xs\:bg-blueAqua{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xs\:bg-blueAquaLight{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xs\:hover\:bg-dark:hover{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xs\:hover\:bg-grey:hover{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xs\:hover\:bg-greyLight:hover{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xs\:hover\:bg-light:hover{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xs\:hover\:bg-red:hover{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xs\:hover\:bg-redLight:hover{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xs\:hover\:bg-orange:hover{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xs\:hover\:bg-orangeLight:hover{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xs\:hover\:bg-yellowOrange:hover{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xs\:hover\:bg-yellow:hover{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xs\:hover\:bg-yellowLight:hover{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xs\:hover\:bg-yellowGreen:hover{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xs\:hover\:bg-yellowGreenLight:hover{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xs\:hover\:bg-green:hover{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xs\:hover\:bg-greenLight:hover{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xs\:hover\:bg-blue:hover{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xs\:hover\:bg-blueLight:hover{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xs\:hover\:bg-blueAqua:hover{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xs\:hover\:bg-blueAquaLight:hover{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xs\:focus\:bg-dark:focus{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xs\:focus\:bg-grey:focus{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xs\:focus\:bg-greyLight:focus{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xs\:focus\:bg-light:focus{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xs\:focus\:bg-red:focus{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xs\:focus\:bg-redLight:focus{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xs\:focus\:bg-orange:focus{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xs\:focus\:bg-orangeLight:focus{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xs\:focus\:bg-yellowOrange:focus{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xs\:focus\:bg-yellow:focus{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xs\:focus\:bg-yellowLight:focus{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xs\:focus\:bg-yellowGreen:focus{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xs\:focus\:bg-yellowGreenLight:focus{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xs\:focus\:bg-green:focus{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xs\:focus\:bg-greenLight:focus{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xs\:focus\:bg-blue:focus{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xs\:focus\:bg-blueLight:focus{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xs\:focus\:bg-blueAqua:focus{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xs\:focus\:bg-blueAquaLight:focus{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xs\:bg-none{
    background-image:none
  }

  .xs\:bg-gradient-to-t{
    background-image:-webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top, var(--gradient-color-stops))
  }

  .xs\:bg-gradient-to-tr{
    background-image:-webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top right, var(--gradient-color-stops))
  }

  .xs\:bg-gradient-to-r{
    background-image:-webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to right, var(--gradient-color-stops))
  }

  .xs\:bg-gradient-to-br{
    background-image:-webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .xs\:bg-gradient-to-b{
    background-image:-webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .xs\:bg-gradient-to-bl{
    background-image:-webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .xs\:bg-gradient-to-l{
    background-image:-webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to left, var(--gradient-color-stops))
  }

  .xs\:bg-gradient-to-tl{
    background-image:-webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top left, var(--gradient-color-stops))
  }

  .xs\:bg-opacity-0{
    --bg-opacity:0
  }

  .xs\:bg-opacity-25{
    --bg-opacity:0.25
  }

  .xs\:bg-opacity-50{
    --bg-opacity:0.5
  }

  .xs\:bg-opacity-75{
    --bg-opacity:0.75
  }

  .xs\:bg-opacity-100{
    --bg-opacity:1
  }

  .xs\:hover\:bg-opacity-0:hover{
    --bg-opacity:0
  }

  .xs\:hover\:bg-opacity-25:hover{
    --bg-opacity:0.25
  }

  .xs\:hover\:bg-opacity-50:hover{
    --bg-opacity:0.5
  }

  .xs\:hover\:bg-opacity-75:hover{
    --bg-opacity:0.75
  }

  .xs\:hover\:bg-opacity-100:hover{
    --bg-opacity:1
  }

  .xs\:focus\:bg-opacity-0:focus{
    --bg-opacity:0
  }

  .xs\:focus\:bg-opacity-25:focus{
    --bg-opacity:0.25
  }

  .xs\:focus\:bg-opacity-50:focus{
    --bg-opacity:0.5
  }

  .xs\:focus\:bg-opacity-75:focus{
    --bg-opacity:0.75
  }

  .xs\:focus\:bg-opacity-100:focus{
    --bg-opacity:1
  }

  .xs\:bg-bottom{
    background-position:bottom
  }

  .xs\:bg-center{
    background-position:center
  }

  .xs\:bg-left{
    background-position:left
  }

  .xs\:bg-left-bottom{
    background-position:left bottom
  }

  .xs\:bg-left-top{
    background-position:left top
  }

  .xs\:bg-right{
    background-position:right
  }

  .xs\:bg-right-bottom{
    background-position:right bottom
  }

  .xs\:bg-right-top{
    background-position:right top
  }

  .xs\:bg-top{
    background-position:top
  }

  .xs\:bg-repeat{
    background-repeat:repeat
  }

  .xs\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .xs\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .xs\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .xs\:bg-repeat-round{
    background-repeat:round
  }

  .xs\:bg-repeat-space{
    background-repeat:space
  }

  .xs\:bg-auto{
    background-size:auto
  }

  .xs\:bg-cover{
    background-size:cover
  }

  .xs\:bg-contain{
    background-size:contain
  }

  .xs\:block{
    display:block
  }

  .xs\:inline-block{
    display:inline-block
  }

  .xs\:inline{
    display:inline
  }

  .xs\:flex{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
  }

  .xs\:inline-flex{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex
  }

  .xs\:inline-grid{
    display:inline-grid
  }

  .xs\:hidden{
    display:none
  }

  .xs\:flex-row{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row
  }

  .xs\:flex-row-reverse{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:reverse;
        -ms-flex-direction:row-reverse;
            flex-direction:row-reverse
  }

  .xs\:flex-col{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column
  }

  .xs\:flex-col-reverse{
    -webkit-box-orient:vertical;
    -webkit-box-direction:reverse;
        -ms-flex-direction:column-reverse;
            flex-direction:column-reverse
  }

  .xs\:flex-wrap{
    -ms-flex-wrap:wrap;
        flex-wrap:wrap
  }

  .xs\:flex-wrap-reverse{
    -ms-flex-wrap:wrap-reverse;
        flex-wrap:wrap-reverse
  }

  .xs\:flex-no-wrap{
    -ms-flex-wrap:nowrap;
        flex-wrap:nowrap
  }

  .xs\:place-items-auto{
    place-items:auto
  }

  .xs\:place-items-start{
    place-items:start
  }

  .xs\:place-items-end{
    place-items:end
  }

  .xs\:place-items-center{
    place-items:center
  }

  .xs\:place-items-stretch{
    place-items:stretch
  }

  .xs\:items-start{
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start
  }

  .xs\:items-end{
    -webkit-box-align:end;
        -ms-flex-align:end;
            align-items:flex-end
  }

  .xs\:items-center{
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }

  .xs\:items-baseline{
    -webkit-box-align:baseline;
        -ms-flex-align:baseline;
            align-items:baseline
  }

  .xs\:items-stretch{
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch
  }

  .xs\:justify-items-auto{
    justify-items:auto
  }

  .xs\:justify-items-start{
    justify-items:start
  }

  .xs\:justify-items-end{
    justify-items:end
  }

  .xs\:justify-items-center{
    justify-items:center
  }

  .xs\:justify-items-stretch{
    justify-items:stretch
  }

  .xs\:justify-start{
    -webkit-box-pack:start;
        -ms-flex-pack:start;
            justify-content:flex-start
  }

  .xs\:justify-end{
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end
  }

  .xs\:justify-center{
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center
  }

  .xs\:justify-between{
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between
  }

  .xs\:justify-around{
    -ms-flex-pack:distribute;
        justify-content:space-around
  }

  .xs\:justify-evenly{
    -webkit-box-pack:space-evenly;
        -ms-flex-pack:space-evenly;
            justify-content:space-evenly
  }

  .xs\:justify-self-auto{
    justify-self:auto
  }

  .xs\:justify-self-start{
    justify-self:start
  }

  .xs\:justify-self-end{
    justify-self:end
  }

  .xs\:justify-self-center{
    justify-self:center
  }

  .xs\:justify-self-stretch{
    justify-self:stretch
  }

  .xs\:flex-1{
    -webkit-box-flex:1;
        -ms-flex:1 1 0%;
            flex:1 1 0%
  }

  .xs\:flex-auto{
    -webkit-box-flex:1;
        -ms-flex:1 1 auto;
            flex:1 1 auto
  }

  .xs\:flex-initial{
    -webkit-box-flex:0;
        -ms-flex:0 1 auto;
            flex:0 1 auto
  }

  .xs\:flex-none{
    -webkit-box-flex:0;
        -ms-flex:none;
            flex:none
  }

  .xs\:flex-grow-0{
    -webkit-box-flex:0;
        -ms-flex-positive:0;
            flex-grow:0
  }

  .xs\:flex-grow{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1
  }

  .xs\:flex-shrink-0{
    -ms-flex-negative:0;
        flex-shrink:0
  }

  .xs\:flex-shrink{
    -ms-flex-negative:1;
        flex-shrink:1
  }

  .xs\:h-0{
    height:0
  }

  .xs\:h-auto{
    height:auto
  }

  .xs\:h-1\/1{
    height:100%
  }

  .xs\:h-full{
    height:100%
  }

  .xs\:h-1\/2{
    height:50%
  }

  .xs\:h-1\/3{
    height:33.333333%
  }

  .xs\:h-2\/3{
    height:66.666667%
  }

  .xs\:h-1\/4{
    height:25%
  }

  .xs\:h-2\/4{
    height:50%
  }

  .xs\:h-3\/4{
    height:75%
  }

  .xs\:h-1\/5{
    height:20%
  }

  .xs\:h-2\/5{
    height:40%
  }

  .xs\:h-3\/5{
    height:60%
  }

  .xs\:h-4\/5{
    height:80%
  }

  .xs\:h-1\/6{
    height:16.666667%
  }

  .xs\:h-2\/6{
    height:33.333333%
  }

  .xs\:h-3\/6{
    height:50%
  }

  .xs\:h-4\/6{
    height:66.666667%
  }

  .xs\:h-5\/6{
    height:83.333333%
  }

  .xs\:h-1\/12{
    height:8.333333%
  }

  .xs\:h-2\/12{
    height:16.666667%
  }

  .xs\:h-3\/12{
    height:25%
  }

  .xs\:h-4\/12{
    height:33.333333%
  }

  .xs\:h-5\/12{
    height:41.666667%
  }

  .xs\:h-6\/12{
    height:50%
  }

  .xs\:h-7\/12{
    height:58.333333%
  }

  .xs\:h-8\/12{
    height:66.666667%
  }

  .xs\:h-9\/12{
    height:75%
  }

  .xs\:h-10\/12{
    height:83.333333%
  }

  .xs\:h-11\/12{
    height:91.666667%
  }

  .xs\:h-12\/12{
    height:100%
  }

  .xs\:h-screen{
    height:100vh
  }

  .xs\:text-t1{
    font-size:3em
  }

  .xs\:text-t2{
    font-size:2.25em
  }

  .xs\:text-t3{
    font-size:1.5em
  }

  .xs\:text-t4{
    font-size:1.2em
  }

  .xs\:text-body{
    font-size:1em
  }

  .xs\:text-s{
    font-size:.875em
  }

  .xs\:text-xs{
    font-size:.625em
  }

  .xs\:overflow-hidden{
    overflow:hidden
  }

  .xs\:overflow-x-hidden{
    overflow-x:hidden
  }

  .xs\:overflow-y-hidden{
    overflow-y:hidden
  }

  .xs\:text-left{
    text-align:left
  }

  .xs\:text-center{
    text-align:center
  }

  .xs\:text-right{
    text-align:right
  }

  .xs\:text-justify{
    text-align:justify
  }

  .xs\:text-dark{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xs\:text-grey{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xs\:text-greyLight{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xs\:text-light{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xs\:text-red{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xs\:text-redLight{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xs\:text-orange{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xs\:text-orangeLight{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xs\:text-yellowOrange{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xs\:text-yellow{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xs\:text-yellowLight{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xs\:text-yellowGreen{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xs\:text-yellowGreenLight{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xs\:text-green{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xs\:text-greenLight{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xs\:text-blue{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xs\:text-blueLight{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xs\:text-blueAqua{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xs\:text-blueAquaLight{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xs\:hover\:text-dark:hover{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xs\:hover\:text-grey:hover{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xs\:hover\:text-greyLight:hover{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xs\:hover\:text-light:hover{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xs\:hover\:text-red:hover{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xs\:hover\:text-redLight:hover{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xs\:hover\:text-orange:hover{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xs\:hover\:text-orangeLight:hover{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xs\:hover\:text-yellowOrange:hover{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xs\:hover\:text-yellow:hover{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xs\:hover\:text-yellowLight:hover{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xs\:hover\:text-yellowGreen:hover{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xs\:hover\:text-yellowGreenLight:hover{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xs\:hover\:text-green:hover{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xs\:hover\:text-greenLight:hover{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xs\:hover\:text-blue:hover{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xs\:hover\:text-blueLight:hover{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xs\:hover\:text-blueAqua:hover{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xs\:hover\:text-blueAquaLight:hover{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xs\:focus\:text-dark:focus{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xs\:focus\:text-grey:focus{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xs\:focus\:text-greyLight:focus{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xs\:focus\:text-light:focus{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xs\:focus\:text-red:focus{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xs\:focus\:text-redLight:focus{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xs\:focus\:text-orange:focus{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xs\:focus\:text-orangeLight:focus{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xs\:focus\:text-yellowOrange:focus{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xs\:focus\:text-yellow:focus{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xs\:focus\:text-yellowLight:focus{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xs\:focus\:text-yellowGreen:focus{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xs\:focus\:text-yellowGreenLight:focus{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xs\:focus\:text-green:focus{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xs\:focus\:text-greenLight:focus{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xs\:focus\:text-blue:focus{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xs\:focus\:text-blueLight:focus{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xs\:focus\:text-blueAqua:focus{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xs\:focus\:text-blueAquaLight:focus{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xs\:text-opacity-0{
    --text-opacity:0
  }

  .xs\:text-opacity-25{
    --text-opacity:0.25
  }

  .xs\:text-opacity-50{
    --text-opacity:0.5
  }

  .xs\:text-opacity-75{
    --text-opacity:0.75
  }

  .xs\:text-opacity-100{
    --text-opacity:1
  }

  .xs\:hover\:text-opacity-0:hover{
    --text-opacity:0
  }

  .xs\:hover\:text-opacity-25:hover{
    --text-opacity:0.25
  }

  .xs\:hover\:text-opacity-50:hover{
    --text-opacity:0.5
  }

  .xs\:hover\:text-opacity-75:hover{
    --text-opacity:0.75
  }

  .xs\:hover\:text-opacity-100:hover{
    --text-opacity:1
  }

  .xs\:focus\:text-opacity-0:focus{
    --text-opacity:0
  }

  .xs\:focus\:text-opacity-25:focus{
    --text-opacity:0.25
  }

  .xs\:focus\:text-opacity-50:focus{
    --text-opacity:0.5
  }

  .xs\:focus\:text-opacity-75:focus{
    --text-opacity:0.75
  }

  .xs\:focus\:text-opacity-100:focus{
    --text-opacity:1
  }

  .xs\:select-text{
    -webkit-user-select:text;
       -moz-user-select:text;
        -ms-user-select:text;
            user-select:text
  }

  .xs\:align-text-top{
    vertical-align:text-top
  }

  .xs\:align-text-bottom{
    vertical-align:text-bottom
  }

  .xs\:w-0{
    width:0
  }

  .xs\:w-auto{
    width:auto
  }

  .xs\:w-1\/1{
    width:100%
  }

  .xs\:w-full{
    width:100%
  }

  .xs\:w-1\/2{
    width:50%
  }

  .xs\:w-1\/3{
    width:33.333333%
  }

  .xs\:w-2\/3{
    width:66.666667%
  }

  .xs\:w-1\/4{
    width:25%
  }

  .xs\:w-2\/4{
    width:50%
  }

  .xs\:w-3\/4{
    width:75%
  }

  .xs\:w-1\/5{
    width:20%
  }

  .xs\:w-2\/5{
    width:40%
  }

  .xs\:w-3\/5{
    width:60%
  }

  .xs\:w-4\/5{
    width:80%
  }

  .xs\:w-1\/6{
    width:16.666667%
  }

  .xs\:w-2\/6{
    width:33.333333%
  }

  .xs\:w-3\/6{
    width:50%
  }

  .xs\:w-4\/6{
    width:66.666667%
  }

  .xs\:w-5\/6{
    width:83.333333%
  }

  .xs\:w-1\/12{
    width:8.333333%
  }

  .xs\:w-2\/12{
    width:16.666667%
  }

  .xs\:w-3\/12{
    width:25%
  }

  .xs\:w-4\/12{
    width:33.333333%
  }

  .xs\:w-5\/12{
    width:41.666667%
  }

  .xs\:w-6\/12{
    width:50%
  }

  .xs\:w-7\/12{
    width:58.333333%
  }

  .xs\:w-8\/12{
    width:66.666667%
  }

  .xs\:w-9\/12{
    width:75%
  }

  .xs\:w-10\/12{
    width:83.333333%
  }

  .xs\:w-11\/12{
    width:91.666667%
  }

  .xs\:w-12\/12{
    width:100%
  }

  .xs\:w-screen{
    width:100vw
  }
}

@media (min-width: 768px){

  .sm\:bg-fixed{
    background-attachment:fixed
  }

  .sm\:bg-local{
    background-attachment:local
  }

  .sm\:bg-scroll{
    background-attachment:scroll
  }

  .sm\:bg-clip-border{
    background-clip:border-box
  }

  .sm\:bg-clip-padding{
    background-clip:padding-box
  }

  .sm\:bg-clip-content{
    background-clip:content-box
  }

  .sm\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .sm\:bg-dark{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .sm\:bg-grey{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .sm\:bg-greyLight{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .sm\:bg-light{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .sm\:bg-red{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .sm\:bg-redLight{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .sm\:bg-orange{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .sm\:bg-orangeLight{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .sm\:bg-yellowOrange{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .sm\:bg-yellow{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .sm\:bg-yellowLight{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .sm\:bg-yellowGreen{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .sm\:bg-yellowGreenLight{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .sm\:bg-green{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .sm\:bg-greenLight{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .sm\:bg-blue{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .sm\:bg-blueLight{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .sm\:bg-blueAqua{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .sm\:bg-blueAquaLight{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .sm\:hover\:bg-dark:hover{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .sm\:hover\:bg-grey:hover{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .sm\:hover\:bg-greyLight:hover{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .sm\:hover\:bg-light:hover{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .sm\:hover\:bg-red:hover{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .sm\:hover\:bg-redLight:hover{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .sm\:hover\:bg-orange:hover{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .sm\:hover\:bg-orangeLight:hover{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellowOrange:hover{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellow:hover{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellowLight:hover{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellowGreen:hover{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .sm\:hover\:bg-yellowGreenLight:hover{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .sm\:hover\:bg-green:hover{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .sm\:hover\:bg-greenLight:hover{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .sm\:hover\:bg-blue:hover{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .sm\:hover\:bg-blueLight:hover{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .sm\:hover\:bg-blueAqua:hover{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .sm\:hover\:bg-blueAquaLight:hover{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .sm\:focus\:bg-dark:focus{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .sm\:focus\:bg-grey:focus{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .sm\:focus\:bg-greyLight:focus{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .sm\:focus\:bg-light:focus{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .sm\:focus\:bg-red:focus{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .sm\:focus\:bg-redLight:focus{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .sm\:focus\:bg-orange:focus{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .sm\:focus\:bg-orangeLight:focus{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellowOrange:focus{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellow:focus{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellowLight:focus{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellowGreen:focus{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .sm\:focus\:bg-yellowGreenLight:focus{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .sm\:focus\:bg-green:focus{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .sm\:focus\:bg-greenLight:focus{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .sm\:focus\:bg-blue:focus{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .sm\:focus\:bg-blueLight:focus{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .sm\:focus\:bg-blueAqua:focus{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .sm\:focus\:bg-blueAquaLight:focus{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .sm\:bg-none{
    background-image:none
  }

  .sm\:bg-gradient-to-t{
    background-image:-webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-tr{
    background-image:-webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-r{
    background-image:-webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-br{
    background-image:-webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-b{
    background-image:-webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-bl{
    background-image:-webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-l{
    background-image:-webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to left, var(--gradient-color-stops))
  }

  .sm\:bg-gradient-to-tl{
    background-image:-webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top left, var(--gradient-color-stops))
  }

  .sm\:bg-opacity-0{
    --bg-opacity:0
  }

  .sm\:bg-opacity-25{
    --bg-opacity:0.25
  }

  .sm\:bg-opacity-50{
    --bg-opacity:0.5
  }

  .sm\:bg-opacity-75{
    --bg-opacity:0.75
  }

  .sm\:bg-opacity-100{
    --bg-opacity:1
  }

  .sm\:hover\:bg-opacity-0:hover{
    --bg-opacity:0
  }

  .sm\:hover\:bg-opacity-25:hover{
    --bg-opacity:0.25
  }

  .sm\:hover\:bg-opacity-50:hover{
    --bg-opacity:0.5
  }

  .sm\:hover\:bg-opacity-75:hover{
    --bg-opacity:0.75
  }

  .sm\:hover\:bg-opacity-100:hover{
    --bg-opacity:1
  }

  .sm\:focus\:bg-opacity-0:focus{
    --bg-opacity:0
  }

  .sm\:focus\:bg-opacity-25:focus{
    --bg-opacity:0.25
  }

  .sm\:focus\:bg-opacity-50:focus{
    --bg-opacity:0.5
  }

  .sm\:focus\:bg-opacity-75:focus{
    --bg-opacity:0.75
  }

  .sm\:focus\:bg-opacity-100:focus{
    --bg-opacity:1
  }

  .sm\:bg-bottom{
    background-position:bottom
  }

  .sm\:bg-center{
    background-position:center
  }

  .sm\:bg-left{
    background-position:left
  }

  .sm\:bg-left-bottom{
    background-position:left bottom
  }

  .sm\:bg-left-top{
    background-position:left top
  }

  .sm\:bg-right{
    background-position:right
  }

  .sm\:bg-right-bottom{
    background-position:right bottom
  }

  .sm\:bg-right-top{
    background-position:right top
  }

  .sm\:bg-top{
    background-position:top
  }

  .sm\:bg-repeat{
    background-repeat:repeat
  }

  .sm\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .sm\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .sm\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .sm\:bg-repeat-round{
    background-repeat:round
  }

  .sm\:bg-repeat-space{
    background-repeat:space
  }

  .sm\:bg-auto{
    background-size:auto
  }

  .sm\:bg-cover{
    background-size:cover
  }

  .sm\:bg-contain{
    background-size:contain
  }

  .sm\:block{
    display:block
  }

  .sm\:inline-block{
    display:inline-block
  }

  .sm\:inline{
    display:inline
  }

  .sm\:flex{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
  }

  .sm\:inline-flex{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex
  }

  .sm\:inline-grid{
    display:inline-grid
  }

  .sm\:hidden{
    display:none
  }

  .sm\:flex-row{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row
  }

  .sm\:flex-row-reverse{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:reverse;
        -ms-flex-direction:row-reverse;
            flex-direction:row-reverse
  }

  .sm\:flex-col{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column
  }

  .sm\:flex-col-reverse{
    -webkit-box-orient:vertical;
    -webkit-box-direction:reverse;
        -ms-flex-direction:column-reverse;
            flex-direction:column-reverse
  }

  .sm\:flex-wrap{
    -ms-flex-wrap:wrap;
        flex-wrap:wrap
  }

  .sm\:flex-wrap-reverse{
    -ms-flex-wrap:wrap-reverse;
        flex-wrap:wrap-reverse
  }

  .sm\:flex-no-wrap{
    -ms-flex-wrap:nowrap;
        flex-wrap:nowrap
  }

  .sm\:place-items-auto{
    place-items:auto
  }

  .sm\:place-items-start{
    place-items:start
  }

  .sm\:place-items-end{
    place-items:end
  }

  .sm\:place-items-center{
    place-items:center
  }

  .sm\:place-items-stretch{
    place-items:stretch
  }

  .sm\:items-start{
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start
  }

  .sm\:items-end{
    -webkit-box-align:end;
        -ms-flex-align:end;
            align-items:flex-end
  }

  .sm\:items-center{
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }

  .sm\:items-baseline{
    -webkit-box-align:baseline;
        -ms-flex-align:baseline;
            align-items:baseline
  }

  .sm\:items-stretch{
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch
  }

  .sm\:justify-items-auto{
    justify-items:auto
  }

  .sm\:justify-items-start{
    justify-items:start
  }

  .sm\:justify-items-end{
    justify-items:end
  }

  .sm\:justify-items-center{
    justify-items:center
  }

  .sm\:justify-items-stretch{
    justify-items:stretch
  }

  .sm\:justify-start{
    -webkit-box-pack:start;
        -ms-flex-pack:start;
            justify-content:flex-start
  }

  .sm\:justify-end{
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end
  }

  .sm\:justify-center{
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center
  }

  .sm\:justify-between{
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between
  }

  .sm\:justify-around{
    -ms-flex-pack:distribute;
        justify-content:space-around
  }

  .sm\:justify-evenly{
    -webkit-box-pack:space-evenly;
        -ms-flex-pack:space-evenly;
            justify-content:space-evenly
  }

  .sm\:justify-self-auto{
    justify-self:auto
  }

  .sm\:justify-self-start{
    justify-self:start
  }

  .sm\:justify-self-end{
    justify-self:end
  }

  .sm\:justify-self-center{
    justify-self:center
  }

  .sm\:justify-self-stretch{
    justify-self:stretch
  }

  .sm\:flex-1{
    -webkit-box-flex:1;
        -ms-flex:1 1 0%;
            flex:1 1 0%
  }

  .sm\:flex-auto{
    -webkit-box-flex:1;
        -ms-flex:1 1 auto;
            flex:1 1 auto
  }

  .sm\:flex-initial{
    -webkit-box-flex:0;
        -ms-flex:0 1 auto;
            flex:0 1 auto
  }

  .sm\:flex-none{
    -webkit-box-flex:0;
        -ms-flex:none;
            flex:none
  }

  .sm\:flex-grow-0{
    -webkit-box-flex:0;
        -ms-flex-positive:0;
            flex-grow:0
  }

  .sm\:flex-grow{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1
  }

  .sm\:flex-shrink-0{
    -ms-flex-negative:0;
        flex-shrink:0
  }

  .sm\:flex-shrink{
    -ms-flex-negative:1;
        flex-shrink:1
  }

  .sm\:h-0{
    height:0
  }

  .sm\:h-auto{
    height:auto
  }

  .sm\:h-1\/1{
    height:100%
  }

  .sm\:h-full{
    height:100%
  }

  .sm\:h-1\/2{
    height:50%
  }

  .sm\:h-1\/3{
    height:33.333333%
  }

  .sm\:h-2\/3{
    height:66.666667%
  }

  .sm\:h-1\/4{
    height:25%
  }

  .sm\:h-2\/4{
    height:50%
  }

  .sm\:h-3\/4{
    height:75%
  }

  .sm\:h-1\/5{
    height:20%
  }

  .sm\:h-2\/5{
    height:40%
  }

  .sm\:h-3\/5{
    height:60%
  }

  .sm\:h-4\/5{
    height:80%
  }

  .sm\:h-1\/6{
    height:16.666667%
  }

  .sm\:h-2\/6{
    height:33.333333%
  }

  .sm\:h-3\/6{
    height:50%
  }

  .sm\:h-4\/6{
    height:66.666667%
  }

  .sm\:h-5\/6{
    height:83.333333%
  }

  .sm\:h-1\/12{
    height:8.333333%
  }

  .sm\:h-2\/12{
    height:16.666667%
  }

  .sm\:h-3\/12{
    height:25%
  }

  .sm\:h-4\/12{
    height:33.333333%
  }

  .sm\:h-5\/12{
    height:41.666667%
  }

  .sm\:h-6\/12{
    height:50%
  }

  .sm\:h-7\/12{
    height:58.333333%
  }

  .sm\:h-8\/12{
    height:66.666667%
  }

  .sm\:h-9\/12{
    height:75%
  }

  .sm\:h-10\/12{
    height:83.333333%
  }

  .sm\:h-11\/12{
    height:91.666667%
  }

  .sm\:h-12\/12{
    height:100%
  }

  .sm\:h-screen{
    height:100vh
  }

  .sm\:text-t1{
    font-size:3em
  }

  .sm\:text-t2{
    font-size:2.25em
  }

  .sm\:text-t3{
    font-size:1.5em
  }

  .sm\:text-t4{
    font-size:1.2em
  }

  .sm\:text-body{
    font-size:1em
  }

  .sm\:text-s{
    font-size:.875em
  }

  .sm\:text-xs{
    font-size:.625em
  }

  .sm\:overflow-hidden{
    overflow:hidden
  }

  .sm\:overflow-x-hidden{
    overflow-x:hidden
  }

  .sm\:overflow-y-hidden{
    overflow-y:hidden
  }

  .sm\:text-left{
    text-align:left
  }

  .sm\:text-center{
    text-align:center
  }

  .sm\:text-right{
    text-align:right
  }

  .sm\:text-justify{
    text-align:justify
  }

  .sm\:text-dark{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .sm\:text-grey{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .sm\:text-greyLight{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .sm\:text-light{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .sm\:text-red{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .sm\:text-redLight{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .sm\:text-orange{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .sm\:text-orangeLight{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .sm\:text-yellowOrange{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .sm\:text-yellow{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .sm\:text-yellowLight{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .sm\:text-yellowGreen{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .sm\:text-yellowGreenLight{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .sm\:text-green{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .sm\:text-greenLight{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .sm\:text-blue{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .sm\:text-blueLight{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .sm\:text-blueAqua{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .sm\:text-blueAquaLight{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .sm\:hover\:text-dark:hover{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .sm\:hover\:text-grey:hover{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .sm\:hover\:text-greyLight:hover{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .sm\:hover\:text-light:hover{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .sm\:hover\:text-red:hover{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .sm\:hover\:text-redLight:hover{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .sm\:hover\:text-orange:hover{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .sm\:hover\:text-orangeLight:hover{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .sm\:hover\:text-yellowOrange:hover{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .sm\:hover\:text-yellow:hover{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .sm\:hover\:text-yellowLight:hover{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .sm\:hover\:text-yellowGreen:hover{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .sm\:hover\:text-yellowGreenLight:hover{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .sm\:hover\:text-green:hover{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .sm\:hover\:text-greenLight:hover{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .sm\:hover\:text-blue:hover{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .sm\:hover\:text-blueLight:hover{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .sm\:hover\:text-blueAqua:hover{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .sm\:hover\:text-blueAquaLight:hover{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .sm\:focus\:text-dark:focus{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .sm\:focus\:text-grey:focus{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .sm\:focus\:text-greyLight:focus{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .sm\:focus\:text-light:focus{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .sm\:focus\:text-red:focus{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .sm\:focus\:text-redLight:focus{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .sm\:focus\:text-orange:focus{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .sm\:focus\:text-orangeLight:focus{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .sm\:focus\:text-yellowOrange:focus{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .sm\:focus\:text-yellow:focus{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .sm\:focus\:text-yellowLight:focus{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .sm\:focus\:text-yellowGreen:focus{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .sm\:focus\:text-yellowGreenLight:focus{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .sm\:focus\:text-green:focus{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .sm\:focus\:text-greenLight:focus{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .sm\:focus\:text-blue:focus{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .sm\:focus\:text-blueLight:focus{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .sm\:focus\:text-blueAqua:focus{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .sm\:focus\:text-blueAquaLight:focus{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .sm\:text-opacity-0{
    --text-opacity:0
  }

  .sm\:text-opacity-25{
    --text-opacity:0.25
  }

  .sm\:text-opacity-50{
    --text-opacity:0.5
  }

  .sm\:text-opacity-75{
    --text-opacity:0.75
  }

  .sm\:text-opacity-100{
    --text-opacity:1
  }

  .sm\:hover\:text-opacity-0:hover{
    --text-opacity:0
  }

  .sm\:hover\:text-opacity-25:hover{
    --text-opacity:0.25
  }

  .sm\:hover\:text-opacity-50:hover{
    --text-opacity:0.5
  }

  .sm\:hover\:text-opacity-75:hover{
    --text-opacity:0.75
  }

  .sm\:hover\:text-opacity-100:hover{
    --text-opacity:1
  }

  .sm\:focus\:text-opacity-0:focus{
    --text-opacity:0
  }

  .sm\:focus\:text-opacity-25:focus{
    --text-opacity:0.25
  }

  .sm\:focus\:text-opacity-50:focus{
    --text-opacity:0.5
  }

  .sm\:focus\:text-opacity-75:focus{
    --text-opacity:0.75
  }

  .sm\:focus\:text-opacity-100:focus{
    --text-opacity:1
  }

  .sm\:select-text{
    -webkit-user-select:text;
       -moz-user-select:text;
        -ms-user-select:text;
            user-select:text
  }

  .sm\:align-text-top{
    vertical-align:text-top
  }

  .sm\:align-text-bottom{
    vertical-align:text-bottom
  }

  .sm\:w-0{
    width:0
  }

  .sm\:w-auto{
    width:auto
  }

  .sm\:w-1\/1{
    width:100%
  }

  .sm\:w-full{
    width:100%
  }

  .sm\:w-1\/2{
    width:50%
  }

  .sm\:w-1\/3{
    width:33.333333%
  }

  .sm\:w-2\/3{
    width:66.666667%
  }

  .sm\:w-1\/4{
    width:25%
  }

  .sm\:w-2\/4{
    width:50%
  }

  .sm\:w-3\/4{
    width:75%
  }

  .sm\:w-1\/5{
    width:20%
  }

  .sm\:w-2\/5{
    width:40%
  }

  .sm\:w-3\/5{
    width:60%
  }

  .sm\:w-4\/5{
    width:80%
  }

  .sm\:w-1\/6{
    width:16.666667%
  }

  .sm\:w-2\/6{
    width:33.333333%
  }

  .sm\:w-3\/6{
    width:50%
  }

  .sm\:w-4\/6{
    width:66.666667%
  }

  .sm\:w-5\/6{
    width:83.333333%
  }

  .sm\:w-1\/12{
    width:8.333333%
  }

  .sm\:w-2\/12{
    width:16.666667%
  }

  .sm\:w-3\/12{
    width:25%
  }

  .sm\:w-4\/12{
    width:33.333333%
  }

  .sm\:w-5\/12{
    width:41.666667%
  }

  .sm\:w-6\/12{
    width:50%
  }

  .sm\:w-7\/12{
    width:58.333333%
  }

  .sm\:w-8\/12{
    width:66.666667%
  }

  .sm\:w-9\/12{
    width:75%
  }

  .sm\:w-10\/12{
    width:83.333333%
  }

  .sm\:w-11\/12{
    width:91.666667%
  }

  .sm\:w-12\/12{
    width:100%
  }

  .sm\:w-screen{
    width:100vw
  }
}

@media (min-width: 992px){

  .md\:bg-fixed{
    background-attachment:fixed
  }

  .md\:bg-local{
    background-attachment:local
  }

  .md\:bg-scroll{
    background-attachment:scroll
  }

  .md\:bg-clip-border{
    background-clip:border-box
  }

  .md\:bg-clip-padding{
    background-clip:padding-box
  }

  .md\:bg-clip-content{
    background-clip:content-box
  }

  .md\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .md\:bg-dark{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .md\:bg-grey{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .md\:bg-greyLight{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .md\:bg-light{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .md\:bg-red{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .md\:bg-redLight{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .md\:bg-orange{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .md\:bg-orangeLight{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .md\:bg-yellowOrange{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .md\:bg-yellow{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .md\:bg-yellowLight{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .md\:bg-yellowGreen{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .md\:bg-yellowGreenLight{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .md\:bg-green{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .md\:bg-greenLight{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .md\:bg-blue{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .md\:bg-blueLight{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .md\:bg-blueAqua{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .md\:bg-blueAquaLight{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .md\:hover\:bg-dark:hover{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .md\:hover\:bg-grey:hover{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .md\:hover\:bg-greyLight:hover{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .md\:hover\:bg-light:hover{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .md\:hover\:bg-red:hover{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .md\:hover\:bg-redLight:hover{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .md\:hover\:bg-orange:hover{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .md\:hover\:bg-orangeLight:hover{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .md\:hover\:bg-yellowOrange:hover{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .md\:hover\:bg-yellow:hover{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .md\:hover\:bg-yellowLight:hover{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .md\:hover\:bg-yellowGreen:hover{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .md\:hover\:bg-yellowGreenLight:hover{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .md\:hover\:bg-green:hover{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .md\:hover\:bg-greenLight:hover{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .md\:hover\:bg-blue:hover{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .md\:hover\:bg-blueLight:hover{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .md\:hover\:bg-blueAqua:hover{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .md\:hover\:bg-blueAquaLight:hover{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .md\:focus\:bg-dark:focus{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .md\:focus\:bg-grey:focus{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .md\:focus\:bg-greyLight:focus{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .md\:focus\:bg-light:focus{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .md\:focus\:bg-red:focus{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .md\:focus\:bg-redLight:focus{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .md\:focus\:bg-orange:focus{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .md\:focus\:bg-orangeLight:focus{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .md\:focus\:bg-yellowOrange:focus{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .md\:focus\:bg-yellow:focus{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .md\:focus\:bg-yellowLight:focus{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .md\:focus\:bg-yellowGreen:focus{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .md\:focus\:bg-yellowGreenLight:focus{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .md\:focus\:bg-green:focus{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .md\:focus\:bg-greenLight:focus{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .md\:focus\:bg-blue:focus{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .md\:focus\:bg-blueLight:focus{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .md\:focus\:bg-blueAqua:focus{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .md\:focus\:bg-blueAquaLight:focus{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .md\:bg-none{
    background-image:none
  }

  .md\:bg-gradient-to-t{
    background-image:-webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-tr{
    background-image:-webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-r{
    background-image:-webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-br{
    background-image:-webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-b{
    background-image:-webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-bl{
    background-image:-webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-l{
    background-image:-webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to left, var(--gradient-color-stops))
  }

  .md\:bg-gradient-to-tl{
    background-image:-webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top left, var(--gradient-color-stops))
  }

  .md\:bg-opacity-0{
    --bg-opacity:0
  }

  .md\:bg-opacity-25{
    --bg-opacity:0.25
  }

  .md\:bg-opacity-50{
    --bg-opacity:0.5
  }

  .md\:bg-opacity-75{
    --bg-opacity:0.75
  }

  .md\:bg-opacity-100{
    --bg-opacity:1
  }

  .md\:hover\:bg-opacity-0:hover{
    --bg-opacity:0
  }

  .md\:hover\:bg-opacity-25:hover{
    --bg-opacity:0.25
  }

  .md\:hover\:bg-opacity-50:hover{
    --bg-opacity:0.5
  }

  .md\:hover\:bg-opacity-75:hover{
    --bg-opacity:0.75
  }

  .md\:hover\:bg-opacity-100:hover{
    --bg-opacity:1
  }

  .md\:focus\:bg-opacity-0:focus{
    --bg-opacity:0
  }

  .md\:focus\:bg-opacity-25:focus{
    --bg-opacity:0.25
  }

  .md\:focus\:bg-opacity-50:focus{
    --bg-opacity:0.5
  }

  .md\:focus\:bg-opacity-75:focus{
    --bg-opacity:0.75
  }

  .md\:focus\:bg-opacity-100:focus{
    --bg-opacity:1
  }

  .md\:bg-bottom{
    background-position:bottom
  }

  .md\:bg-center{
    background-position:center
  }

  .md\:bg-left{
    background-position:left
  }

  .md\:bg-left-bottom{
    background-position:left bottom
  }

  .md\:bg-left-top{
    background-position:left top
  }

  .md\:bg-right{
    background-position:right
  }

  .md\:bg-right-bottom{
    background-position:right bottom
  }

  .md\:bg-right-top{
    background-position:right top
  }

  .md\:bg-top{
    background-position:top
  }

  .md\:bg-repeat{
    background-repeat:repeat
  }

  .md\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .md\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .md\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .md\:bg-repeat-round{
    background-repeat:round
  }

  .md\:bg-repeat-space{
    background-repeat:space
  }

  .md\:bg-auto{
    background-size:auto
  }

  .md\:bg-cover{
    background-size:cover
  }

  .md\:bg-contain{
    background-size:contain
  }

  .md\:block{
    display:block
  }

  .md\:inline-block{
    display:inline-block
  }

  .md\:inline{
    display:inline
  }

  .md\:flex{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
  }

  .md\:inline-flex{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex
  }

  .md\:inline-grid{
    display:inline-grid
  }

  .md\:hidden{
    display:none
  }

  .md\:flex-row{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row
  }

  .md\:flex-row-reverse{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:reverse;
        -ms-flex-direction:row-reverse;
            flex-direction:row-reverse
  }

  .md\:flex-col{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column
  }

  .md\:flex-col-reverse{
    -webkit-box-orient:vertical;
    -webkit-box-direction:reverse;
        -ms-flex-direction:column-reverse;
            flex-direction:column-reverse
  }

  .md\:flex-wrap{
    -ms-flex-wrap:wrap;
        flex-wrap:wrap
  }

  .md\:flex-wrap-reverse{
    -ms-flex-wrap:wrap-reverse;
        flex-wrap:wrap-reverse
  }

  .md\:flex-no-wrap{
    -ms-flex-wrap:nowrap;
        flex-wrap:nowrap
  }

  .md\:place-items-auto{
    place-items:auto
  }

  .md\:place-items-start{
    place-items:start
  }

  .md\:place-items-end{
    place-items:end
  }

  .md\:place-items-center{
    place-items:center
  }

  .md\:place-items-stretch{
    place-items:stretch
  }

  .md\:items-start{
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start
  }

  .md\:items-end{
    -webkit-box-align:end;
        -ms-flex-align:end;
            align-items:flex-end
  }

  .md\:items-center{
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }

  .md\:items-baseline{
    -webkit-box-align:baseline;
        -ms-flex-align:baseline;
            align-items:baseline
  }

  .md\:items-stretch{
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch
  }

  .md\:justify-items-auto{
    justify-items:auto
  }

  .md\:justify-items-start{
    justify-items:start
  }

  .md\:justify-items-end{
    justify-items:end
  }

  .md\:justify-items-center{
    justify-items:center
  }

  .md\:justify-items-stretch{
    justify-items:stretch
  }

  .md\:justify-start{
    -webkit-box-pack:start;
        -ms-flex-pack:start;
            justify-content:flex-start
  }

  .md\:justify-end{
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end
  }

  .md\:justify-center{
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center
  }

  .md\:justify-between{
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between
  }

  .md\:justify-around{
    -ms-flex-pack:distribute;
        justify-content:space-around
  }

  .md\:justify-evenly{
    -webkit-box-pack:space-evenly;
        -ms-flex-pack:space-evenly;
            justify-content:space-evenly
  }

  .md\:justify-self-auto{
    justify-self:auto
  }

  .md\:justify-self-start{
    justify-self:start
  }

  .md\:justify-self-end{
    justify-self:end
  }

  .md\:justify-self-center{
    justify-self:center
  }

  .md\:justify-self-stretch{
    justify-self:stretch
  }

  .md\:flex-1{
    -webkit-box-flex:1;
        -ms-flex:1 1 0%;
            flex:1 1 0%
  }

  .md\:flex-auto{
    -webkit-box-flex:1;
        -ms-flex:1 1 auto;
            flex:1 1 auto
  }

  .md\:flex-initial{
    -webkit-box-flex:0;
        -ms-flex:0 1 auto;
            flex:0 1 auto
  }

  .md\:flex-none{
    -webkit-box-flex:0;
        -ms-flex:none;
            flex:none
  }

  .md\:flex-grow-0{
    -webkit-box-flex:0;
        -ms-flex-positive:0;
            flex-grow:0
  }

  .md\:flex-grow{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1
  }

  .md\:flex-shrink-0{
    -ms-flex-negative:0;
        flex-shrink:0
  }

  .md\:flex-shrink{
    -ms-flex-negative:1;
        flex-shrink:1
  }

  .md\:h-0{
    height:0
  }

  .md\:h-auto{
    height:auto
  }

  .md\:h-1\/1{
    height:100%
  }

  .md\:h-full{
    height:100%
  }

  .md\:h-1\/2{
    height:50%
  }

  .md\:h-1\/3{
    height:33.333333%
  }

  .md\:h-2\/3{
    height:66.666667%
  }

  .md\:h-1\/4{
    height:25%
  }

  .md\:h-2\/4{
    height:50%
  }

  .md\:h-3\/4{
    height:75%
  }

  .md\:h-1\/5{
    height:20%
  }

  .md\:h-2\/5{
    height:40%
  }

  .md\:h-3\/5{
    height:60%
  }

  .md\:h-4\/5{
    height:80%
  }

  .md\:h-1\/6{
    height:16.666667%
  }

  .md\:h-2\/6{
    height:33.333333%
  }

  .md\:h-3\/6{
    height:50%
  }

  .md\:h-4\/6{
    height:66.666667%
  }

  .md\:h-5\/6{
    height:83.333333%
  }

  .md\:h-1\/12{
    height:8.333333%
  }

  .md\:h-2\/12{
    height:16.666667%
  }

  .md\:h-3\/12{
    height:25%
  }

  .md\:h-4\/12{
    height:33.333333%
  }

  .md\:h-5\/12{
    height:41.666667%
  }

  .md\:h-6\/12{
    height:50%
  }

  .md\:h-7\/12{
    height:58.333333%
  }

  .md\:h-8\/12{
    height:66.666667%
  }

  .md\:h-9\/12{
    height:75%
  }

  .md\:h-10\/12{
    height:83.333333%
  }

  .md\:h-11\/12{
    height:91.666667%
  }

  .md\:h-12\/12{
    height:100%
  }

  .md\:h-screen{
    height:100vh
  }

  .md\:text-t1{
    font-size:3em
  }

  .md\:text-t2{
    font-size:2.25em
  }

  .md\:text-t3{
    font-size:1.5em
  }

  .md\:text-t4{
    font-size:1.2em
  }

  .md\:text-body{
    font-size:1em
  }

  .md\:text-s{
    font-size:.875em
  }

  .md\:text-xs{
    font-size:.625em
  }

  .md\:mt-0{
    margin-top:0
  }

  .md\:mt-2{
    margin-top:0.5rem
  }

  .md\:overflow-hidden{
    overflow:hidden
  }

  .md\:overflow-x-hidden{
    overflow-x:hidden
  }

  .md\:overflow-y-hidden{
    overflow-y:hidden
  }

  .md\:p-0{
    padding:0
  }

  .md\:px-0{
    padding-left:0;
    padding-right:0
  }

  .md\:py-4{
    padding-top:1rem;
    padding-bottom:1rem
  }

  .md\:pl-4{
    padding-left:1rem
  }

  .md\:pl-8{
    padding-left:2rem
  }

  .md\:text-left{
    text-align:left
  }

  .md\:text-center{
    text-align:center
  }

  .md\:text-right{
    text-align:right
  }

  .md\:text-justify{
    text-align:justify
  }

  .md\:text-dark{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .md\:text-grey{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .md\:text-greyLight{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .md\:text-light{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .md\:text-red{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .md\:text-redLight{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .md\:text-orange{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .md\:text-orangeLight{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .md\:text-yellowOrange{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .md\:text-yellow{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .md\:text-yellowLight{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .md\:text-yellowGreen{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .md\:text-yellowGreenLight{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .md\:text-green{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .md\:text-greenLight{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .md\:text-blue{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .md\:text-blueLight{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .md\:text-blueAqua{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .md\:text-blueAquaLight{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .md\:hover\:text-dark:hover{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .md\:hover\:text-grey:hover{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .md\:hover\:text-greyLight:hover{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .md\:hover\:text-light:hover{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .md\:hover\:text-red:hover{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .md\:hover\:text-redLight:hover{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .md\:hover\:text-orange:hover{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .md\:hover\:text-orangeLight:hover{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .md\:hover\:text-yellowOrange:hover{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .md\:hover\:text-yellow:hover{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .md\:hover\:text-yellowLight:hover{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .md\:hover\:text-yellowGreen:hover{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .md\:hover\:text-yellowGreenLight:hover{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .md\:hover\:text-green:hover{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .md\:hover\:text-greenLight:hover{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .md\:hover\:text-blue:hover{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .md\:hover\:text-blueLight:hover{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .md\:hover\:text-blueAqua:hover{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .md\:hover\:text-blueAquaLight:hover{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .md\:focus\:text-dark:focus{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .md\:focus\:text-grey:focus{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .md\:focus\:text-greyLight:focus{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .md\:focus\:text-light:focus{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .md\:focus\:text-red:focus{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .md\:focus\:text-redLight:focus{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .md\:focus\:text-orange:focus{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .md\:focus\:text-orangeLight:focus{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .md\:focus\:text-yellowOrange:focus{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .md\:focus\:text-yellow:focus{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .md\:focus\:text-yellowLight:focus{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .md\:focus\:text-yellowGreen:focus{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .md\:focus\:text-yellowGreenLight:focus{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .md\:focus\:text-green:focus{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .md\:focus\:text-greenLight:focus{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .md\:focus\:text-blue:focus{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .md\:focus\:text-blueLight:focus{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .md\:focus\:text-blueAqua:focus{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .md\:focus\:text-blueAquaLight:focus{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .md\:text-opacity-0{
    --text-opacity:0
  }

  .md\:text-opacity-25{
    --text-opacity:0.25
  }

  .md\:text-opacity-50{
    --text-opacity:0.5
  }

  .md\:text-opacity-75{
    --text-opacity:0.75
  }

  .md\:text-opacity-100{
    --text-opacity:1
  }

  .md\:hover\:text-opacity-0:hover{
    --text-opacity:0
  }

  .md\:hover\:text-opacity-25:hover{
    --text-opacity:0.25
  }

  .md\:hover\:text-opacity-50:hover{
    --text-opacity:0.5
  }

  .md\:hover\:text-opacity-75:hover{
    --text-opacity:0.75
  }

  .md\:hover\:text-opacity-100:hover{
    --text-opacity:1
  }

  .md\:focus\:text-opacity-0:focus{
    --text-opacity:0
  }

  .md\:focus\:text-opacity-25:focus{
    --text-opacity:0.25
  }

  .md\:focus\:text-opacity-50:focus{
    --text-opacity:0.5
  }

  .md\:focus\:text-opacity-75:focus{
    --text-opacity:0.75
  }

  .md\:focus\:text-opacity-100:focus{
    --text-opacity:1
  }

  .md\:select-text{
    -webkit-user-select:text;
       -moz-user-select:text;
        -ms-user-select:text;
            user-select:text
  }

  .md\:align-text-top{
    vertical-align:text-top
  }

  .md\:align-text-bottom{
    vertical-align:text-bottom
  }

  .md\:w-0{
    width:0
  }

  .md\:w-auto{
    width:auto
  }

  .md\:w-1\/1{
    width:100%
  }

  .md\:w-full{
    width:100%
  }

  .md\:w-1\/2{
    width:50%
  }

  .md\:w-1\/3{
    width:33.333333%
  }

  .md\:w-2\/3{
    width:66.666667%
  }

  .md\:w-1\/4{
    width:25%
  }

  .md\:w-2\/4{
    width:50%
  }

  .md\:w-3\/4{
    width:75%
  }

  .md\:w-1\/5{
    width:20%
  }

  .md\:w-2\/5{
    width:40%
  }

  .md\:w-3\/5{
    width:60%
  }

  .md\:w-4\/5{
    width:80%
  }

  .md\:w-1\/6{
    width:16.666667%
  }

  .md\:w-2\/6{
    width:33.333333%
  }

  .md\:w-3\/6{
    width:50%
  }

  .md\:w-4\/6{
    width:66.666667%
  }

  .md\:w-5\/6{
    width:83.333333%
  }

  .md\:w-1\/12{
    width:8.333333%
  }

  .md\:w-2\/12{
    width:16.666667%
  }

  .md\:w-3\/12{
    width:25%
  }

  .md\:w-4\/12{
    width:33.333333%
  }

  .md\:w-5\/12{
    width:41.666667%
  }

  .md\:w-6\/12{
    width:50%
  }

  .md\:w-7\/12{
    width:58.333333%
  }

  .md\:w-8\/12{
    width:66.666667%
  }

  .md\:w-9\/12{
    width:75%
  }

  .md\:w-10\/12{
    width:83.333333%
  }

  .md\:w-11\/12{
    width:91.666667%
  }

  .md\:w-12\/12{
    width:100%
  }

  .md\:w-screen{
    width:100vw
  }
}

@media (min-width: 1280px){

  .lg\:bg-fixed{
    background-attachment:fixed
  }

  .lg\:bg-local{
    background-attachment:local
  }

  .lg\:bg-scroll{
    background-attachment:scroll
  }

  .lg\:bg-clip-border{
    background-clip:border-box
  }

  .lg\:bg-clip-padding{
    background-clip:padding-box
  }

  .lg\:bg-clip-content{
    background-clip:content-box
  }

  .lg\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .lg\:bg-dark{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .lg\:bg-grey{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .lg\:bg-greyLight{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .lg\:bg-light{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .lg\:bg-red{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .lg\:bg-redLight{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .lg\:bg-orange{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .lg\:bg-orangeLight{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .lg\:bg-yellowOrange{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .lg\:bg-yellow{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .lg\:bg-yellowLight{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .lg\:bg-yellowGreen{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .lg\:bg-yellowGreenLight{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .lg\:bg-green{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .lg\:bg-greenLight{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .lg\:bg-blue{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .lg\:bg-blueLight{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .lg\:bg-blueAqua{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .lg\:bg-blueAquaLight{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .lg\:hover\:bg-dark:hover{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .lg\:hover\:bg-grey:hover{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .lg\:hover\:bg-greyLight:hover{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .lg\:hover\:bg-light:hover{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .lg\:hover\:bg-red:hover{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .lg\:hover\:bg-redLight:hover{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .lg\:hover\:bg-orange:hover{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .lg\:hover\:bg-orangeLight:hover{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellowOrange:hover{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellow:hover{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellowLight:hover{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellowGreen:hover{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .lg\:hover\:bg-yellowGreenLight:hover{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .lg\:hover\:bg-green:hover{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .lg\:hover\:bg-greenLight:hover{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .lg\:hover\:bg-blue:hover{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .lg\:hover\:bg-blueLight:hover{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .lg\:hover\:bg-blueAqua:hover{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .lg\:hover\:bg-blueAquaLight:hover{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .lg\:focus\:bg-dark:focus{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .lg\:focus\:bg-grey:focus{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .lg\:focus\:bg-greyLight:focus{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .lg\:focus\:bg-light:focus{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .lg\:focus\:bg-red:focus{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .lg\:focus\:bg-redLight:focus{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .lg\:focus\:bg-orange:focus{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .lg\:focus\:bg-orangeLight:focus{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellowOrange:focus{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellow:focus{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellowLight:focus{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellowGreen:focus{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .lg\:focus\:bg-yellowGreenLight:focus{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .lg\:focus\:bg-green:focus{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .lg\:focus\:bg-greenLight:focus{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .lg\:focus\:bg-blue:focus{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .lg\:focus\:bg-blueLight:focus{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .lg\:focus\:bg-blueAqua:focus{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .lg\:focus\:bg-blueAquaLight:focus{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .lg\:bg-none{
    background-image:none
  }

  .lg\:bg-gradient-to-t{
    background-image:-webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-tr{
    background-image:-webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-r{
    background-image:-webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-br{
    background-image:-webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-b{
    background-image:-webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-bl{
    background-image:-webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-l{
    background-image:-webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to left, var(--gradient-color-stops))
  }

  .lg\:bg-gradient-to-tl{
    background-image:-webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top left, var(--gradient-color-stops))
  }

  .lg\:bg-opacity-0{
    --bg-opacity:0
  }

  .lg\:bg-opacity-25{
    --bg-opacity:0.25
  }

  .lg\:bg-opacity-50{
    --bg-opacity:0.5
  }

  .lg\:bg-opacity-75{
    --bg-opacity:0.75
  }

  .lg\:bg-opacity-100{
    --bg-opacity:1
  }

  .lg\:hover\:bg-opacity-0:hover{
    --bg-opacity:0
  }

  .lg\:hover\:bg-opacity-25:hover{
    --bg-opacity:0.25
  }

  .lg\:hover\:bg-opacity-50:hover{
    --bg-opacity:0.5
  }

  .lg\:hover\:bg-opacity-75:hover{
    --bg-opacity:0.75
  }

  .lg\:hover\:bg-opacity-100:hover{
    --bg-opacity:1
  }

  .lg\:focus\:bg-opacity-0:focus{
    --bg-opacity:0
  }

  .lg\:focus\:bg-opacity-25:focus{
    --bg-opacity:0.25
  }

  .lg\:focus\:bg-opacity-50:focus{
    --bg-opacity:0.5
  }

  .lg\:focus\:bg-opacity-75:focus{
    --bg-opacity:0.75
  }

  .lg\:focus\:bg-opacity-100:focus{
    --bg-opacity:1
  }

  .lg\:bg-bottom{
    background-position:bottom
  }

  .lg\:bg-center{
    background-position:center
  }

  .lg\:bg-left{
    background-position:left
  }

  .lg\:bg-left-bottom{
    background-position:left bottom
  }

  .lg\:bg-left-top{
    background-position:left top
  }

  .lg\:bg-right{
    background-position:right
  }

  .lg\:bg-right-bottom{
    background-position:right bottom
  }

  .lg\:bg-right-top{
    background-position:right top
  }

  .lg\:bg-top{
    background-position:top
  }

  .lg\:bg-repeat{
    background-repeat:repeat
  }

  .lg\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .lg\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .lg\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .lg\:bg-repeat-round{
    background-repeat:round
  }

  .lg\:bg-repeat-space{
    background-repeat:space
  }

  .lg\:bg-auto{
    background-size:auto
  }

  .lg\:bg-cover{
    background-size:cover
  }

  .lg\:bg-contain{
    background-size:contain
  }

  .lg\:block{
    display:block
  }

  .lg\:inline-block{
    display:inline-block
  }

  .lg\:inline{
    display:inline
  }

  .lg\:flex{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
  }

  .lg\:inline-flex{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex
  }

  .lg\:inline-grid{
    display:inline-grid
  }

  .lg\:hidden{
    display:none
  }

  .lg\:flex-row{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row
  }

  .lg\:flex-row-reverse{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:reverse;
        -ms-flex-direction:row-reverse;
            flex-direction:row-reverse
  }

  .lg\:flex-col{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column
  }

  .lg\:flex-col-reverse{
    -webkit-box-orient:vertical;
    -webkit-box-direction:reverse;
        -ms-flex-direction:column-reverse;
            flex-direction:column-reverse
  }

  .lg\:flex-wrap{
    -ms-flex-wrap:wrap;
        flex-wrap:wrap
  }

  .lg\:flex-wrap-reverse{
    -ms-flex-wrap:wrap-reverse;
        flex-wrap:wrap-reverse
  }

  .lg\:flex-no-wrap{
    -ms-flex-wrap:nowrap;
        flex-wrap:nowrap
  }

  .lg\:place-items-auto{
    place-items:auto
  }

  .lg\:place-items-start{
    place-items:start
  }

  .lg\:place-items-end{
    place-items:end
  }

  .lg\:place-items-center{
    place-items:center
  }

  .lg\:place-items-stretch{
    place-items:stretch
  }

  .lg\:items-start{
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start
  }

  .lg\:items-end{
    -webkit-box-align:end;
        -ms-flex-align:end;
            align-items:flex-end
  }

  .lg\:items-center{
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }

  .lg\:items-baseline{
    -webkit-box-align:baseline;
        -ms-flex-align:baseline;
            align-items:baseline
  }

  .lg\:items-stretch{
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch
  }

  .lg\:justify-items-auto{
    justify-items:auto
  }

  .lg\:justify-items-start{
    justify-items:start
  }

  .lg\:justify-items-end{
    justify-items:end
  }

  .lg\:justify-items-center{
    justify-items:center
  }

  .lg\:justify-items-stretch{
    justify-items:stretch
  }

  .lg\:justify-start{
    -webkit-box-pack:start;
        -ms-flex-pack:start;
            justify-content:flex-start
  }

  .lg\:justify-end{
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end
  }

  .lg\:justify-center{
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center
  }

  .lg\:justify-between{
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between
  }

  .lg\:justify-around{
    -ms-flex-pack:distribute;
        justify-content:space-around
  }

  .lg\:justify-evenly{
    -webkit-box-pack:space-evenly;
        -ms-flex-pack:space-evenly;
            justify-content:space-evenly
  }

  .lg\:justify-self-auto{
    justify-self:auto
  }

  .lg\:justify-self-start{
    justify-self:start
  }

  .lg\:justify-self-end{
    justify-self:end
  }

  .lg\:justify-self-center{
    justify-self:center
  }

  .lg\:justify-self-stretch{
    justify-self:stretch
  }

  .lg\:flex-1{
    -webkit-box-flex:1;
        -ms-flex:1 1 0%;
            flex:1 1 0%
  }

  .lg\:flex-auto{
    -webkit-box-flex:1;
        -ms-flex:1 1 auto;
            flex:1 1 auto
  }

  .lg\:flex-initial{
    -webkit-box-flex:0;
        -ms-flex:0 1 auto;
            flex:0 1 auto
  }

  .lg\:flex-none{
    -webkit-box-flex:0;
        -ms-flex:none;
            flex:none
  }

  .lg\:flex-grow-0{
    -webkit-box-flex:0;
        -ms-flex-positive:0;
            flex-grow:0
  }

  .lg\:flex-grow{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1
  }

  .lg\:flex-shrink-0{
    -ms-flex-negative:0;
        flex-shrink:0
  }

  .lg\:flex-shrink{
    -ms-flex-negative:1;
        flex-shrink:1
  }

  .lg\:h-0{
    height:0
  }

  .lg\:h-auto{
    height:auto
  }

  .lg\:h-1\/1{
    height:100%
  }

  .lg\:h-full{
    height:100%
  }

  .lg\:h-1\/2{
    height:50%
  }

  .lg\:h-1\/3{
    height:33.333333%
  }

  .lg\:h-2\/3{
    height:66.666667%
  }

  .lg\:h-1\/4{
    height:25%
  }

  .lg\:h-2\/4{
    height:50%
  }

  .lg\:h-3\/4{
    height:75%
  }

  .lg\:h-1\/5{
    height:20%
  }

  .lg\:h-2\/5{
    height:40%
  }

  .lg\:h-3\/5{
    height:60%
  }

  .lg\:h-4\/5{
    height:80%
  }

  .lg\:h-1\/6{
    height:16.666667%
  }

  .lg\:h-2\/6{
    height:33.333333%
  }

  .lg\:h-3\/6{
    height:50%
  }

  .lg\:h-4\/6{
    height:66.666667%
  }

  .lg\:h-5\/6{
    height:83.333333%
  }

  .lg\:h-1\/12{
    height:8.333333%
  }

  .lg\:h-2\/12{
    height:16.666667%
  }

  .lg\:h-3\/12{
    height:25%
  }

  .lg\:h-4\/12{
    height:33.333333%
  }

  .lg\:h-5\/12{
    height:41.666667%
  }

  .lg\:h-6\/12{
    height:50%
  }

  .lg\:h-7\/12{
    height:58.333333%
  }

  .lg\:h-8\/12{
    height:66.666667%
  }

  .lg\:h-9\/12{
    height:75%
  }

  .lg\:h-10\/12{
    height:83.333333%
  }

  .lg\:h-11\/12{
    height:91.666667%
  }

  .lg\:h-12\/12{
    height:100%
  }

  .lg\:h-screen{
    height:100vh
  }

  .lg\:text-t1{
    font-size:3em
  }

  .lg\:text-t2{
    font-size:2.25em
  }

  .lg\:text-t3{
    font-size:1.5em
  }

  .lg\:text-t4{
    font-size:1.2em
  }

  .lg\:text-body{
    font-size:1em
  }

  .lg\:text-s{
    font-size:.875em
  }

  .lg\:text-xs{
    font-size:.625em
  }

  .lg\:overflow-hidden{
    overflow:hidden
  }

  .lg\:overflow-x-hidden{
    overflow-x:hidden
  }

  .lg\:overflow-y-hidden{
    overflow-y:hidden
  }

  .lg\:text-left{
    text-align:left
  }

  .lg\:text-center{
    text-align:center
  }

  .lg\:text-right{
    text-align:right
  }

  .lg\:text-justify{
    text-align:justify
  }

  .lg\:text-dark{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .lg\:text-grey{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .lg\:text-greyLight{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .lg\:text-light{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .lg\:text-red{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .lg\:text-redLight{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .lg\:text-orange{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .lg\:text-orangeLight{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .lg\:text-yellowOrange{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .lg\:text-yellow{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .lg\:text-yellowLight{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .lg\:text-yellowGreen{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .lg\:text-yellowGreenLight{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .lg\:text-green{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .lg\:text-greenLight{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .lg\:text-blue{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .lg\:text-blueLight{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .lg\:text-blueAqua{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .lg\:text-blueAquaLight{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .lg\:hover\:text-dark:hover{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .lg\:hover\:text-grey:hover{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .lg\:hover\:text-greyLight:hover{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .lg\:hover\:text-light:hover{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .lg\:hover\:text-red:hover{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .lg\:hover\:text-redLight:hover{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .lg\:hover\:text-orange:hover{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .lg\:hover\:text-orangeLight:hover{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .lg\:hover\:text-yellowOrange:hover{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .lg\:hover\:text-yellow:hover{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .lg\:hover\:text-yellowLight:hover{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .lg\:hover\:text-yellowGreen:hover{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .lg\:hover\:text-yellowGreenLight:hover{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .lg\:hover\:text-green:hover{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .lg\:hover\:text-greenLight:hover{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .lg\:hover\:text-blue:hover{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .lg\:hover\:text-blueLight:hover{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .lg\:hover\:text-blueAqua:hover{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .lg\:hover\:text-blueAquaLight:hover{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .lg\:focus\:text-dark:focus{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .lg\:focus\:text-grey:focus{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .lg\:focus\:text-greyLight:focus{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .lg\:focus\:text-light:focus{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .lg\:focus\:text-red:focus{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .lg\:focus\:text-redLight:focus{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .lg\:focus\:text-orange:focus{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .lg\:focus\:text-orangeLight:focus{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .lg\:focus\:text-yellowOrange:focus{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .lg\:focus\:text-yellow:focus{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .lg\:focus\:text-yellowLight:focus{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .lg\:focus\:text-yellowGreen:focus{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .lg\:focus\:text-yellowGreenLight:focus{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .lg\:focus\:text-green:focus{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .lg\:focus\:text-greenLight:focus{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .lg\:focus\:text-blue:focus{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .lg\:focus\:text-blueLight:focus{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .lg\:focus\:text-blueAqua:focus{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .lg\:focus\:text-blueAquaLight:focus{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .lg\:text-opacity-0{
    --text-opacity:0
  }

  .lg\:text-opacity-25{
    --text-opacity:0.25
  }

  .lg\:text-opacity-50{
    --text-opacity:0.5
  }

  .lg\:text-opacity-75{
    --text-opacity:0.75
  }

  .lg\:text-opacity-100{
    --text-opacity:1
  }

  .lg\:hover\:text-opacity-0:hover{
    --text-opacity:0
  }

  .lg\:hover\:text-opacity-25:hover{
    --text-opacity:0.25
  }

  .lg\:hover\:text-opacity-50:hover{
    --text-opacity:0.5
  }

  .lg\:hover\:text-opacity-75:hover{
    --text-opacity:0.75
  }

  .lg\:hover\:text-opacity-100:hover{
    --text-opacity:1
  }

  .lg\:focus\:text-opacity-0:focus{
    --text-opacity:0
  }

  .lg\:focus\:text-opacity-25:focus{
    --text-opacity:0.25
  }

  .lg\:focus\:text-opacity-50:focus{
    --text-opacity:0.5
  }

  .lg\:focus\:text-opacity-75:focus{
    --text-opacity:0.75
  }

  .lg\:focus\:text-opacity-100:focus{
    --text-opacity:1
  }

  .lg\:select-text{
    -webkit-user-select:text;
       -moz-user-select:text;
        -ms-user-select:text;
            user-select:text
  }

  .lg\:align-text-top{
    vertical-align:text-top
  }

  .lg\:align-text-bottom{
    vertical-align:text-bottom
  }

  .lg\:w-0{
    width:0
  }

  .lg\:w-auto{
    width:auto
  }

  .lg\:w-1\/1{
    width:100%
  }

  .lg\:w-full{
    width:100%
  }

  .lg\:w-1\/2{
    width:50%
  }

  .lg\:w-1\/3{
    width:33.333333%
  }

  .lg\:w-2\/3{
    width:66.666667%
  }

  .lg\:w-1\/4{
    width:25%
  }

  .lg\:w-2\/4{
    width:50%
  }

  .lg\:w-3\/4{
    width:75%
  }

  .lg\:w-1\/5{
    width:20%
  }

  .lg\:w-2\/5{
    width:40%
  }

  .lg\:w-3\/5{
    width:60%
  }

  .lg\:w-4\/5{
    width:80%
  }

  .lg\:w-1\/6{
    width:16.666667%
  }

  .lg\:w-2\/6{
    width:33.333333%
  }

  .lg\:w-3\/6{
    width:50%
  }

  .lg\:w-4\/6{
    width:66.666667%
  }

  .lg\:w-5\/6{
    width:83.333333%
  }

  .lg\:w-1\/12{
    width:8.333333%
  }

  .lg\:w-2\/12{
    width:16.666667%
  }

  .lg\:w-3\/12{
    width:25%
  }

  .lg\:w-4\/12{
    width:33.333333%
  }

  .lg\:w-5\/12{
    width:41.666667%
  }

  .lg\:w-6\/12{
    width:50%
  }

  .lg\:w-7\/12{
    width:58.333333%
  }

  .lg\:w-8\/12{
    width:66.666667%
  }

  .lg\:w-9\/12{
    width:75%
  }

  .lg\:w-10\/12{
    width:83.333333%
  }

  .lg\:w-11\/12{
    width:91.666667%
  }

  .lg\:w-12\/12{
    width:100%
  }

  .lg\:w-screen{
    width:100vw
  }
}

@media (min-width: 1440px){

  .xl\:bg-fixed{
    background-attachment:fixed
  }

  .xl\:bg-local{
    background-attachment:local
  }

  .xl\:bg-scroll{
    background-attachment:scroll
  }

  .xl\:bg-clip-border{
    background-clip:border-box
  }

  .xl\:bg-clip-padding{
    background-clip:padding-box
  }

  .xl\:bg-clip-content{
    background-clip:content-box
  }

  .xl\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .xl\:bg-dark{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xl\:bg-grey{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xl\:bg-greyLight{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xl\:bg-light{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xl\:bg-red{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xl\:bg-redLight{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xl\:bg-orange{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xl\:bg-orangeLight{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xl\:bg-yellowOrange{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xl\:bg-yellow{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xl\:bg-yellowLight{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xl\:bg-yellowGreen{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xl\:bg-yellowGreenLight{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xl\:bg-green{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xl\:bg-greenLight{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xl\:bg-blue{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xl\:bg-blueLight{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xl\:bg-blueAqua{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xl\:bg-blueAquaLight{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xl\:hover\:bg-dark:hover{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xl\:hover\:bg-grey:hover{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xl\:hover\:bg-greyLight:hover{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xl\:hover\:bg-light:hover{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xl\:hover\:bg-red:hover{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xl\:hover\:bg-redLight:hover{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xl\:hover\:bg-orange:hover{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xl\:hover\:bg-orangeLight:hover{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellowOrange:hover{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellow:hover{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellowLight:hover{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellowGreen:hover{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xl\:hover\:bg-yellowGreenLight:hover{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xl\:hover\:bg-green:hover{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xl\:hover\:bg-greenLight:hover{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xl\:hover\:bg-blue:hover{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xl\:hover\:bg-blueLight:hover{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xl\:hover\:bg-blueAqua:hover{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xl\:hover\:bg-blueAquaLight:hover{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xl\:focus\:bg-dark:focus{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xl\:focus\:bg-grey:focus{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xl\:focus\:bg-greyLight:focus{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xl\:focus\:bg-light:focus{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xl\:focus\:bg-red:focus{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xl\:focus\:bg-redLight:focus{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xl\:focus\:bg-orange:focus{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xl\:focus\:bg-orangeLight:focus{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellowOrange:focus{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellow:focus{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellowLight:focus{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellowGreen:focus{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xl\:focus\:bg-yellowGreenLight:focus{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xl\:focus\:bg-green:focus{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xl\:focus\:bg-greenLight:focus{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xl\:focus\:bg-blue:focus{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xl\:focus\:bg-blueLight:focus{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xl\:focus\:bg-blueAqua:focus{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xl\:focus\:bg-blueAquaLight:focus{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xl\:bg-none{
    background-image:none
  }

  .xl\:bg-gradient-to-t{
    background-image:-webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-tr{
    background-image:-webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-r{
    background-image:-webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-br{
    background-image:-webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-b{
    background-image:-webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-bl{
    background-image:-webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-l{
    background-image:-webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to left, var(--gradient-color-stops))
  }

  .xl\:bg-gradient-to-tl{
    background-image:-webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top left, var(--gradient-color-stops))
  }

  .xl\:bg-opacity-0{
    --bg-opacity:0
  }

  .xl\:bg-opacity-25{
    --bg-opacity:0.25
  }

  .xl\:bg-opacity-50{
    --bg-opacity:0.5
  }

  .xl\:bg-opacity-75{
    --bg-opacity:0.75
  }

  .xl\:bg-opacity-100{
    --bg-opacity:1
  }

  .xl\:hover\:bg-opacity-0:hover{
    --bg-opacity:0
  }

  .xl\:hover\:bg-opacity-25:hover{
    --bg-opacity:0.25
  }

  .xl\:hover\:bg-opacity-50:hover{
    --bg-opacity:0.5
  }

  .xl\:hover\:bg-opacity-75:hover{
    --bg-opacity:0.75
  }

  .xl\:hover\:bg-opacity-100:hover{
    --bg-opacity:1
  }

  .xl\:focus\:bg-opacity-0:focus{
    --bg-opacity:0
  }

  .xl\:focus\:bg-opacity-25:focus{
    --bg-opacity:0.25
  }

  .xl\:focus\:bg-opacity-50:focus{
    --bg-opacity:0.5
  }

  .xl\:focus\:bg-opacity-75:focus{
    --bg-opacity:0.75
  }

  .xl\:focus\:bg-opacity-100:focus{
    --bg-opacity:1
  }

  .xl\:bg-bottom{
    background-position:bottom
  }

  .xl\:bg-center{
    background-position:center
  }

  .xl\:bg-left{
    background-position:left
  }

  .xl\:bg-left-bottom{
    background-position:left bottom
  }

  .xl\:bg-left-top{
    background-position:left top
  }

  .xl\:bg-right{
    background-position:right
  }

  .xl\:bg-right-bottom{
    background-position:right bottom
  }

  .xl\:bg-right-top{
    background-position:right top
  }

  .xl\:bg-top{
    background-position:top
  }

  .xl\:bg-repeat{
    background-repeat:repeat
  }

  .xl\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .xl\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .xl\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .xl\:bg-repeat-round{
    background-repeat:round
  }

  .xl\:bg-repeat-space{
    background-repeat:space
  }

  .xl\:bg-auto{
    background-size:auto
  }

  .xl\:bg-cover{
    background-size:cover
  }

  .xl\:bg-contain{
    background-size:contain
  }

  .xl\:block{
    display:block
  }

  .xl\:inline-block{
    display:inline-block
  }

  .xl\:inline{
    display:inline
  }

  .xl\:flex{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
  }

  .xl\:inline-flex{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex
  }

  .xl\:inline-grid{
    display:inline-grid
  }

  .xl\:hidden{
    display:none
  }

  .xl\:flex-row{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row
  }

  .xl\:flex-row-reverse{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:reverse;
        -ms-flex-direction:row-reverse;
            flex-direction:row-reverse
  }

  .xl\:flex-col{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column
  }

  .xl\:flex-col-reverse{
    -webkit-box-orient:vertical;
    -webkit-box-direction:reverse;
        -ms-flex-direction:column-reverse;
            flex-direction:column-reverse
  }

  .xl\:flex-wrap{
    -ms-flex-wrap:wrap;
        flex-wrap:wrap
  }

  .xl\:flex-wrap-reverse{
    -ms-flex-wrap:wrap-reverse;
        flex-wrap:wrap-reverse
  }

  .xl\:flex-no-wrap{
    -ms-flex-wrap:nowrap;
        flex-wrap:nowrap
  }

  .xl\:place-items-auto{
    place-items:auto
  }

  .xl\:place-items-start{
    place-items:start
  }

  .xl\:place-items-end{
    place-items:end
  }

  .xl\:place-items-center{
    place-items:center
  }

  .xl\:place-items-stretch{
    place-items:stretch
  }

  .xl\:items-start{
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start
  }

  .xl\:items-end{
    -webkit-box-align:end;
        -ms-flex-align:end;
            align-items:flex-end
  }

  .xl\:items-center{
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }

  .xl\:items-baseline{
    -webkit-box-align:baseline;
        -ms-flex-align:baseline;
            align-items:baseline
  }

  .xl\:items-stretch{
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch
  }

  .xl\:justify-items-auto{
    justify-items:auto
  }

  .xl\:justify-items-start{
    justify-items:start
  }

  .xl\:justify-items-end{
    justify-items:end
  }

  .xl\:justify-items-center{
    justify-items:center
  }

  .xl\:justify-items-stretch{
    justify-items:stretch
  }

  .xl\:justify-start{
    -webkit-box-pack:start;
        -ms-flex-pack:start;
            justify-content:flex-start
  }

  .xl\:justify-end{
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end
  }

  .xl\:justify-center{
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center
  }

  .xl\:justify-between{
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between
  }

  .xl\:justify-around{
    -ms-flex-pack:distribute;
        justify-content:space-around
  }

  .xl\:justify-evenly{
    -webkit-box-pack:space-evenly;
        -ms-flex-pack:space-evenly;
            justify-content:space-evenly
  }

  .xl\:justify-self-auto{
    justify-self:auto
  }

  .xl\:justify-self-start{
    justify-self:start
  }

  .xl\:justify-self-end{
    justify-self:end
  }

  .xl\:justify-self-center{
    justify-self:center
  }

  .xl\:justify-self-stretch{
    justify-self:stretch
  }

  .xl\:flex-1{
    -webkit-box-flex:1;
        -ms-flex:1 1 0%;
            flex:1 1 0%
  }

  .xl\:flex-auto{
    -webkit-box-flex:1;
        -ms-flex:1 1 auto;
            flex:1 1 auto
  }

  .xl\:flex-initial{
    -webkit-box-flex:0;
        -ms-flex:0 1 auto;
            flex:0 1 auto
  }

  .xl\:flex-none{
    -webkit-box-flex:0;
        -ms-flex:none;
            flex:none
  }

  .xl\:flex-grow-0{
    -webkit-box-flex:0;
        -ms-flex-positive:0;
            flex-grow:0
  }

  .xl\:flex-grow{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1
  }

  .xl\:flex-shrink-0{
    -ms-flex-negative:0;
        flex-shrink:0
  }

  .xl\:flex-shrink{
    -ms-flex-negative:1;
        flex-shrink:1
  }

  .xl\:h-0{
    height:0
  }

  .xl\:h-auto{
    height:auto
  }

  .xl\:h-1\/1{
    height:100%
  }

  .xl\:h-full{
    height:100%
  }

  .xl\:h-1\/2{
    height:50%
  }

  .xl\:h-1\/3{
    height:33.333333%
  }

  .xl\:h-2\/3{
    height:66.666667%
  }

  .xl\:h-1\/4{
    height:25%
  }

  .xl\:h-2\/4{
    height:50%
  }

  .xl\:h-3\/4{
    height:75%
  }

  .xl\:h-1\/5{
    height:20%
  }

  .xl\:h-2\/5{
    height:40%
  }

  .xl\:h-3\/5{
    height:60%
  }

  .xl\:h-4\/5{
    height:80%
  }

  .xl\:h-1\/6{
    height:16.666667%
  }

  .xl\:h-2\/6{
    height:33.333333%
  }

  .xl\:h-3\/6{
    height:50%
  }

  .xl\:h-4\/6{
    height:66.666667%
  }

  .xl\:h-5\/6{
    height:83.333333%
  }

  .xl\:h-1\/12{
    height:8.333333%
  }

  .xl\:h-2\/12{
    height:16.666667%
  }

  .xl\:h-3\/12{
    height:25%
  }

  .xl\:h-4\/12{
    height:33.333333%
  }

  .xl\:h-5\/12{
    height:41.666667%
  }

  .xl\:h-6\/12{
    height:50%
  }

  .xl\:h-7\/12{
    height:58.333333%
  }

  .xl\:h-8\/12{
    height:66.666667%
  }

  .xl\:h-9\/12{
    height:75%
  }

  .xl\:h-10\/12{
    height:83.333333%
  }

  .xl\:h-11\/12{
    height:91.666667%
  }

  .xl\:h-12\/12{
    height:100%
  }

  .xl\:h-screen{
    height:100vh
  }

  .xl\:text-t1{
    font-size:3em
  }

  .xl\:text-t2{
    font-size:2.25em
  }

  .xl\:text-t3{
    font-size:1.5em
  }

  .xl\:text-t4{
    font-size:1.2em
  }

  .xl\:text-body{
    font-size:1em
  }

  .xl\:text-s{
    font-size:.875em
  }

  .xl\:text-xs{
    font-size:.625em
  }

  .xl\:overflow-hidden{
    overflow:hidden
  }

  .xl\:overflow-x-hidden{
    overflow-x:hidden
  }

  .xl\:overflow-y-hidden{
    overflow-y:hidden
  }

  .xl\:text-left{
    text-align:left
  }

  .xl\:text-center{
    text-align:center
  }

  .xl\:text-right{
    text-align:right
  }

  .xl\:text-justify{
    text-align:justify
  }

  .xl\:text-dark{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xl\:text-grey{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xl\:text-greyLight{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xl\:text-light{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xl\:text-red{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xl\:text-redLight{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xl\:text-orange{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xl\:text-orangeLight{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xl\:text-yellowOrange{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xl\:text-yellow{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xl\:text-yellowLight{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xl\:text-yellowGreen{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xl\:text-yellowGreenLight{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xl\:text-green{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xl\:text-greenLight{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xl\:text-blue{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xl\:text-blueLight{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xl\:text-blueAqua{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xl\:text-blueAquaLight{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xl\:hover\:text-dark:hover{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xl\:hover\:text-grey:hover{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xl\:hover\:text-greyLight:hover{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xl\:hover\:text-light:hover{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xl\:hover\:text-red:hover{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xl\:hover\:text-redLight:hover{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xl\:hover\:text-orange:hover{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xl\:hover\:text-orangeLight:hover{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xl\:hover\:text-yellowOrange:hover{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xl\:hover\:text-yellow:hover{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xl\:hover\:text-yellowLight:hover{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xl\:hover\:text-yellowGreen:hover{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xl\:hover\:text-yellowGreenLight:hover{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xl\:hover\:text-green:hover{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xl\:hover\:text-greenLight:hover{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xl\:hover\:text-blue:hover{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xl\:hover\:text-blueLight:hover{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xl\:hover\:text-blueAqua:hover{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xl\:hover\:text-blueAquaLight:hover{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xl\:focus\:text-dark:focus{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xl\:focus\:text-grey:focus{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xl\:focus\:text-greyLight:focus{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xl\:focus\:text-light:focus{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xl\:focus\:text-red:focus{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xl\:focus\:text-redLight:focus{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xl\:focus\:text-orange:focus{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xl\:focus\:text-orangeLight:focus{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xl\:focus\:text-yellowOrange:focus{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xl\:focus\:text-yellow:focus{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xl\:focus\:text-yellowLight:focus{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xl\:focus\:text-yellowGreen:focus{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xl\:focus\:text-yellowGreenLight:focus{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xl\:focus\:text-green:focus{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xl\:focus\:text-greenLight:focus{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xl\:focus\:text-blue:focus{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xl\:focus\:text-blueLight:focus{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xl\:focus\:text-blueAqua:focus{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xl\:focus\:text-blueAquaLight:focus{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xl\:text-opacity-0{
    --text-opacity:0
  }

  .xl\:text-opacity-25{
    --text-opacity:0.25
  }

  .xl\:text-opacity-50{
    --text-opacity:0.5
  }

  .xl\:text-opacity-75{
    --text-opacity:0.75
  }

  .xl\:text-opacity-100{
    --text-opacity:1
  }

  .xl\:hover\:text-opacity-0:hover{
    --text-opacity:0
  }

  .xl\:hover\:text-opacity-25:hover{
    --text-opacity:0.25
  }

  .xl\:hover\:text-opacity-50:hover{
    --text-opacity:0.5
  }

  .xl\:hover\:text-opacity-75:hover{
    --text-opacity:0.75
  }

  .xl\:hover\:text-opacity-100:hover{
    --text-opacity:1
  }

  .xl\:focus\:text-opacity-0:focus{
    --text-opacity:0
  }

  .xl\:focus\:text-opacity-25:focus{
    --text-opacity:0.25
  }

  .xl\:focus\:text-opacity-50:focus{
    --text-opacity:0.5
  }

  .xl\:focus\:text-opacity-75:focus{
    --text-opacity:0.75
  }

  .xl\:focus\:text-opacity-100:focus{
    --text-opacity:1
  }

  .xl\:select-text{
    -webkit-user-select:text;
       -moz-user-select:text;
        -ms-user-select:text;
            user-select:text
  }

  .xl\:align-text-top{
    vertical-align:text-top
  }

  .xl\:align-text-bottom{
    vertical-align:text-bottom
  }

  .xl\:w-0{
    width:0
  }

  .xl\:w-auto{
    width:auto
  }

  .xl\:w-1\/1{
    width:100%
  }

  .xl\:w-full{
    width:100%
  }

  .xl\:w-1\/2{
    width:50%
  }

  .xl\:w-1\/3{
    width:33.333333%
  }

  .xl\:w-2\/3{
    width:66.666667%
  }

  .xl\:w-1\/4{
    width:25%
  }

  .xl\:w-2\/4{
    width:50%
  }

  .xl\:w-3\/4{
    width:75%
  }

  .xl\:w-1\/5{
    width:20%
  }

  .xl\:w-2\/5{
    width:40%
  }

  .xl\:w-3\/5{
    width:60%
  }

  .xl\:w-4\/5{
    width:80%
  }

  .xl\:w-1\/6{
    width:16.666667%
  }

  .xl\:w-2\/6{
    width:33.333333%
  }

  .xl\:w-3\/6{
    width:50%
  }

  .xl\:w-4\/6{
    width:66.666667%
  }

  .xl\:w-5\/6{
    width:83.333333%
  }

  .xl\:w-1\/12{
    width:8.333333%
  }

  .xl\:w-2\/12{
    width:16.666667%
  }

  .xl\:w-3\/12{
    width:25%
  }

  .xl\:w-4\/12{
    width:33.333333%
  }

  .xl\:w-5\/12{
    width:41.666667%
  }

  .xl\:w-6\/12{
    width:50%
  }

  .xl\:w-7\/12{
    width:58.333333%
  }

  .xl\:w-8\/12{
    width:66.666667%
  }

  .xl\:w-9\/12{
    width:75%
  }

  .xl\:w-10\/12{
    width:83.333333%
  }

  .xl\:w-11\/12{
    width:91.666667%
  }

  .xl\:w-12\/12{
    width:100%
  }

  .xl\:w-screen{
    width:100vw
  }
}

@media (min-width: 1920px){

  .xxl\:bg-fixed{
    background-attachment:fixed
  }

  .xxl\:bg-local{
    background-attachment:local
  }

  .xxl\:bg-scroll{
    background-attachment:scroll
  }

  .xxl\:bg-clip-border{
    background-clip:border-box
  }

  .xxl\:bg-clip-padding{
    background-clip:padding-box
  }

  .xxl\:bg-clip-content{
    background-clip:content-box
  }

  .xxl\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .xxl\:bg-dark{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xxl\:bg-grey{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xxl\:bg-greyLight{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xxl\:bg-light{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xxl\:bg-red{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xxl\:bg-redLight{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xxl\:bg-orange{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xxl\:bg-orangeLight{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xxl\:bg-yellowOrange{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xxl\:bg-yellow{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xxl\:bg-yellowLight{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xxl\:bg-yellowGreen{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xxl\:bg-yellowGreenLight{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xxl\:bg-green{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xxl\:bg-greenLight{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xxl\:bg-blue{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xxl\:bg-blueLight{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xxl\:bg-blueAqua{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xxl\:bg-blueAquaLight{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xxl\:hover\:bg-dark:hover{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xxl\:hover\:bg-grey:hover{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xxl\:hover\:bg-greyLight:hover{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xxl\:hover\:bg-light:hover{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xxl\:hover\:bg-red:hover{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xxl\:hover\:bg-redLight:hover{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xxl\:hover\:bg-orange:hover{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xxl\:hover\:bg-orangeLight:hover{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xxl\:hover\:bg-yellowOrange:hover{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xxl\:hover\:bg-yellow:hover{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xxl\:hover\:bg-yellowLight:hover{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xxl\:hover\:bg-yellowGreen:hover{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xxl\:hover\:bg-yellowGreenLight:hover{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xxl\:hover\:bg-green:hover{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xxl\:hover\:bg-greenLight:hover{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xxl\:hover\:bg-blue:hover{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xxl\:hover\:bg-blueLight:hover{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xxl\:hover\:bg-blueAqua:hover{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xxl\:hover\:bg-blueAquaLight:hover{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xxl\:focus\:bg-dark:focus{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .xxl\:focus\:bg-grey:focus{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .xxl\:focus\:bg-greyLight:focus{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .xxl\:focus\:bg-light:focus{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .xxl\:focus\:bg-red:focus{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .xxl\:focus\:bg-redLight:focus{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .xxl\:focus\:bg-orange:focus{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .xxl\:focus\:bg-orangeLight:focus{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .xxl\:focus\:bg-yellowOrange:focus{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .xxl\:focus\:bg-yellow:focus{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .xxl\:focus\:bg-yellowLight:focus{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .xxl\:focus\:bg-yellowGreen:focus{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .xxl\:focus\:bg-yellowGreenLight:focus{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .xxl\:focus\:bg-green:focus{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .xxl\:focus\:bg-greenLight:focus{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .xxl\:focus\:bg-blue:focus{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .xxl\:focus\:bg-blueLight:focus{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .xxl\:focus\:bg-blueAqua:focus{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .xxl\:focus\:bg-blueAquaLight:focus{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .xxl\:bg-none{
    background-image:none
  }

  .xxl\:bg-gradient-to-t{
    background-image:-webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top, var(--gradient-color-stops))
  }

  .xxl\:bg-gradient-to-tr{
    background-image:-webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top right, var(--gradient-color-stops))
  }

  .xxl\:bg-gradient-to-r{
    background-image:-webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to right, var(--gradient-color-stops))
  }

  .xxl\:bg-gradient-to-br{
    background-image:-webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .xxl\:bg-gradient-to-b{
    background-image:-webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .xxl\:bg-gradient-to-bl{
    background-image:-webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .xxl\:bg-gradient-to-l{
    background-image:-webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to left, var(--gradient-color-stops))
  }

  .xxl\:bg-gradient-to-tl{
    background-image:-webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top left, var(--gradient-color-stops))
  }

  .xxl\:bg-opacity-0{
    --bg-opacity:0
  }

  .xxl\:bg-opacity-25{
    --bg-opacity:0.25
  }

  .xxl\:bg-opacity-50{
    --bg-opacity:0.5
  }

  .xxl\:bg-opacity-75{
    --bg-opacity:0.75
  }

  .xxl\:bg-opacity-100{
    --bg-opacity:1
  }

  .xxl\:hover\:bg-opacity-0:hover{
    --bg-opacity:0
  }

  .xxl\:hover\:bg-opacity-25:hover{
    --bg-opacity:0.25
  }

  .xxl\:hover\:bg-opacity-50:hover{
    --bg-opacity:0.5
  }

  .xxl\:hover\:bg-opacity-75:hover{
    --bg-opacity:0.75
  }

  .xxl\:hover\:bg-opacity-100:hover{
    --bg-opacity:1
  }

  .xxl\:focus\:bg-opacity-0:focus{
    --bg-opacity:0
  }

  .xxl\:focus\:bg-opacity-25:focus{
    --bg-opacity:0.25
  }

  .xxl\:focus\:bg-opacity-50:focus{
    --bg-opacity:0.5
  }

  .xxl\:focus\:bg-opacity-75:focus{
    --bg-opacity:0.75
  }

  .xxl\:focus\:bg-opacity-100:focus{
    --bg-opacity:1
  }

  .xxl\:bg-bottom{
    background-position:bottom
  }

  .xxl\:bg-center{
    background-position:center
  }

  .xxl\:bg-left{
    background-position:left
  }

  .xxl\:bg-left-bottom{
    background-position:left bottom
  }

  .xxl\:bg-left-top{
    background-position:left top
  }

  .xxl\:bg-right{
    background-position:right
  }

  .xxl\:bg-right-bottom{
    background-position:right bottom
  }

  .xxl\:bg-right-top{
    background-position:right top
  }

  .xxl\:bg-top{
    background-position:top
  }

  .xxl\:bg-repeat{
    background-repeat:repeat
  }

  .xxl\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .xxl\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .xxl\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .xxl\:bg-repeat-round{
    background-repeat:round
  }

  .xxl\:bg-repeat-space{
    background-repeat:space
  }

  .xxl\:bg-auto{
    background-size:auto
  }

  .xxl\:bg-cover{
    background-size:cover
  }

  .xxl\:bg-contain{
    background-size:contain
  }

  .xxl\:block{
    display:block
  }

  .xxl\:inline-block{
    display:inline-block
  }

  .xxl\:inline{
    display:inline
  }

  .xxl\:flex{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
  }

  .xxl\:inline-flex{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex
  }

  .xxl\:inline-grid{
    display:inline-grid
  }

  .xxl\:hidden{
    display:none
  }

  .xxl\:flex-row{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row
  }

  .xxl\:flex-row-reverse{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:reverse;
        -ms-flex-direction:row-reverse;
            flex-direction:row-reverse
  }

  .xxl\:flex-col{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column
  }

  .xxl\:flex-col-reverse{
    -webkit-box-orient:vertical;
    -webkit-box-direction:reverse;
        -ms-flex-direction:column-reverse;
            flex-direction:column-reverse
  }

  .xxl\:flex-wrap{
    -ms-flex-wrap:wrap;
        flex-wrap:wrap
  }

  .xxl\:flex-wrap-reverse{
    -ms-flex-wrap:wrap-reverse;
        flex-wrap:wrap-reverse
  }

  .xxl\:flex-no-wrap{
    -ms-flex-wrap:nowrap;
        flex-wrap:nowrap
  }

  .xxl\:place-items-auto{
    place-items:auto
  }

  .xxl\:place-items-start{
    place-items:start
  }

  .xxl\:place-items-end{
    place-items:end
  }

  .xxl\:place-items-center{
    place-items:center
  }

  .xxl\:place-items-stretch{
    place-items:stretch
  }

  .xxl\:items-start{
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start
  }

  .xxl\:items-end{
    -webkit-box-align:end;
        -ms-flex-align:end;
            align-items:flex-end
  }

  .xxl\:items-center{
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }

  .xxl\:items-baseline{
    -webkit-box-align:baseline;
        -ms-flex-align:baseline;
            align-items:baseline
  }

  .xxl\:items-stretch{
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch
  }

  .xxl\:justify-items-auto{
    justify-items:auto
  }

  .xxl\:justify-items-start{
    justify-items:start
  }

  .xxl\:justify-items-end{
    justify-items:end
  }

  .xxl\:justify-items-center{
    justify-items:center
  }

  .xxl\:justify-items-stretch{
    justify-items:stretch
  }

  .xxl\:justify-start{
    -webkit-box-pack:start;
        -ms-flex-pack:start;
            justify-content:flex-start
  }

  .xxl\:justify-end{
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end
  }

  .xxl\:justify-center{
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center
  }

  .xxl\:justify-between{
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between
  }

  .xxl\:justify-around{
    -ms-flex-pack:distribute;
        justify-content:space-around
  }

  .xxl\:justify-evenly{
    -webkit-box-pack:space-evenly;
        -ms-flex-pack:space-evenly;
            justify-content:space-evenly
  }

  .xxl\:justify-self-auto{
    justify-self:auto
  }

  .xxl\:justify-self-start{
    justify-self:start
  }

  .xxl\:justify-self-end{
    justify-self:end
  }

  .xxl\:justify-self-center{
    justify-self:center
  }

  .xxl\:justify-self-stretch{
    justify-self:stretch
  }

  .xxl\:flex-1{
    -webkit-box-flex:1;
        -ms-flex:1 1 0%;
            flex:1 1 0%
  }

  .xxl\:flex-auto{
    -webkit-box-flex:1;
        -ms-flex:1 1 auto;
            flex:1 1 auto
  }

  .xxl\:flex-initial{
    -webkit-box-flex:0;
        -ms-flex:0 1 auto;
            flex:0 1 auto
  }

  .xxl\:flex-none{
    -webkit-box-flex:0;
        -ms-flex:none;
            flex:none
  }

  .xxl\:flex-grow-0{
    -webkit-box-flex:0;
        -ms-flex-positive:0;
            flex-grow:0
  }

  .xxl\:flex-grow{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1
  }

  .xxl\:flex-shrink-0{
    -ms-flex-negative:0;
        flex-shrink:0
  }

  .xxl\:flex-shrink{
    -ms-flex-negative:1;
        flex-shrink:1
  }

  .xxl\:h-0{
    height:0
  }

  .xxl\:h-auto{
    height:auto
  }

  .xxl\:h-1\/1{
    height:100%
  }

  .xxl\:h-full{
    height:100%
  }

  .xxl\:h-1\/2{
    height:50%
  }

  .xxl\:h-1\/3{
    height:33.333333%
  }

  .xxl\:h-2\/3{
    height:66.666667%
  }

  .xxl\:h-1\/4{
    height:25%
  }

  .xxl\:h-2\/4{
    height:50%
  }

  .xxl\:h-3\/4{
    height:75%
  }

  .xxl\:h-1\/5{
    height:20%
  }

  .xxl\:h-2\/5{
    height:40%
  }

  .xxl\:h-3\/5{
    height:60%
  }

  .xxl\:h-4\/5{
    height:80%
  }

  .xxl\:h-1\/6{
    height:16.666667%
  }

  .xxl\:h-2\/6{
    height:33.333333%
  }

  .xxl\:h-3\/6{
    height:50%
  }

  .xxl\:h-4\/6{
    height:66.666667%
  }

  .xxl\:h-5\/6{
    height:83.333333%
  }

  .xxl\:h-1\/12{
    height:8.333333%
  }

  .xxl\:h-2\/12{
    height:16.666667%
  }

  .xxl\:h-3\/12{
    height:25%
  }

  .xxl\:h-4\/12{
    height:33.333333%
  }

  .xxl\:h-5\/12{
    height:41.666667%
  }

  .xxl\:h-6\/12{
    height:50%
  }

  .xxl\:h-7\/12{
    height:58.333333%
  }

  .xxl\:h-8\/12{
    height:66.666667%
  }

  .xxl\:h-9\/12{
    height:75%
  }

  .xxl\:h-10\/12{
    height:83.333333%
  }

  .xxl\:h-11\/12{
    height:91.666667%
  }

  .xxl\:h-12\/12{
    height:100%
  }

  .xxl\:h-screen{
    height:100vh
  }

  .xxl\:text-t1{
    font-size:3em
  }

  .xxl\:text-t2{
    font-size:2.25em
  }

  .xxl\:text-t3{
    font-size:1.5em
  }

  .xxl\:text-t4{
    font-size:1.2em
  }

  .xxl\:text-body{
    font-size:1em
  }

  .xxl\:text-s{
    font-size:.875em
  }

  .xxl\:text-xs{
    font-size:.625em
  }

  .xxl\:overflow-hidden{
    overflow:hidden
  }

  .xxl\:overflow-x-hidden{
    overflow-x:hidden
  }

  .xxl\:overflow-y-hidden{
    overflow-y:hidden
  }

  .xxl\:text-left{
    text-align:left
  }

  .xxl\:text-center{
    text-align:center
  }

  .xxl\:text-right{
    text-align:right
  }

  .xxl\:text-justify{
    text-align:justify
  }

  .xxl\:text-dark{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xxl\:text-grey{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xxl\:text-greyLight{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xxl\:text-light{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xxl\:text-red{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xxl\:text-redLight{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xxl\:text-orange{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xxl\:text-orangeLight{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xxl\:text-yellowOrange{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xxl\:text-yellow{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xxl\:text-yellowLight{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xxl\:text-yellowGreen{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xxl\:text-yellowGreenLight{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xxl\:text-green{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xxl\:text-greenLight{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xxl\:text-blue{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xxl\:text-blueLight{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xxl\:text-blueAqua{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xxl\:text-blueAquaLight{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xxl\:hover\:text-dark:hover{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xxl\:hover\:text-grey:hover{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xxl\:hover\:text-greyLight:hover{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xxl\:hover\:text-light:hover{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xxl\:hover\:text-red:hover{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xxl\:hover\:text-redLight:hover{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xxl\:hover\:text-orange:hover{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xxl\:hover\:text-orangeLight:hover{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xxl\:hover\:text-yellowOrange:hover{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xxl\:hover\:text-yellow:hover{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xxl\:hover\:text-yellowLight:hover{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xxl\:hover\:text-yellowGreen:hover{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xxl\:hover\:text-yellowGreenLight:hover{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xxl\:hover\:text-green:hover{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xxl\:hover\:text-greenLight:hover{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xxl\:hover\:text-blue:hover{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xxl\:hover\:text-blueLight:hover{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xxl\:hover\:text-blueAqua:hover{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xxl\:hover\:text-blueAquaLight:hover{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xxl\:focus\:text-dark:focus{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .xxl\:focus\:text-grey:focus{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .xxl\:focus\:text-greyLight:focus{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .xxl\:focus\:text-light:focus{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .xxl\:focus\:text-red:focus{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .xxl\:focus\:text-redLight:focus{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .xxl\:focus\:text-orange:focus{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .xxl\:focus\:text-orangeLight:focus{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .xxl\:focus\:text-yellowOrange:focus{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .xxl\:focus\:text-yellow:focus{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .xxl\:focus\:text-yellowLight:focus{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .xxl\:focus\:text-yellowGreen:focus{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .xxl\:focus\:text-yellowGreenLight:focus{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .xxl\:focus\:text-green:focus{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .xxl\:focus\:text-greenLight:focus{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .xxl\:focus\:text-blue:focus{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .xxl\:focus\:text-blueLight:focus{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .xxl\:focus\:text-blueAqua:focus{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .xxl\:focus\:text-blueAquaLight:focus{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .xxl\:text-opacity-0{
    --text-opacity:0
  }

  .xxl\:text-opacity-25{
    --text-opacity:0.25
  }

  .xxl\:text-opacity-50{
    --text-opacity:0.5
  }

  .xxl\:text-opacity-75{
    --text-opacity:0.75
  }

  .xxl\:text-opacity-100{
    --text-opacity:1
  }

  .xxl\:hover\:text-opacity-0:hover{
    --text-opacity:0
  }

  .xxl\:hover\:text-opacity-25:hover{
    --text-opacity:0.25
  }

  .xxl\:hover\:text-opacity-50:hover{
    --text-opacity:0.5
  }

  .xxl\:hover\:text-opacity-75:hover{
    --text-opacity:0.75
  }

  .xxl\:hover\:text-opacity-100:hover{
    --text-opacity:1
  }

  .xxl\:focus\:text-opacity-0:focus{
    --text-opacity:0
  }

  .xxl\:focus\:text-opacity-25:focus{
    --text-opacity:0.25
  }

  .xxl\:focus\:text-opacity-50:focus{
    --text-opacity:0.5
  }

  .xxl\:focus\:text-opacity-75:focus{
    --text-opacity:0.75
  }

  .xxl\:focus\:text-opacity-100:focus{
    --text-opacity:1
  }

  .xxl\:select-text{
    -webkit-user-select:text;
       -moz-user-select:text;
        -ms-user-select:text;
            user-select:text
  }

  .xxl\:align-text-top{
    vertical-align:text-top
  }

  .xxl\:align-text-bottom{
    vertical-align:text-bottom
  }

  .xxl\:w-0{
    width:0
  }

  .xxl\:w-auto{
    width:auto
  }

  .xxl\:w-1\/1{
    width:100%
  }

  .xxl\:w-full{
    width:100%
  }

  .xxl\:w-1\/2{
    width:50%
  }

  .xxl\:w-1\/3{
    width:33.333333%
  }

  .xxl\:w-2\/3{
    width:66.666667%
  }

  .xxl\:w-1\/4{
    width:25%
  }

  .xxl\:w-2\/4{
    width:50%
  }

  .xxl\:w-3\/4{
    width:75%
  }

  .xxl\:w-1\/5{
    width:20%
  }

  .xxl\:w-2\/5{
    width:40%
  }

  .xxl\:w-3\/5{
    width:60%
  }

  .xxl\:w-4\/5{
    width:80%
  }

  .xxl\:w-1\/6{
    width:16.666667%
  }

  .xxl\:w-2\/6{
    width:33.333333%
  }

  .xxl\:w-3\/6{
    width:50%
  }

  .xxl\:w-4\/6{
    width:66.666667%
  }

  .xxl\:w-5\/6{
    width:83.333333%
  }

  .xxl\:w-1\/12{
    width:8.333333%
  }

  .xxl\:w-2\/12{
    width:16.666667%
  }

  .xxl\:w-3\/12{
    width:25%
  }

  .xxl\:w-4\/12{
    width:33.333333%
  }

  .xxl\:w-5\/12{
    width:41.666667%
  }

  .xxl\:w-6\/12{
    width:50%
  }

  .xxl\:w-7\/12{
    width:58.333333%
  }

  .xxl\:w-8\/12{
    width:66.666667%
  }

  .xxl\:w-9\/12{
    width:75%
  }

  .xxl\:w-10\/12{
    width:83.333333%
  }

  .xxl\:w-11\/12{
    width:91.666667%
  }

  .xxl\:w-12\/12{
    width:100%
  }

  .xxl\:w-screen{
    width:100vw
  }
}

@media print{

  .print\:bg-fixed{
    background-attachment:fixed
  }

  .print\:bg-local{
    background-attachment:local
  }

  .print\:bg-scroll{
    background-attachment:scroll
  }

  .print\:bg-clip-border{
    background-clip:border-box
  }

  .print\:bg-clip-padding{
    background-clip:padding-box
  }

  .print\:bg-clip-content{
    background-clip:content-box
  }

  .print\:bg-clip-text{
    -webkit-background-clip:text;
            background-clip:text
  }

  .print\:bg-dark{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .print\:bg-grey{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .print\:bg-greyLight{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .print\:bg-light{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .print\:bg-red{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .print\:bg-redLight{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .print\:bg-orange{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .print\:bg-orangeLight{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .print\:bg-yellowOrange{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .print\:bg-yellow{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .print\:bg-yellowLight{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .print\:bg-yellowGreen{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .print\:bg-yellowGreenLight{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .print\:bg-green{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .print\:bg-greenLight{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .print\:bg-blue{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .print\:bg-blueLight{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .print\:bg-blueAqua{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .print\:bg-blueAquaLight{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .print\:hover\:bg-dark:hover{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .print\:hover\:bg-grey:hover{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .print\:hover\:bg-greyLight:hover{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .print\:hover\:bg-light:hover{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .print\:hover\:bg-red:hover{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .print\:hover\:bg-redLight:hover{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .print\:hover\:bg-orange:hover{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .print\:hover\:bg-orangeLight:hover{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .print\:hover\:bg-yellowOrange:hover{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .print\:hover\:bg-yellow:hover{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .print\:hover\:bg-yellowLight:hover{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .print\:hover\:bg-yellowGreen:hover{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .print\:hover\:bg-yellowGreenLight:hover{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .print\:hover\:bg-green:hover{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .print\:hover\:bg-greenLight:hover{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .print\:hover\:bg-blue:hover{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .print\:hover\:bg-blueLight:hover{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .print\:hover\:bg-blueAqua:hover{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .print\:hover\:bg-blueAquaLight:hover{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .print\:focus\:bg-dark:focus{
    --bg-opacity:1;
    background-color:#000000;
    background-color:rgba(0, 0, 0, var(--bg-opacity))
  }

  .print\:focus\:bg-grey:focus{
    --bg-opacity:1;
    background-color:#b2b2b2;
    background-color:rgba(178, 178, 178, var(--bg-opacity))
  }

  .print\:focus\:bg-greyLight:focus{
    --bg-opacity:1;
    background-color:#f6f6f6;
    background-color:rgba(246, 246, 246, var(--bg-opacity))
  }

  .print\:focus\:bg-light:focus{
    --bg-opacity:1;
    background-color:#ffffff;
    background-color:rgba(255, 255, 255, var(--bg-opacity))
  }

  .print\:focus\:bg-red:focus{
    --bg-opacity:1;
    background-color:#ff9173;
    background-color:rgba(255, 145, 115, var(--bg-opacity))
  }

  .print\:focus\:bg-redLight:focus{
    --bg-opacity:1;
    background-color:#fff4f1;
    background-color:rgba(255, 244, 241, var(--bg-opacity))
  }

  .print\:focus\:bg-orange:focus{
    --bg-opacity:1;
    background-color:#ff974a;
    background-color:rgba(255, 151, 74, var(--bg-opacity))
  }

  .print\:focus\:bg-orangeLight:focus{
    --bg-opacity:1;
    background-color:#fff5ed;
    background-color:rgba(255, 245, 237, var(--bg-opacity))
  }

  .print\:focus\:bg-yellowOrange:focus{
    --bg-opacity:1;
    background-color:#ffb850;
    background-color:rgba(255, 184, 80, var(--bg-opacity))
  }

  .print\:focus\:bg-yellow:focus{
    --bg-opacity:1;
    background-color:#ffde55;
    background-color:rgba(255, 222, 85, var(--bg-opacity))
  }

  .print\:focus\:bg-yellowLight:focus{
    --bg-opacity:1;
    background-color:#fffced;
    background-color:rgba(255, 252, 237, var(--bg-opacity))
  }

  .print\:focus\:bg-yellowGreen:focus{
    --bg-opacity:1;
    background-color:#f4f44a;
    background-color:rgba(244, 244, 74, var(--bg-opacity))
  }

  .print\:focus\:bg-yellowGreenLight:focus{
    --bg-opacity:1;
    background-color:#fcfce5;
    background-color:rgba(252, 252, 229, var(--bg-opacity))
  }

  .print\:focus\:bg-green:focus{
    --bg-opacity:1;
    background-color:#bce772;
    background-color:rgba(188, 231, 114, var(--bg-opacity))
  }

  .print\:focus\:bg-greenLight:focus{
    --bg-opacity:1;
    background-color:#f6ffe8;
    background-color:rgba(246, 255, 232, var(--bg-opacity))
  }

  .print\:focus\:bg-blue:focus{
    --bg-opacity:1;
    background-color:#a0dcef;
    background-color:rgba(160, 220, 239, var(--bg-opacity))
  }

  .print\:focus\:bg-blueLight:focus{
    --bg-opacity:1;
    background-color:#e9f6ff;
    background-color:rgba(233, 246, 255, var(--bg-opacity))
  }

  .print\:focus\:bg-blueAqua:focus{
    --bg-opacity:1;
    background-color:#95e4db;
    background-color:rgba(149, 228, 219, var(--bg-opacity))
  }

  .print\:focus\:bg-blueAquaLight:focus{
    --bg-opacity:1;
    background-color:#eefbfb;
    background-color:rgba(238, 251, 251, var(--bg-opacity))
  }

  .print\:bg-none{
    background-image:none
  }

  .print\:bg-gradient-to-t{
    background-image:-webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-tr{
    background-image:-webkit-gradient(linear, left bottom, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top right, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-r{
    background-image:-webkit-gradient(linear, left top, right top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to right, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-br{
    background-image:-webkit-gradient(linear, left top, right bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom right, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-b{
    background-image:-webkit-gradient(linear, left top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-bl{
    background-image:-webkit-gradient(linear, right top, left bottom, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to bottom left, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-l{
    background-image:-webkit-gradient(linear, right top, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to left, var(--gradient-color-stops))
  }

  .print\:bg-gradient-to-tl{
    background-image:-webkit-gradient(linear, right bottom, left top, from(var(--gradient-color-stops)));
    background-image:linear-gradient(to top left, var(--gradient-color-stops))
  }

  .print\:bg-opacity-0{
    --bg-opacity:0
  }

  .print\:bg-opacity-25{
    --bg-opacity:0.25
  }

  .print\:bg-opacity-50{
    --bg-opacity:0.5
  }

  .print\:bg-opacity-75{
    --bg-opacity:0.75
  }

  .print\:bg-opacity-100{
    --bg-opacity:1
  }

  .print\:hover\:bg-opacity-0:hover{
    --bg-opacity:0
  }

  .print\:hover\:bg-opacity-25:hover{
    --bg-opacity:0.25
  }

  .print\:hover\:bg-opacity-50:hover{
    --bg-opacity:0.5
  }

  .print\:hover\:bg-opacity-75:hover{
    --bg-opacity:0.75
  }

  .print\:hover\:bg-opacity-100:hover{
    --bg-opacity:1
  }

  .print\:focus\:bg-opacity-0:focus{
    --bg-opacity:0
  }

  .print\:focus\:bg-opacity-25:focus{
    --bg-opacity:0.25
  }

  .print\:focus\:bg-opacity-50:focus{
    --bg-opacity:0.5
  }

  .print\:focus\:bg-opacity-75:focus{
    --bg-opacity:0.75
  }

  .print\:focus\:bg-opacity-100:focus{
    --bg-opacity:1
  }

  .print\:bg-bottom{
    background-position:bottom
  }

  .print\:bg-center{
    background-position:center
  }

  .print\:bg-left{
    background-position:left
  }

  .print\:bg-left-bottom{
    background-position:left bottom
  }

  .print\:bg-left-top{
    background-position:left top
  }

  .print\:bg-right{
    background-position:right
  }

  .print\:bg-right-bottom{
    background-position:right bottom
  }

  .print\:bg-right-top{
    background-position:right top
  }

  .print\:bg-top{
    background-position:top
  }

  .print\:bg-repeat{
    background-repeat:repeat
  }

  .print\:bg-no-repeat{
    background-repeat:no-repeat
  }

  .print\:bg-repeat-x{
    background-repeat:repeat-x
  }

  .print\:bg-repeat-y{
    background-repeat:repeat-y
  }

  .print\:bg-repeat-round{
    background-repeat:round
  }

  .print\:bg-repeat-space{
    background-repeat:space
  }

  .print\:bg-auto{
    background-size:auto
  }

  .print\:bg-cover{
    background-size:cover
  }

  .print\:bg-contain{
    background-size:contain
  }

  .print\:block{
    display:block
  }

  .print\:inline-block{
    display:inline-block
  }

  .print\:inline{
    display:inline
  }

  .print\:flex{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
  }

  .print\:inline-flex{
    display:-webkit-inline-box;
    display:-ms-inline-flexbox;
    display:inline-flex
  }

  .print\:inline-grid{
    display:inline-grid
  }

  .print\:hidden{
    display:none
  }

  .print\:flex-row{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:normal;
        -ms-flex-direction:row;
            flex-direction:row
  }

  .print\:flex-row-reverse{
    -webkit-box-orient:horizontal;
    -webkit-box-direction:reverse;
        -ms-flex-direction:row-reverse;
            flex-direction:row-reverse
  }

  .print\:flex-col{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
        -ms-flex-direction:column;
            flex-direction:column
  }

  .print\:flex-col-reverse{
    -webkit-box-orient:vertical;
    -webkit-box-direction:reverse;
        -ms-flex-direction:column-reverse;
            flex-direction:column-reverse
  }

  .print\:flex-wrap{
    -ms-flex-wrap:wrap;
        flex-wrap:wrap
  }

  .print\:flex-wrap-reverse{
    -ms-flex-wrap:wrap-reverse;
        flex-wrap:wrap-reverse
  }

  .print\:flex-no-wrap{
    -ms-flex-wrap:nowrap;
        flex-wrap:nowrap
  }

  .print\:place-items-auto{
    place-items:auto
  }

  .print\:place-items-start{
    place-items:start
  }

  .print\:place-items-end{
    place-items:end
  }

  .print\:place-items-center{
    place-items:center
  }

  .print\:place-items-stretch{
    place-items:stretch
  }

  .print\:items-start{
    -webkit-box-align:start;
        -ms-flex-align:start;
            align-items:flex-start
  }

  .print\:items-end{
    -webkit-box-align:end;
        -ms-flex-align:end;
            align-items:flex-end
  }

  .print\:items-center{
    -webkit-box-align:center;
        -ms-flex-align:center;
            align-items:center
  }

  .print\:items-baseline{
    -webkit-box-align:baseline;
        -ms-flex-align:baseline;
            align-items:baseline
  }

  .print\:items-stretch{
    -webkit-box-align:stretch;
        -ms-flex-align:stretch;
            align-items:stretch
  }

  .print\:justify-items-auto{
    justify-items:auto
  }

  .print\:justify-items-start{
    justify-items:start
  }

  .print\:justify-items-end{
    justify-items:end
  }

  .print\:justify-items-center{
    justify-items:center
  }

  .print\:justify-items-stretch{
    justify-items:stretch
  }

  .print\:justify-start{
    -webkit-box-pack:start;
        -ms-flex-pack:start;
            justify-content:flex-start
  }

  .print\:justify-end{
    -webkit-box-pack:end;
        -ms-flex-pack:end;
            justify-content:flex-end
  }

  .print\:justify-center{
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            justify-content:center
  }

  .print\:justify-between{
    -webkit-box-pack:justify;
        -ms-flex-pack:justify;
            justify-content:space-between
  }

  .print\:justify-around{
    -ms-flex-pack:distribute;
        justify-content:space-around
  }

  .print\:justify-evenly{
    -webkit-box-pack:space-evenly;
        -ms-flex-pack:space-evenly;
            justify-content:space-evenly
  }

  .print\:justify-self-auto{
    justify-self:auto
  }

  .print\:justify-self-start{
    justify-self:start
  }

  .print\:justify-self-end{
    justify-self:end
  }

  .print\:justify-self-center{
    justify-self:center
  }

  .print\:justify-self-stretch{
    justify-self:stretch
  }

  .print\:flex-1{
    -webkit-box-flex:1;
        -ms-flex:1 1 0%;
            flex:1 1 0%
  }

  .print\:flex-auto{
    -webkit-box-flex:1;
        -ms-flex:1 1 auto;
            flex:1 1 auto
  }

  .print\:flex-initial{
    -webkit-box-flex:0;
        -ms-flex:0 1 auto;
            flex:0 1 auto
  }

  .print\:flex-none{
    -webkit-box-flex:0;
        -ms-flex:none;
            flex:none
  }

  .print\:flex-grow-0{
    -webkit-box-flex:0;
        -ms-flex-positive:0;
            flex-grow:0
  }

  .print\:flex-grow{
    -webkit-box-flex:1;
        -ms-flex-positive:1;
            flex-grow:1
  }

  .print\:flex-shrink-0{
    -ms-flex-negative:0;
        flex-shrink:0
  }

  .print\:flex-shrink{
    -ms-flex-negative:1;
        flex-shrink:1
  }

  .print\:h-0{
    height:0
  }

  .print\:h-auto{
    height:auto
  }

  .print\:h-1\/1{
    height:100%
  }

  .print\:h-full{
    height:100%
  }

  .print\:h-1\/2{
    height:50%
  }

  .print\:h-1\/3{
    height:33.333333%
  }

  .print\:h-2\/3{
    height:66.666667%
  }

  .print\:h-1\/4{
    height:25%
  }

  .print\:h-2\/4{
    height:50%
  }

  .print\:h-3\/4{
    height:75%
  }

  .print\:h-1\/5{
    height:20%
  }

  .print\:h-2\/5{
    height:40%
  }

  .print\:h-3\/5{
    height:60%
  }

  .print\:h-4\/5{
    height:80%
  }

  .print\:h-1\/6{
    height:16.666667%
  }

  .print\:h-2\/6{
    height:33.333333%
  }

  .print\:h-3\/6{
    height:50%
  }

  .print\:h-4\/6{
    height:66.666667%
  }

  .print\:h-5\/6{
    height:83.333333%
  }

  .print\:h-1\/12{
    height:8.333333%
  }

  .print\:h-2\/12{
    height:16.666667%
  }

  .print\:h-3\/12{
    height:25%
  }

  .print\:h-4\/12{
    height:33.333333%
  }

  .print\:h-5\/12{
    height:41.666667%
  }

  .print\:h-6\/12{
    height:50%
  }

  .print\:h-7\/12{
    height:58.333333%
  }

  .print\:h-8\/12{
    height:66.666667%
  }

  .print\:h-9\/12{
    height:75%
  }

  .print\:h-10\/12{
    height:83.333333%
  }

  .print\:h-11\/12{
    height:91.666667%
  }

  .print\:h-12\/12{
    height:100%
  }

  .print\:h-screen{
    height:100vh
  }

  .print\:text-t1{
    font-size:3em
  }

  .print\:text-t2{
    font-size:2.25em
  }

  .print\:text-t3{
    font-size:1.5em
  }

  .print\:text-t4{
    font-size:1.2em
  }

  .print\:text-body{
    font-size:1em
  }

  .print\:text-s{
    font-size:.875em
  }

  .print\:text-xs{
    font-size:.625em
  }

  .print\:overflow-hidden{
    overflow:hidden
  }

  .print\:overflow-x-hidden{
    overflow-x:hidden
  }

  .print\:overflow-y-hidden{
    overflow-y:hidden
  }

  .print\:text-left{
    text-align:left
  }

  .print\:text-center{
    text-align:center
  }

  .print\:text-right{
    text-align:right
  }

  .print\:text-justify{
    text-align:justify
  }

  .print\:text-dark{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .print\:text-grey{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .print\:text-greyLight{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .print\:text-light{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .print\:text-red{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .print\:text-redLight{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .print\:text-orange{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .print\:text-orangeLight{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .print\:text-yellowOrange{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .print\:text-yellow{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .print\:text-yellowLight{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .print\:text-yellowGreen{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .print\:text-yellowGreenLight{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .print\:text-green{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .print\:text-greenLight{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .print\:text-blue{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .print\:text-blueLight{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .print\:text-blueAqua{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .print\:text-blueAquaLight{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .print\:hover\:text-dark:hover{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .print\:hover\:text-grey:hover{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .print\:hover\:text-greyLight:hover{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .print\:hover\:text-light:hover{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .print\:hover\:text-red:hover{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .print\:hover\:text-redLight:hover{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .print\:hover\:text-orange:hover{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .print\:hover\:text-orangeLight:hover{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .print\:hover\:text-yellowOrange:hover{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .print\:hover\:text-yellow:hover{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .print\:hover\:text-yellowLight:hover{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .print\:hover\:text-yellowGreen:hover{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .print\:hover\:text-yellowGreenLight:hover{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .print\:hover\:text-green:hover{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .print\:hover\:text-greenLight:hover{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .print\:hover\:text-blue:hover{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .print\:hover\:text-blueLight:hover{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .print\:hover\:text-blueAqua:hover{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .print\:hover\:text-blueAquaLight:hover{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .print\:focus\:text-dark:focus{
    --text-opacity:1;
    color:#000000;
    color:rgba(0, 0, 0, var(--text-opacity))
  }

  .print\:focus\:text-grey:focus{
    --text-opacity:1;
    color:#b2b2b2;
    color:rgba(178, 178, 178, var(--text-opacity))
  }

  .print\:focus\:text-greyLight:focus{
    --text-opacity:1;
    color:#f6f6f6;
    color:rgba(246, 246, 246, var(--text-opacity))
  }

  .print\:focus\:text-light:focus{
    --text-opacity:1;
    color:#ffffff;
    color:rgba(255, 255, 255, var(--text-opacity))
  }

  .print\:focus\:text-red:focus{
    --text-opacity:1;
    color:#ff9173;
    color:rgba(255, 145, 115, var(--text-opacity))
  }

  .print\:focus\:text-redLight:focus{
    --text-opacity:1;
    color:#fff4f1;
    color:rgba(255, 244, 241, var(--text-opacity))
  }

  .print\:focus\:text-orange:focus{
    --text-opacity:1;
    color:#ff974a;
    color:rgba(255, 151, 74, var(--text-opacity))
  }

  .print\:focus\:text-orangeLight:focus{
    --text-opacity:1;
    color:#fff5ed;
    color:rgba(255, 245, 237, var(--text-opacity))
  }

  .print\:focus\:text-yellowOrange:focus{
    --text-opacity:1;
    color:#ffb850;
    color:rgba(255, 184, 80, var(--text-opacity))
  }

  .print\:focus\:text-yellow:focus{
    --text-opacity:1;
    color:#ffde55;
    color:rgba(255, 222, 85, var(--text-opacity))
  }

  .print\:focus\:text-yellowLight:focus{
    --text-opacity:1;
    color:#fffced;
    color:rgba(255, 252, 237, var(--text-opacity))
  }

  .print\:focus\:text-yellowGreen:focus{
    --text-opacity:1;
    color:#f4f44a;
    color:rgba(244, 244, 74, var(--text-opacity))
  }

  .print\:focus\:text-yellowGreenLight:focus{
    --text-opacity:1;
    color:#fcfce5;
    color:rgba(252, 252, 229, var(--text-opacity))
  }

  .print\:focus\:text-green:focus{
    --text-opacity:1;
    color:#bce772;
    color:rgba(188, 231, 114, var(--text-opacity))
  }

  .print\:focus\:text-greenLight:focus{
    --text-opacity:1;
    color:#f6ffe8;
    color:rgba(246, 255, 232, var(--text-opacity))
  }

  .print\:focus\:text-blue:focus{
    --text-opacity:1;
    color:#a0dcef;
    color:rgba(160, 220, 239, var(--text-opacity))
  }

  .print\:focus\:text-blueLight:focus{
    --text-opacity:1;
    color:#e9f6ff;
    color:rgba(233, 246, 255, var(--text-opacity))
  }

  .print\:focus\:text-blueAqua:focus{
    --text-opacity:1;
    color:#95e4db;
    color:rgba(149, 228, 219, var(--text-opacity))
  }

  .print\:focus\:text-blueAquaLight:focus{
    --text-opacity:1;
    color:#eefbfb;
    color:rgba(238, 251, 251, var(--text-opacity))
  }

  .print\:text-opacity-0{
    --text-opacity:0
  }

  .print\:text-opacity-25{
    --text-opacity:0.25
  }

  .print\:text-opacity-50{
    --text-opacity:0.5
  }

  .print\:text-opacity-75{
    --text-opacity:0.75
  }

  .print\:text-opacity-100{
    --text-opacity:1
  }

  .print\:hover\:text-opacity-0:hover{
    --text-opacity:0
  }

  .print\:hover\:text-opacity-25:hover{
    --text-opacity:0.25
  }

  .print\:hover\:text-opacity-50:hover{
    --text-opacity:0.5
  }

  .print\:hover\:text-opacity-75:hover{
    --text-opacity:0.75
  }

  .print\:hover\:text-opacity-100:hover{
    --text-opacity:1
  }

  .print\:focus\:text-opacity-0:focus{
    --text-opacity:0
  }

  .print\:focus\:text-opacity-25:focus{
    --text-opacity:0.25
  }

  .print\:focus\:text-opacity-50:focus{
    --text-opacity:0.5
  }

  .print\:focus\:text-opacity-75:focus{
    --text-opacity:0.75
  }

  .print\:focus\:text-opacity-100:focus{
    --text-opacity:1
  }

  .print\:select-text{
    -webkit-user-select:text;
       -moz-user-select:text;
        -ms-user-select:text;
            user-select:text
  }

  .print\:align-text-top{
    vertical-align:text-top
  }

  .print\:align-text-bottom{
    vertical-align:text-bottom
  }

  .print\:w-0{
    width:0
  }

  .print\:w-auto{
    width:auto
  }

  .print\:w-1\/1{
    width:100%
  }

  .print\:w-full{
    width:100%
  }

  .print\:w-1\/2{
    width:50%
  }

  .print\:w-1\/3{
    width:33.333333%
  }

  .print\:w-2\/3{
    width:66.666667%
  }

  .print\:w-1\/4{
    width:25%
  }

  .print\:w-2\/4{
    width:50%
  }

  .print\:w-3\/4{
    width:75%
  }

  .print\:w-1\/5{
    width:20%
  }

  .print\:w-2\/5{
    width:40%
  }

  .print\:w-3\/5{
    width:60%
  }

  .print\:w-4\/5{
    width:80%
  }

  .print\:w-1\/6{
    width:16.666667%
  }

  .print\:w-2\/6{
    width:33.333333%
  }

  .print\:w-3\/6{
    width:50%
  }

  .print\:w-4\/6{
    width:66.666667%
  }

  .print\:w-5\/6{
    width:83.333333%
  }

  .print\:w-1\/12{
    width:8.333333%
  }

  .print\:w-2\/12{
    width:16.666667%
  }

  .print\:w-3\/12{
    width:25%
  }

  .print\:w-4\/12{
    width:33.333333%
  }

  .print\:w-5\/12{
    width:41.666667%
  }

  .print\:w-6\/12{
    width:50%
  }

  .print\:w-7\/12{
    width:58.333333%
  }

  .print\:w-8\/12{
    width:66.666667%
  }

  .print\:w-9\/12{
    width:75%
  }

  .print\:w-10\/12{
    width:83.333333%
  }

  .print\:w-11\/12{
    width:91.666667%
  }

  .print\:w-12\/12{
    width:100%
  }

  .print\:w-screen{
    width:100vw
  }
}
.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}
.c-modal{
  z-index:-1;
  position:fixed;
  top:0;
  right:0;
  left:0;
  bottom:0;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-pack:center;
      -ms-flex-pack:center;
          justify-content:center;
  padding:2em 1em;
  overflow-y:auto;
  pointer-events:none;
  -webkit-transition:z-index 0s .8s;
  transition:z-index 0s .8s
}
.c-modal--default{
  overflow:visible
}
.c-modal--default .c-modal__inner{
  max-width:480px
}
.c-modal--default .c-modal__content{
  padding:0
}
.c-modal--createquiz .c-modal__inner{
  max-width:1050px
}
.has-modal-open .c-modal{
  z-index:900;
  pointer-events:initial;
  -webkit-transition:opacity .8s, z-index 0s 0s;
  transition:opacity .8s, z-index 0s 0s
}
.c-modal__bg{
  position:fixed;
  top:0;
  left:0;
  display:block;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5)
}
.c-modal__inner{
  width:100%;
  max-width:600px;
  margin-top:auto;
  margin-bottom:auto;
  background-color:#ffffff;
  border-radius:.425em;
  -webkit-transform-origin:50% 100%;
          transform-origin:50% 100%
}
.c-modal__close{
  z-index:9;
  position:absolute;
  top:0;
  right:0;
  width:2.75em;
  height:2.75em;
  color:#b2b2b2;
  border-radius:inherit;
  cursor:pointer;
  -webkit-transition:color 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:color 0.2s cubic-bezier(0.39, 0.575, 0.565, 1)
}
.c-modal__close:hover{
  color:#000000
}
.c-modal__content{
  padding:1.5em
}
@media (min-width: 992px){
.c-modal__content{
    padding:3em
}
}
.anim-modal-enter .c-modal__inner{
  -webkit-transform:translate(0, 2em) rotateX(20deg);
          transform:translate(0, 2em) rotateX(20deg)
}
.anim-modal-enter .c-modal__bg,.anim-modal-leave-to .c-modal__bg{
  opacity:0
}
.anim-modal-enter .c-modal__inner,.anim-modal-leave-to .c-modal__inner{
  opacity:0
}
.anim-modal-leave,.anim-modal-enter-to{
  opacity:1
}
.anim-modal-leave .c-modal__bg,.anim-modal-enter-to .c-modal__bg{
  opacity:1
}
.anim-modal-leave .c-modal__inner,.anim-modal-enter-to .c-modal__inner{
  opacity:1;
  -webkit-transform:translate(0) rotate(0);
          transform:translate(0) rotate(0)
}
.anim-modal-enter-active .c-modal__bg{
  -webkit-transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
}
.anim-modal-enter-active .c-modal__inner{
  -webkit-transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.4s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.4s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.4s cubic-bezier(0.19, 1, 0.22, 1)
}
.anim-modal-leave-active .c-modal__bg{
  -webkit-transition:opacity 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.2s cubic-bezier(0.39, 0.575, 0.565, 1)
}
.anim-modal-leave-active .c-modal__inner{
  -webkit-transition:opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.15s cubic-bezier(0.39, 0.575, 0.565, 1)
}
.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}
.o-rounded{
  --color-bg: #ffffff;
  display:inline-block;
  padding:1em 1.5em;
  background-color:var(--color-bg);
  border-radius:4em;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1)
}
.o-rounded--xs{
  padding:.3em .7em
}
.o-rounded__inner{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  font-weight:700
}
.o-rounded__inner>*:nth-child(n+2){
  margin-left:.75rem
}
.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}
.c-box{
  --color-bg: #ffffff;
  --color-rounded: #ffffff
}
.c-box.js-reveal.c-box--center .c-box__title{
  -webkit-transform:scale(0.5) translate(-50%, -50%);
          transform:scale(0.5) translate(-50%, -50%)
}
.c-box.js-reveal .c-box__title{
  opacity:0;
  -webkit-transform:scale(0.5) translate(0, -50%);
          transform:scale(0.5) translate(0, -50%)
}
.c-box.js-reveal .c-box__inner{
  border-radius:0;
  opacity:0;
  -webkit-transform:translate(0, 2em) scale(1, 0.8);
          transform:translate(0, 2em) scale(1, 0.8);
  -webkit-transform-origin:50% 100%;
          transform-origin:50% 100%
}
.c-box.js-reveal.view-in{
  -webkit-transition:all 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition:all 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95)
}
.c-box.js-reveal.view-in.c-box--center .c-box__title{
  -webkit-transform:scale(1) translate(-50%, -50%);
          transform:scale(1) translate(-50%, -50%)
}
.c-box.js-reveal.view-in .c-box__title{
  opacity:1;
  -webkit-transform:scale(1) translate(0, -50%);
          transform:scale(1) translate(0, -50%);
  -webkit-transition:all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  transition:all 0.4s cubic-bezier(0.19, 1, 0.22, 1) 0.3s
}
.c-box.js-reveal.view-in .c-box__inner{
  border-radius:.425em;
  opacity:1;
  -webkit-transform:translate(0) scale(1);
          transform:translate(0) scale(1);
  -webkit-transition:border-radius 0.6s cubic-bezier(0.47, 0, 0.745, 0.715),opacity 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95),-webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition:border-radius 0.6s cubic-bezier(0.47, 0, 0.745, 0.715),opacity 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95),-webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition:border-radius 0.6s cubic-bezier(0.47, 0, 0.745, 0.715),opacity 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95),transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition:border-radius 0.6s cubic-bezier(0.47, 0, 0.745, 0.715),opacity 0.6s cubic-bezier(0.445, 0.05, 0.55, 0.95),transform 0.6s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.6s cubic-bezier(0.19, 1, 0.22, 1)
}
.c-box .c-box__title{
  z-index:1;
  position:absolute;
  top:0;
  left:1.5em;
  -webkit-transform:translate(0, -50%);
          transform:translate(0, -50%)
}
.c-box .c-box__title .o-rounded{
  background-color:var(--color-rounded)
}
.c-box .c-box__title+.c-box__inner{
  margin-top:4em;
  padding-top:2em
}
.c-box .c-box__other-title{
  z-index:1;
  position:absolute;
  top:0;
  right:1.5em;
  -webkit-transform:translate(0, -50%);
          transform:translate(0, -50%)
}
.c-box .c-box__other-title .o-rounded{
  background-color:var(--color-bg, #ffffff)
}
.c-box .c-box__other-title+.c-box__inner{
  margin-top:4em;
  padding-top:2em
}
.c-box--red-light{
  --color-bg: #fff4f1
}
.c-box--blue-light{
  --color-bg: #e9f6ff
}
.c-box--blue-aqua-light{
  --color-bg: #eefbfb
}
.c-box--green-light{
  --color-bg: #f6ffe8
}
.c-box--yellow-light{
  --color-bg: #fffced
}
.c-box--grey-light{
  --color-bg: #f6f6f6;
  color:#b2b2b2
}
.c-box--center{
  text-align:center
}
.c-box--center .c-box__title{
  left:50%;
  white-space:nowrap;
  -webkit-transform:translate(-50%, -50%);
          transform:translate(-50%, -50%)
}
.c-box--sm .c-box__title .o-rounded{
  padding:.4em 1em
}
.c-box--sm .c-box__title+.c-box__inner{
  margin-top:2em
}
.c-box--sm .c-box__other-title .o-rounded{
  padding:.4em 1em
}
.c-box--sm .c-box__other-title+.c-box__inner{
  margin-top:2em
}
.c-box--sm .c-box__content{
  padding:1em
}
.c-box--sm .c-box__header{
  padding:1em
}
.c-box--sm .c-box__header+.c-box__content{
  padding:1em
}
.c-box__header{
  padding:1.5em 2em;
  border-bottom:1px solid #f6f6f6
}
.c-box__inner{
  background-color:var(--color-bg, #ffffff);
  border-radius:.425em
}
.c-box__inner--shadow{
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1)
}
.c-box__content{
  padding:2em
}
.c-box__award{
  position:absolute;
  top:-.5em;
  right:-.5em
}
.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}
.o-modal-default{
  padding-top:2em
}
.o-modal-default .c-box__inner{
  max-width:420px;
  margin-top:0 !important;
  margin-right:auto;
  margin-left:auto;
  padding-top:0 !important
}
.o-modal-default .o-icon{
  --color-bg: #ff9173
}
.o-modal-default__content{
  margin-bottom:2em;
  text-align:center
}
.o-modal-default__title{
  margin-bottom:1em
}
.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}
.o-toggle-switch{
  display:-webkit-inline-box;
  display:-ms-inline-flexbox;
  display:inline-flex;
  -webkit-box-align:center;
      -ms-flex-align:center;
          align-items:center;
  padding:.4em .7em;
  background-color:#ffffff;
  border-radius:.425em;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1)
}
.o-toggle-switch--md{
  padding-top:.5em;
  padding-bottom:.5em
}
.o-toggle-switch--md .o-toggle-switch__el label{
  padding:.2em .7em;
  font-size:.875em;
  color:#000000
}
.o-toggle-switch__el:nth-child(n+2){
  margin-left:.2em
}
.o-toggle-switch__el input{
  z-index:2;
  position:absolute;
  top:0;
  left:0;
  display:block;
  width:100%;
  height:100%;
  cursor:pointer;
  opacity:0
}
.o-toggle-switch__el input:hover+label{
  color:#ffffff;
  -webkit-transition:color 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:color 0.2s cubic-bezier(0.39, 0.575, 0.565, 1)
}
.o-toggle-switch__el input:hover+label:before{
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-transition:-webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition:-webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition:transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition:transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-toggle-switch__el input:checked+label{
  color:#000000;
  -webkit-transition:color 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:color 0.3s cubic-bezier(0.39, 0.575, 0.565, 1)
}
.o-toggle-switch__el input:checked+label:before{
  opacity:0;
  -webkit-transition:opacity 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.2s cubic-bezier(0.47, 0, 0.745, 0.715)
}
.o-toggle-switch__el input:checked+label:after{
  opacity:1;
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.1s;
  transition:all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) 0.1s
}
.o-toggle-switch__el label{
  z-index:1;
  display:block;
  padding:.5em 1em;
  font-size:.625em;
  font-weight:700;
  color:#b2b2b2;
  text-transform:uppercase;
  border-radius:2em;
  overflow:hidden;
  -webkit-user-select:none;
     -moz-user-select:none;
      -ms-user-select:none;
          user-select:none;
  -webkit-transition:color 0.2s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:color 0.2s cubic-bezier(0.47, 0, 0.745, 0.715)
}
.o-toggle-switch__el label:after,.o-toggle-switch__el label:before{
  z-index:-1;
  content:"";
  display:block;
  width:100%;
  height:100%;
  background:none;
  position:absolute;
  top:0;
  left:0;
  border-radius:inherit
}
.o-toggle-switch__el label:before{
  background-color:#b2b2b2;
  opacity:.3;
  -webkit-transform:scale(0, 1);
          transform:scale(0, 1);
  -webkit-transition:-webkit-transform 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition:-webkit-transform 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition:transform 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035);
  transition:transform 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035), -webkit-transform 0.3s cubic-bezier(0.95, 0.05, 0.795, 0.035)
}
.o-toggle-switch__el label:after{
  border:2px solid #000000;
  opacity:0;
  -webkit-transform:scale(0.8);
          transform:scale(0.8);
  -webkit-transition:all 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s;
  transition:all 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s
}
.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}
.o-map{
  display:block;
  width:100%;
  height:0;
  padding-top:100%;
  background-color:#f6f6f6;
  border-radius:.425em
}
@media (min-width: 480px){
.o-map{
    padding-top:75%
}
}
@media (min-width: 768px){
.o-map{
    padding-top:50%
}
}
@media (min-width: 992px){
.o-map{
    padding-top:100%
}
}
.o-map__display{
  position:absolute;
  z-index:1;
  top:-.6em;
  right:1em
}
.o-map__display+.o-map__zoom{
  top:3em
}
.o-map__zoom,.o-map__recenter{
  z-index:1;
  position:absolute;
  top:1em;
  right:1em;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-orient:vertical;
  -webkit-box-direction:normal;
      -ms-flex-direction:column;
          flex-direction:column
}
.o-map__zoom .c-btn,.o-map__recenter .c-btn{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  width:2em;
  height:2em;
  min-height:0;
  padding:0;
  -webkit-box-shadow:none;
          box-shadow:none
}
.o-map__zoom .c-btn:first-child,.o-map__recenter .c-btn:first-child{
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}
.o-map__zoom .c-btn:last-child,.o-map__recenter .c-btn:last-child{
  border-top:1px solid #f6f6f6;
  border-top-right-radius:0;
  border-top-left-radius:0
}
.o-map__zoom .c-btn__label,.o-map__recenter .c-btn__label{
  margin:auto;
  font-size:1.2em
}
.o-map__recenter{
  top:8.2em
}
.o-map__recenter .svg-marker{
  width:20px;
  height:20px
}
.o-map__recenter .c-btn{
  border-radius:.425em
}
.o-map__inner{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  border-radius:inherit
}
.o-map__googlemap{
  height:100%;
  width:100%
}
.o-map__googlemap .gmnoprint,.o-map__googlemap .gm-style-ccm,.o-map__googlemap [rel="noopener"]{
  display:none !important
}
.o-map__googlemap .gm-style-iw-d>div{
  padding:10px
}
.o-map__googlemap .gm-style-iw.gm-style-iw-c .gm-ui-hover-effect{
  top:0 !important;
  right:0 !important
}
.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}
.update-location-name{
  font-size:17px;
  font-weight:600;
  letter-spacing:0.05em;
  line-height:20px
}
.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}
.o-info-window{
  --bg-opacity:1;
  background-color:#ffffff;
  background-color:rgba(255, 255, 255, var(--bg-opacity));
  font-family:Gilroy, Helvetica Neue, Arial, sans-serif;
  width:100%;
  height:100%
}
.o-info-window *{
  font-family:Gilroy, Helvetica Neue, Arial, sans-serif
}
.o-info-window__title{
  padding-left:1rem;
  padding-right:1rem;
  font-size:1em
}
.o-info-window__title .o-input-text{
  font-size:17px;
  line-height:1.2;
  font-weight:700
}
.o-info-window__content{
  padding-left:2rem;
  padding-right:2rem;
  padding-top:1.5rem;
  padding-bottom:1.5rem
}
.o-info-window__content.with-border{
  padding-left:1rem;
  padding-right:1rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  padding-bottom:1.5rem;
  border-top:1px solid #f6f6f6
}
.o-info-window__content .c-listing__address{
  font-style:normal
}
.o-hover-img img[data-v-33f80289],.o-hover-img .o-icon[data-v-33f80289]{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-hover-img:hover img[data-v-33f80289],.o-hover-img:hover .o-icon[data-v-33f80289]{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}
.o-map-infowindow[data-v-33f80289]{
  display:none !important
}
.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}
.o-map-popup{
  display:none !important
}
.o-map__googlemap .o-map-popup{
  display:block !important
}
.o-map-popup-bubble{
  position:absolute;
  top:0;
  left:0;
  -webkit-transform:translate(-50%, calc(-100% - 10px));
          transform:translate(-50%, calc(-100% - 10px));
  background-color:#ffffff;
  border-radius:.425em;
  overflow-y:auto;
  -webkit-box-shadow:0 0 40px 0 rgba(0,0,0,0.1);
          box-shadow:0 0 40px 0 rgba(0,0,0,0.1)
}
.o-map-popup-bubble-anchor{
  position:absolute;
  width:100%;
  bottom:8px;
  left:0
}
.o-map-popup-container{
  cursor:auto;
  height:0;
  position:absolute;
  width:250px
}
.o-hover-img img,.o-hover-img .o-icon{
  -webkit-transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  transition:opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1),transform 0.3s cubic-bezier(0.19, 1, 0.22, 1),-webkit-transform 0.3s cubic-bezier(0.19, 1, 0.22, 1)
}
.o-hover-img:hover img,.o-hover-img:hover .o-icon{
  opacity:.8;
  -webkit-transform:scale(0.95);
          transform:scale(0.95)
}
.map{
  height:400px
}
.map .o-map{
  padding:0;
  height:100%
}
.small-map{
  height:300px;
  pointer-events:none
}
.small-map .o-map{
  padding:0;
  height:100%
}
.small-map *{
  pointer-events:none
}
.l-nav__link{
  z-index:0;
  margin-right:-.1em;
  margin-left:-.1em;
  padding-right:.1em;
  padding-left:.1em;
  overflow:hidden;
  cursor:pointer
}
.l-nav__link span{
  z-index:-1;
  position:absolute;
  bottom:0;
  left:0;
  display:block;
  width:100%;
  height:#a0dcef;
  -webkit-clip-path:inset(0 round .425em .425em .425em .425em);
          clip-path:inset(0 round .425em .425em .425em .425em)
}
.l-nav__link span:before{
  content:"";
  display:block;
  width:100%;
  height:100%;
  background:none;
  z-index:-1;
  position:absolute;
  bottom:-1px;
  left:0;
  background:rgba(255,255,255,0);
  background:-webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)),color-stop(25%, var(--color-main)),color-stop(75%, var(--color-main)),to(rgba(255,255,255,0)));
  background:linear-gradient(to right, rgba(255,255,255,0) 0%,var(--color-main) 25%,var(--color-main) 75%,rgba(255,255,255,0) 100%);
  -webkit-clip-path:inherit;
          clip-path:inherit;
  opacity:0;
  -webkit-transform:scale(0, 1) translateZ(0);
          transform:scale(0, 1) translateZ(0);
  -webkit-transition:opacity 0.1s 0.3s,-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition:opacity 0.1s 0.3s,transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715),-webkit-transform 0.4s cubic-bezier(0.47, 0, 0.745, 0.715)
}
.l-nav__link:hover span:before{
  opacity:1;
  -webkit-transform:scale(1) translateZ(0);
          transform:scale(1) translateZ(0);
  -webkit-transition:opacity 0.1s 0s,-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
}
.l-nav__link.is-active span:before{
  opacity:1;
  -webkit-transform:scale(2, 1) translateZ(0);
          transform:scale(2, 1) translateZ(0);
  -webkit-transition:opacity 0.1s 0s,-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:opacity 0.1s 0s,transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1),-webkit-transform 0.8s cubic-bezier(0.39, 0.575, 0.565, 1)
}
.l-nav__link.disabled{
  pointer-events:none;
  color:#b2b2b2
}
.l-nav__link.disabled span{
  pointer-events:none
}
.create-quiz{
  --color-main: #a0dcef
}
.create-quiz__info{
  max-width:400px;
  margin-bottom:1em
}
@media (min-width: 768px){
.create-quiz__info{
    width:calc(100% - 1em);
    margin-top:-2em
}
}


/*# sourceMappingURL=app.b672a758.css.map*/